import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import {
  GetApiV5FirmsWorkflowTemplates200Item,
  useGetApiV5FirmsWorkflowTemplates,
} from '@liscio/api';
import {
  Icon,
  Typography,
  List,
  IconButton,
  Stack,
  ListItemSecondaryAction,
  ListItemText,
  VerboseLoading,
  GlobalLoader,
  Tooltip,
} from '@liscio/ui';

import { ListItemStyled } from '../NewRequestsModal/NewRequestsModal';
import { generateRedirectUrl } from 'components/DesktopSiteRedirect/desktop-site-redirect-utils';
import {
  useCreateWorkflowFromWorkflowTemplateMutation,
  useDeleteWorkflowTemplateMutation,
  useReadyToSendWorkflowMutation,
} from 'fetch-utils/requests/requests';
import {
  REQUESTS_BUILDER,
  REQUESTS_DISPATCH,
} from 'modules/requests/route-constants';

export function NewRequestsModalTemplates() {
  const navigate = useNavigate();
  const {
    data: templatesData,
    isLoading: templatesDataIsLoading,
    fetchStatus: templatesDataFetchStatus,
    refetch: templatesDataRefetch,
  } = useGetApiV5FirmsWorkflowTemplates();

  const {
    mutate: createWorkflowTemplate,
    isLoading: createWorkflowTemplateIsLoading,
  } = useCreateWorkflowFromWorkflowTemplateMutation();
  const {
    mutate: saveAndSendWorkflowMutation,
    isLoading: createWorkflowRequestIsLoading,
  } = useReadyToSendWorkflowMutation();

  const { mutate: deleteWorkflowTemplate } =
    useDeleteWorkflowTemplateMutation();

  const handleOnClickSend = (
    workflowTemplate: GetApiV5FirmsWorkflowTemplates200Item
  ) => {
    createWorkflowTemplate(
      { id: workflowTemplate.uuid! },
      {
        onSettled: (response) => {
          const workflowId =
            response?.createWorkflowsFromWorkflowTemplate?.workflows?.[0]?.id;

          if (!workflowId) {
            toast('Unable to open template');
            return;
          }

          saveAndSendWorkflowMutation(
            { workflowId },
            {
              onSettled: (requestResponse) => {
                if (!requestResponse?.readyToSend?.workflowRequest?.id) {
                  toast('Unable to open template');
                  return;
                }
                navigate(
                  `/${REQUESTS_DISPATCH}/${requestResponse?.readyToSend?.workflowRequest?.id}?template=${workflowTemplate.uuid}`
                );
              },
            }
          );
        },
      }
    );
  };

  const handleEditTemplate = ({
    uuid: workflowTemplateId,
  }: GetApiV5FirmsWorkflowTemplates200Item) => {
    createWorkflowTemplate(
      { id: workflowTemplateId! },

      {
        onSuccess: (response) => {
          const id =
            response?.createWorkflowsFromWorkflowTemplate?.workflows?.[0]?.id;

          if (id) {
            window.location.href = generateRedirectUrl(
              `/${REQUESTS_BUILDER}/${id}`,
              `?template=${workflowTemplateId}`
            );
            return;
          }

          toast('Unable to edit template');
        },
      }
    );
  };

  const handleDeleteTemplate = ({
    uuid: workflowTemplateId,
  }: GetApiV5FirmsWorkflowTemplates200Item) => {
    deleteWorkflowTemplate(
      { workflowTemplateId: workflowTemplateId! },
      {
        onSettled: (response) => {
          if (response?.deleteWorkflowTemplate?.errors?.length) {
            toast('Unable to delete template');
            return;
          }
          templatesDataRefetch();
        },
      }
    );
  };

  const templatesAreLoading =
    templatesDataIsLoading && templatesDataFetchStatus !== 'idle';
  if (templatesAreLoading) {
    return (
      <Stack overflow="hidden" height="100vh">
        <VerboseLoading isLoading />;
      </Stack>
    );
  }

  return (
    <>
      <Typography variant="h4" paddingBottom={2}>
        Templates
      </Typography>
      <List component={Stack} gap={1.5} role="list">
        {templatesData?.map((template) => (
          <ListItemStyled
            key={template.uuid}
            ContainerProps={{ 'aria-label': template.name }}
          >
            <ListItemText primary={template.name} />
            <ListItemSecondaryAction>
              <Tooltip title="Delete" placement="top">
                <IconButton
                  onClick={() => handleDeleteTemplate(template)}
                  sx={(theme) => ({ marginRight: theme.spacing(2) })}
                  aria-label="delete"
                >
                  <Icon fontSize="small" icon="delete" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit" placement="top">
                <IconButton
                  onClick={() => handleEditTemplate(template)}
                  sx={(theme) => ({ marginRight: theme.spacing(2) })}
                  aria-label="edit"
                >
                  <Icon fontSize="small" color="primary" icon="penOutline" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Send" placement="top">
                <IconButton
                  onClick={() => handleOnClickSend(template)}
                  aria-label="send"
                >
                  <Icon fontSize="small" color="primary" icon="send" />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItemStyled>
        ))}
      </List>
      <GlobalLoader
        open={createWorkflowTemplateIsLoading || createWorkflowRequestIsLoading}
      />
    </>
  );
}
