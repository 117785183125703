/* eslint-disable react-hooks/exhaustive-deps */
import { MouseEvent } from 'react';

import { IndexRequestSchemaDataItem } from '@liscio/api';
import { Typography } from '@liscio/ui';

import { RequestEdit } from '../RequestEdit/RequestEdit';
import { StyledDrawer } from '../RequestEdit/StyledComponents';

type EditDrawerProps = {
  open: boolean;
  row: Pick<IndexRequestSchemaDataItem, 'uuid'>;
  onCancel: (event: MouseEvent) => void;
  onClose: () => void;
};

/**
 * This component is used to edit a request in a drawer.
 */
export function EditDrawer({ open, row, onCancel, onClose }: EditDrawerProps) {
  return (
    <StyledDrawer open={open} anchor="right" onClose={onClose}>
      <Typography variant="h5">Edit</Typography>
      <RequestEdit
        open={open}
        row={row}
        onCancel={onCancel}
        onClose={onClose}
      />
    </StyledDrawer>
  );
}
