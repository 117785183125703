import { ListItem, ListItemButton, ListItemText } from '@liscio/ui';

type RequestsTableFiltersMenuItemProps = {
  title: string;
  index: number;
  currentIndex: number;
  onClick: (menuItemIndex: number) => void;
};

export const RequestTableFiltersMenuItem = ({
  title,
  index,
  currentIndex,
  onClick,
}: RequestsTableFiltersMenuItemProps) => {
  return (
    <ListItem disableGutters disablePadding onClick={() => onClick(index)}>
      <ListItemButton selected={index === currentIndex} disableRipple>
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            fontWeight: currentIndex === index ? 'bold' : 'normal',
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};
