import { useState } from 'react';

import { GridPagination } from '@mui/x-data-grid';

import { IndexRequestSchemaDataItem } from '@liscio/api';
import { Stack, Typography } from '@liscio/ui';

import { BulkSendToolbar } from './BulkSendToolbar';
import { BulkEditDrawer } from '../EditDrawer/BulkEditDrawer';
import { BulkSendDialog } from 'modules/requests/dialogs/BulkSendDialog/BulkSendDialog';

export type BulkSendFooterProps = {
  selectedRows: (IndexRequestSchemaDataItem | undefined)[];
};

/**
 * Footer for bulk send mode
 */
export function BulkSendFooter({ selectedRows }: BulkSendFooterProps) {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isBulkSendDialogOpen, setBulkSendDialogOpen] = useState(false);

  const rowCount = selectedRows.length;
  const rowText =
    rowCount > 0 ? `${rowCount} row${rowCount > 1 ? 's' : ''} selected` : null;

  const validateSelectedRowData = () => {
    return selectedRows.some(
      (row) =>
        row?.assigned_contacts?.length === 0 ||
        row?.status !== 'draft' ||
        !row?.due_date ||
        !row?.owner?.uuid
    );
  };

  return (
    <>
      <Stack
        direction="row"
        sx={{
          minHeight: '65px',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
        px={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1">{rowText}</Typography>
        <GridPagination />
      </Stack>
      {selectedRows.length > 0 && (
        <BulkSendToolbar
          rowCount={rowCount}
          setBulkSendDialogOpen={setBulkSendDialogOpen}
          setDrawerOpen={setDrawerOpen}
          validateSelectedRowData={validateSelectedRowData}
        />
      )}
      <BulkEditDrawer
        open={isDrawerOpen}
        rows={selectedRows}
        onClose={() => setDrawerOpen(false)}
      />
      <BulkSendDialog
        open={isBulkSendDialogOpen}
        selectedRows={selectedRows}
        onClose={() => setBulkSendDialogOpen(false)}
      />
    </>
  );
}
