import { Chip, Typography, useTheme } from '@liscio/ui';

type RequestsFilterChipProps = {
  labelKey: string;
  labelValue: string;
  handleDelete: <T>(typeKey: T) => void;
  typeKey: string;
};

export const RequestsFilterChip = ({
  labelKey,
  labelValue,
  handleDelete,
  typeKey,
}: RequestsFilterChipProps) => {
  const theme = useTheme();

  const FormattedLabel = (
    <>
      <Typography
        variant="body1"
        color={theme.palette.text.secondary}
        component={'span'}
      >
        {labelKey}:{' '}
      </Typography>
      <Typography variant="body1" component={'span'}>
        {labelValue}
      </Typography>
    </>
  );

  return (
    <Chip
      label={FormattedLabel}
      onDelete={() => handleDelete(typeKey)}
      sx={(theme) => ({
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        borderRadius: theme.spacing(4),
        '&.MuiChip-root': {
          height: theme.spacing(5),
        },
      })}
    />
  );
};
