import toast from 'react-hot-toast';

import { usePostApiV5FirmsRequestsIdZip } from '@liscio/api';
import { MenuItem, ListItemIcon, Icon } from '@liscio/ui';

import { ExportZipErrorToast } from './ExportZipErrorToast';
import { ExportZipSuccessToast } from './ExportZipSuccessToast';

interface ExportZipMenuItemProps {
  requestId: string;
  onClose: () => void;
}

export const ExportZipMenuItem = ({
  requestId,
  onClose,
}: ExportZipMenuItemProps) => {
  const { mutateAsync: startZipExport } = usePostApiV5FirmsRequestsIdZip({
    mutation: {
      onSuccess: () => {
        toast.custom(ExportZipSuccessToast, {
          position: 'top-right',
          duration: 5000,
          style: {
            padding: 0,
            margin: '0px 0px',
          },
        });
      },
      onError: (error) => {
        toast.custom(ExportZipErrorToast, {
          position: 'top-right',
          duration: 2000,
          style: {
            padding: 0,
            margin: '0px 0px',
          },
        });
        console.error('Error starting zip export:', error);
      },
      onSettled: () => {
        onClose();
      },
    },
  });

  return (
    <MenuItem onClick={() => startZipExport({ id: requestId })}>
      <ListItemIcon>
        <Icon icon="fileZip" fontSize="small" />
      </ListItemIcon>
      Export ZIP
    </MenuItem>
  );
};
