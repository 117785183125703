import { useEffect, useRef, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import {
  useGetApiV5FirmsRequestsRequestIdSections,
  useGetApiV5FirmsRequestsRequestIdSectionsSectionIdItems,
} from '@liscio/api';
import {
  useRequestStore,
  requestItemsAdapter,
  requestSectionAdapter,
} from '@liscio/common';
import { useEffectOnce, usePrevious } from '@liscio/utils';

type useRequestDesktopViewProps = {
  id: string;
  rightPanelOpen?: boolean;
  leftPanelOpen?: boolean;
};

export const useRequestDesktopView = ({
  id,
  rightPanelOpen: rightPanelOpenInitial = false,
  leftPanelOpen: leftPanelOpenInitial = true,
}: useRequestDesktopViewProps) => {
  const [rightPanelOpen, setRightPanelOpen] = useState(rightPanelOpenInitial);
  const [leftPanelOpen, setLeftPanelOpen] = useState(leftPanelOpenInitial);
  const navigate = useNavigate();
  const {
    visibleSections,
    setCurrentSectionId,
    setDisableEdit,
    currentSectionId,
    showOnlyUnansweredQuestions,
    request,
    setRequest,
    setRefetchSections,
    setRefetchItems,
  } = useRequestStore();
  const pCurrentSectionId = usePrevious(null);
  const requestRef = useRef(request);
  const { sectionId: paramsSectionId } = useParams();

  const {
    data: sectionsData,
    refetch: refetchSections,
    dataUpdatedAt: sectionsUpdatedAt,
    isSuccess,
  } = useGetApiV5FirmsRequestsRequestIdSections(id, { per_page: 100 });

  const {
    data: itemsData,
    refetch: refetchItems,
    dataUpdatedAt: itemDataUpdatedAt,
  } = useGetApiV5FirmsRequestsRequestIdSectionsSectionIdItems(
    id,
    currentSectionId!,
    {},
    {
      query: {
        cacheTime: Infinity,
        staleTime: Infinity,
        enabled: !!currentSectionId && isSuccess,
      },
    }
  );

  useEffectOnce(() => {
    setDisableEdit(true);
  });

  useEffect(() => {
    if (paramsSectionId && paramsSectionId !== currentSectionId) {
      setCurrentSectionId(paramsSectionId);
    }
  }, [paramsSectionId, currentSectionId, setCurrentSectionId]);

  useEffect(() => {
    const section = visibleSections.find((s) => s.id === currentSectionId);
    if (
      sectionsData?.data &&
      request &&
      request !== requestRef.current &&
      pCurrentSectionId !== currentSectionId &&
      !request.sectionsMap[currentSectionId!]
    ) {
      const newRequest = requestSectionAdapter(
        sectionsData.data,
        request,
        sectionsUpdatedAt
      );
      requestRef.current = newRequest;
      setRequest(newRequest);
      setRefetchSections(() => refetchSections());

      if (paramsSectionId && paramsSectionId !== currentSectionId) {
        setCurrentSectionId(paramsSectionId);
      } else if (!currentSectionId && sectionsData.data.length) {
        setCurrentSectionId(sectionsData.data[0].uuid);
        navigate(`sections/${sectionsData.data[0].uuid}`, { replace: true });
      }
    } else if (
      sectionsData &&
      request &&
      section &&
      section.questionsLoadedAt !== -Infinity &&
      section.loadedAt < sectionsUpdatedAt
    ) {
      const newRequest = requestSectionAdapter(
        sectionsData.data,
        request,
        sectionsUpdatedAt
      );
      requestRef.current = newRequest;
      setRequest(newRequest);
    }
  }, [
    pCurrentSectionId,
    currentSectionId,
    sectionsData,
    request,
    setRequest,
    paramsSectionId,
    setCurrentSectionId,
    setRefetchSections,
    refetchSections,
    navigate,
    visibleSections,
    sectionsUpdatedAt,
  ]);

  useEffect(() => {
    if (itemsData && request) {
      const section = visibleSections.find((s) => s.id === currentSectionId);
      if (
        !section ||
        !itemsData.data ||
        section.questionsLoadedAt >= itemDataUpdatedAt
      )
        return;

      const newRequest = requestItemsAdapter(
        request,
        section,
        itemsData.data,
        showOnlyUnansweredQuestions,
        itemDataUpdatedAt
      );
      requestRef.current = newRequest;
      setRefetchItems(() => refetchItems());
      setRequest(newRequest);
    }
  }, [
    currentSectionId,
    itemDataUpdatedAt,
    itemsData,
    refetchItems,
    request,
    setRefetchItems,
    setRequest,
    showOnlyUnansweredQuestions,
    visibleSections,
  ]);

  const onOpenLeftPanel = () => {
    setLeftPanelOpen(true);
    setRightPanelOpen(false);
  };

  const onCloseLeftPanel = () => {
    setLeftPanelOpen(false);
    setRightPanelOpen(true);
  };

  const onOpenRightPanel = () => {
    setRightPanelOpen(true);
    setLeftPanelOpen(false);
  };

  const onCloseRightPanel = () => {
    setRightPanelOpen(false);
    setLeftPanelOpen(true);
  };

  const handleOnSectionClick = (sectionId: string) => {
    setCurrentSectionId(sectionId);
    navigate(`sections/${sectionId}`, { replace: true });
  };

  return {
    rightPanelOpen,
    leftPanelOpen,
    onOpenLeftPanel,
    onCloseLeftPanel,
    onOpenRightPanel,
    onCloseRightPanel,
    handleOnSectionClick,
  };
};
