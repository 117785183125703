// Reference for accessing the hooks:
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { Dialog } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSearchParams } from 'react-router-dom';

import { useGetApiV5FirmsRequests } from '@liscio/api';
import { useMixpanel } from '@liscio/common';
import { Stack, Box, Button, Icon } from '@liscio/ui';
import { useEffectOnce, convertQueryStringToObject } from '@liscio/utils';

import { RequestsSearchViewSkeleton } from './RequestsSearchViewSkeleton';
import { useEmployeeList } from '../../../custom-hooks/useEmployeeList';
import { BulkSendHeader } from '../components/BulkSendHeader/BulkSendHeader';
import { BulkSendToggler } from '../components/BulkSendToggler/BulkSendToggler';
import { NewRequestPageAlert } from '../components/NewRequestPageAlert/NewRequestPageAlert';
import NewRequestsModal from '../components/NewRequestsModal/NewRequestsModal';
import { RequestsFilterChips } from '../components/RequestsFilterChips/RequestsFilterChips';
import { RequestsSearchInput } from '../components/RequestsSearchInput/RequestsSearchInput';
import { RequestsTable } from '../components/RequestsTable/RequestsTable';
import { RequestsTableFilters } from '../components/RequestsTableFilters/RequestsTableFilters';
import { TotalCountHeader } from '../components/TotalCountHeader/TotalCountHeader';
import { useRequestExperience } from '../hooks/use-experiences-hook';
import { useRequestFilterStore } from '../hooks/useRequestFilterStore';
import { generateRedirectUrl } from 'components/DesktopSiteRedirect/desktop-site-redirect-utils';
import { config } from 'config';
import { useHomeData } from 'fetch-utils/users/user-hooks';

export const RequestsSearchView = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    getFiltersForQuery,
    setOwnersList,
    setCurrentUserEmployeeUuid,
    setFilters,
    setRefetchRequests,
    bulkSendModeOn,
  } = useRequestFilterStore();
  const { requestOptInBanners } = useFlags();
  const [uiReady, setUiReady] = useState(false);
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const { isNewRequestExperienceEnabled } = useRequestExperience();
  const filtersForQuery = getFiltersForQuery();

  const {
    data: requestSearchData,
    isLoading,
    fetchStatus,
    refetch: refetchRequests,
  } = useGetApiV5FirmsRequests(filtersForQuery, {
    query: {
      enabled: uiReady,
    },
  });

  useLayoutEffect(() => {
    const redirect = generateRedirectUrl(
      `/requests`,
      `${window.location.search}`,
      window.location.pathname
    );

    async function checkExperience() {
      if (requestOptInBanners) {
        const isEnabled = await isNewRequestExperienceEnabled();
        if (!isEnabled) {
          window.location.href =
            redirect ?? `${config.legacyRedirect.url}/requests`;
        }
      }
    }

    checkExperience();
  }, []);

  // initial tasks/prevent double render
  useEffectOnce(() => {
    const params = convertQueryStringToObject(searchParams, [
      'modal',
      'next',
      'new-request-page',
      'company_name', // this comes from the firm.liscio.me redirect
      'redirectedFrom', // this comes from the firm.liscio.me redirect
    ]);
    // set a default status if no url params
    const areParamsEmpty = Object.keys(params).length === 0;
    if (areParamsEmpty) {
      params.status = 'open';
    }
    setFilters(params);

    // set the refetch function
    setRefetchRequests(refetchRequests);

    // set the ui ready to true
    setUiReady(true);
  });

  // if filtersForQuery changes update the url search params
  // don't put this in the onSettled or cache hits wont trigger it
  useEffect(() => {
    // watch for modal param changes and open appropriate modal
    if (searchParams.get('modal')) {
      if (searchParams.get('modal') === 'newrequest') {
        setRequestModalOpen(true);
      }
    }

    const params = new URLSearchParams(
      filtersForQuery as Record<string, string>
    );

    if (searchParams.toString() !== params.toString()) {
      uiReady &&
        setSearchParams(filtersForQuery as Record<string, string>, {
          replace: true,
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersForQuery]);

  // get the home data - this is already cached on loading the app
  const { data: homeData } = useHomeData();

  // get and set the employee list
  const { employeeList, employeeListLoading } = useEmployeeList(uiReady);

  useEffect(() => {
    if (!employeeList.length || employeeListLoading) return;
    setOwnersList(employeeList);
    setCurrentUserEmployeeUuid(homeData?.data?.employee_uuid as string);
  }, [
    employeeList,
    employeeListLoading,
    homeData,
    setCurrentUserEmployeeUuid,
    setOwnersList,
  ]);

  // Event tracking
  const mixpanel = useMixpanel();
  const statusFilters = useMemo(
    () => (filtersForQuery?.status || '').split(',').filter((itm) => !!itm),
    [filtersForQuery?.status]
  );

  useEffect(() => {
    mixpanel?.track('requestList_view', {
      filters: statusFilters,
    });
  }, [mixpanel, statusFilters]);

  const handleImportButtonClick = () => {
    window.location.href = generateRedirectUrl('/smart-tax');
  };

  return (
    <>
      {requestOptInBanners && <NewRequestPageAlert />}
      <Box
        m={1}
        mt={2}
        display="flex"
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <TotalCountHeader
          totalEntries={requestSearchData?.meta?.total_entries}
          loading={isLoading && fetchStatus !== 'idle'}
          filters={filtersForQuery}
        />
        <Stack direction="row" justifyContent="space-between" gap={2}>
          <Button
            variant="outlined"
            startIcon={<Icon icon="import" />}
            onClick={handleImportButtonClick}
            sx={{ width: '118px' }}
          >
            Import
          </Button>
          <Button
            variant="contained"
            startIcon={<Icon icon="plus" />}
            onClick={() => setRequestModalOpen(true)}
            sx={{ width: '118px' }}
          >
            Request
          </Button>
        </Stack>
      </Box>
      {/* theres a lot going on during the intial render
      uiReady lets things settle before rendering/setting values
       */}
      {uiReady ? (
        <>
          {bulkSendModeOn ? (
            <BulkSendHeader
              currentUserUUID={homeData?.data?.employee_uuid as string}
            />
          ) : (
            <>
              <Stack direction="row" alignItems="center">
                <BulkSendToggler />
                <RequestsSearchInput />
                <RequestsTableFilters />
              </Stack>
              <Stack direction="row" alignItems="center" mx={2} mb={1}>
                <RequestsFilterChips />
              </Stack>
            </>
          )}
          <RequestsTable
            requestSchema={requestSearchData}
            loading={isLoading && fetchStatus !== 'idle'}
          />
        </>
      ) : (
        <RequestsSearchViewSkeleton />
      )}
      <Dialog maxWidth="md" fullWidth open={requestModalOpen}>
        <NewRequestsModal onClose={() => setRequestModalOpen(false)} />
      </Dialog>
    </>
  );
};
