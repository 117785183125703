import { ChangeEvent } from 'react';

import { FormControlLabel } from '@mui/material';

import { Box, Checkbox, Stack, Typography } from '@liscio/ui';

import { BulkSendToggler } from '../BulkSendToggler/BulkSendToggler';
import { RequestsFilterChips } from '../RequestsFilterChips/RequestsFilterChips';
import { useRequestFilterStore } from 'modules/requests/hooks/useRequestFilterStore';

type BulkSendHeaderProps = {
  currentUserUUID: string;
};

export const BulkSendHeader = ({ currentUserUUID }: BulkSendHeaderProps) => {
  const { setOwnerIds, filters } = useRequestFilterStore();

  const handleShowOnlyMyDrafts = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setOwnerIds([currentUserUUID]);
    } else {
      setOwnerIds([]);
    }
  };

  return (
    <Stack direction="row" alignItems="center" gap={2} mt={2} mb={1.5}>
      <BulkSendToggler />
      <Box mt={1}>
        <RequestsFilterChips />
      </Box>
      <FormControlLabel
        label={
          <Typography variant="body2" fontWeight="normal">
            Show only my drafts
          </Typography>
        }
        sx={{ alignItems: 'center' }}
        control={
          <Checkbox
            edge="start"
            tabIndex={0}
            disableRipple
            color="info"
            checked={!!filters?.owner_id?.length}
            onChange={handleShowOnlyMyDrafts}
          />
        }
      />
    </Stack>
  );
};
