/* eslint-disable no-console */
import {
  getApiV5Experiences,
  patchApiV5ExperiencesExperienceId,
} from '@liscio/api';

// This variable is used to store the experience ID of the request experience
let requestExperienceId: string | null = null;

/**
 * This hook provides a function to retrieve experiences from the API and set the request experience
 * in local storage based on the status of the experience.
 */
export function useRequestExperience() {
  /**
   * Checks if the new request experience is enabled based on the value in local storage.
   * @returns true if the new request experience is enabled, false otherwise
   */
  const isNewRequestExperienceEnabled = async () => {
    try {
      const experiences = await getApiV5Experiences();

      if (experiences && experiences.data && experiences.data.length > 0) {
        const requestExperience = experiences.data.find(
          (experience) => experience.name === 'request_list_rendering_v2'
        );

        if (requestExperience) {
          requestExperienceId = requestExperience.uuid;
          const { status } = requestExperience;
          return status.firm === 'enabled' && status.user === 'enabled';
        }
      }
    } catch (error) {
      console.error('Failed to fetch experiences:', error);
    }
    return false;
  };

  /**
   * Updates the request experience status and stores it in local storage.
   * @param status The status to set ('enabled' or 'disabled')
   */
  const updateRequestExperience = async (status: 'enabled' | 'disabled') => {
    if (requestExperienceId) {
      try {
        const updatedRequestExperience =
          await patchApiV5ExperiencesExperienceId(requestExperienceId, {
            status,
          });
        return updatedRequestExperience;
      } catch (error) {
        console.error(`Failed to ${status} experience:`, error);
        return null;
      }
    }
    return null;
  };

  return {
    isNewRequestExperienceEnabled,
    updateRequestExperience,
  };
}
