import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { MenuItem, ListItemIcon, Icon } from '@liscio/ui';

import { RecallRequestDialog } from 'modules/requests/dialogs/RecallRequestDialog/RecallRequestDialog';
import { routes } from 'routes';

interface RecallRequestMenuItemProps {
  onClose: () => void;
  requestId: string;
}

export const RecallRequestMenuItem = ({
  onClose,
  requestId,
}: RecallRequestMenuItemProps) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <>
      <MenuItem onClick={handleClick}>
        <ListItemIcon>
          <Icon icon="recurringCharge" fontSize="small" />
        </ListItemIcon>
        Recall Request
      </MenuItem>
      <RecallRequestDialog
        open={open}
        onClose={() => {
          setOpen(false);
          onClose();
        }}
        requestId={requestId}
        onSuccessfulRecall={() => {
          navigate(routes.requestsListProtected.link());
        }}
      />
    </>
  );
};
