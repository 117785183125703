import { Box, Skeleton, Stack } from '@liscio/ui';

export const RequestsSearchViewSkeleton = () => {
  return (
    <>
      <Stack direction="row" alignItems="center">
        {/* RequestsSearchInput */}
        <Box mx={2}>
          <Skeleton width={500} height={80} />
        </Box>
        <Box mr={2}>
          <Skeleton width={90} height={80} />
        </Box>
        <Skeleton width={80} height={80} />
      </Stack>
      <Stack direction="row" alignItems="center" mx={2} mb={1}>
        {/* RequestsFilterChips */}
        <Skeleton width="100%" height={80} />
      </Stack>
      {/* RequestsTable */}
      <Skeleton variant="rectangular" height={600} />
    </>
  );
};
