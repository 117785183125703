import { Dialog, Typography } from '@liscio/ui';

import requestSubmitImage from './bulk-send-success.svg';
import { useRequestFilterStore } from 'modules/requests/hooks/useRequestFilterStore';

type BulkSendSuccessDialogProps = {
  open: boolean;
  rowCount: number;
  onClose: () => void;
};

/**
 * This component is used to show the success message after bulk sending drafts.
 */
export function BulkSendSuccessDialog({
  open,
  rowCount,
  onClose,
}: BulkSendSuccessDialogProps) {
  const { getRefetchRequests } = useRequestFilterStore();

  const handleClose = () => {
    // Refetch requests after bulk send and dialog display
    // the dialog will fail to display if the refetch happens first
    const refetchRequests = getRefetchRequests();
    if (refetchRequests) {
      refetchRequests();
    }

    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      iconClose
      cancelLabel="Close"
      onCancel={handleClose}
      cancelBtnProps={{ color: 'primary' }}
      PaperProps={{
        sx: {
          minWidth: '440px',
          textAlign: 'center',
        },
      }}
    >
      <img src={requestSubmitImage} alt="Request submit" />
      <Typography variant="h4" mt={3} mb={2}>
        {rowCount === 1 ? 'Request' : 'Requests'} Sent
      </Typography>
      <Typography variant="body1">
        You successfully sent {rowCount}{' '}
        {rowCount === 1 ? 'request' : 'requests'}.
      </Typography>
    </Dialog>
  );
}
