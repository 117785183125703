import { useState } from 'react';

import { alpha, ButtonBase } from '@mui/material';

import {
  Box,
  Icon,
  Typography,
  useTheme,
  Stack,
  styled,
  Divider,
  IconButton,
  ListItem,
} from '@liscio/ui';

import { NewRequestsModalDrafts } from '../NewRequestsModalDrafts/NewRequestsModalDrafts';
import { NewRequestsModalTemplates } from '../NewRequestsModalTemplates/NewRequestsModalTemplates';
import { generateRedirectUrl } from 'components/DesktopSiteRedirect/desktop-site-redirect-utils';
import { REQUESTS_BUILDER } from 'modules/requests/route-constants';

const ImageButton = styled(ButtonBase)<{ selected?: boolean }>(
  ({ theme, ...props }) => ({
    flex: '1 0 0',
    height: '13rem',
    border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
    backgroundColor: props.selected
      ? alpha(theme.palette.primary.light, 0.04)
      : theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    color: props.selected ? theme.palette.primary.main : undefined,
  })
);

export const ListItemStyled = styled(ListItem)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  gap: theme.spacing(3),
}));

const ImageButtonStack = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}));

type RequestModalMenus = 'templates' | 'drafts';

type RequestsModalProps = {
  onClose: () => void;
};

export default function NewRequestsModal({ onClose }: RequestsModalProps) {
  const theme = useTheme();
  const [selectedMenu, setSelectedMenu] =
    useState<RequestModalMenus>('templates');

  const handleBuilderOpened = () => {
    window.location.href = generateRedirectUrl(`/${REQUESTS_BUILDER}`);
  };

  return (
    <>
      <Stack
        overflow="hidden"
        width="100%"
        height="100vh"
        maxHeight="800px"
        gap={theme.spacing(3)}
        paddingY={theme.spacing(3)}
      >
        <Box
          paddingX={3}
          display="flex"
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              letterSpacing: '0.15px',
              textTransform: 'uppercase',
            }}
          >
            Create a Request
          </Typography>
          <IconButton onClick={onClose} aria-label="Close">
            <Icon icon="close" />
          </IconButton>
        </Box>
        <Divider />
        <Box
          gap={theme.spacing(3)}
          width="100%"
          justifyContent={'space-evenly'}
          display="flex"
          paddingX={theme.spacing(3)}
        >
          <ImageButton
            className="MuiButton-outlined"
            selected={selectedMenu === 'templates'}
            onClick={() => setSelectedMenu('templates')}
          >
            <ImageButtonStack>
              <Icon color="primary" icon="template" fontSize={'large'} />
              <Typography>Choose a template</Typography>
            </ImageButtonStack>
          </ImageButton>
          <ImageButton
            selected={selectedMenu === 'drafts'}
            onClick={() => setSelectedMenu('drafts')}
          >
            <ImageButtonStack>
              <Icon
                color="primary"
                icon="arrowRotateRightDuotone"
                fontSize={'large'}
              />
              <Typography>Pick up where you left off</Typography>
            </ImageButtonStack>
          </ImageButton>
          <ImageButton onClick={handleBuilderOpened}>
            <ImageButtonStack>
              <Icon color={'primary'} icon="plusDuotone" fontSize={'large'} />
              <Typography>Start from scratch</Typography>
            </ImageButtonStack>
          </ImageButton>
        </Box>
        <Stack sx={{ overflowY: 'auto' }} paddingX={theme.spacing(3)}>
          {selectedMenu === 'templates' && <NewRequestsModalTemplates />}
          {selectedMenu === 'drafts' && <NewRequestsModalDrafts />}
        </Stack>
      </Stack>
    </>
  );
}
