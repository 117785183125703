import { makeRouteParamsFn } from '@liscio/utils';

import {
  ACCOUNTS_DOMAIN,
  ACCOUNTS_PATHS,
} from './modules/accounts/route-constants';
import {
  DASHBOARD_DOMAIN,
  DASHBOARD_PATHS,
} from './modules/dashboard/route-constants';
import { FILES_DOMAIN, FILES_PATHS } from './modules/files/route-constants';
import {
  INVOICES_DOMAIN,
  INVOICES_PATHS,
} from './modules/invoices/route-constants';
import {
  MESSAGES_DOMAIN,
  MESSAGES_PATHS,
} from './modules/messages/route-constants';
import {
  NOTIFICATIONS_DOMAIN,
  NOTIFICATIONS_PATHS,
} from './modules/notifications/route-constants';
import { TASKS_DOMAIN, TASKS_PATHS } from './modules/tasks/route-constants';
import {
  WORKFLOWS_PATHS,
  WORKFLOWS_PROTECTED_DOMAIN,
} from './modules/workflow/route-constants';
import {
  ADMIN_DOMAIN,
  ADMIN_DOMAIN_PATHS,
} from 'modules/admin/route-constants';
import {
  CONTACTS_DOMAIN,
  CONTACTS_PATHS,
} from 'modules/contacts/route-constants';
import { PROFILE_DOMAIN, PROFILE_PATHS } from 'modules/profile/route-constants';
import { REQUESTS_INDEX } from 'modules/requests/route-constants';
import { SMS_DOMAIN, SMS_PATHS } from 'modules/sms/route-constants';

// TODO: Centralize all our app route paths here (rather than in our modules) for easier global access
export const ROUTE_PATHS = {
  INVITATION: '/i/:token',
  // NOTE - there is no firm page for this route so it's redirected to refactor /esign_list
  // via the desktop-redirects.ts file until we create the edocs page in the firm app
  EDOCS: '/edocs/list',
  DASHBOARD: `/${DASHBOARD_DOMAIN}${DASHBOARD_PATHS.home}`,
  MESSAGES_INBOX: `/${MESSAGES_DOMAIN}${MESSAGES_PATHS.list}`,
  MESSAGES_DETAIL: `/${MESSAGES_DOMAIN}${MESSAGES_PATHS.details}/:id`,
  TASKS_LIST: `/${TASKS_DOMAIN}${TASKS_PATHS.list}`,
  REQUESTS_LIST_PROTECTED: `/${WORKFLOWS_PROTECTED_DOMAIN}${WORKFLOWS_PATHS.list}`,
  REQUEST_DETAIL: `/${REQUESTS_INDEX}/:id`,
  REQUEST_DETAIL_SECTION: `/${REQUESTS_INDEX}/:id/sections/:sectionId`,
  REQUEST_DETAIL_QUESTION: `/${REQUESTS_INDEX}/:requestId/sections/:sectionId/questions/:questionId`,
  ACCOUNTS_LIST: `/${ACCOUNTS_DOMAIN}${ACCOUNTS_PATHS.list}`,
  CONTACTS_LIST: `/${CONTACTS_DOMAIN}${CONTACTS_PATHS.list}`,
  FILES_LIST: `${FILES_DOMAIN}${FILES_PATHS.list}`,
  INVOICES_LIST: `${INVOICES_DOMAIN}${INVOICES_PATHS.list}`,
  NOTIFICATIONS_LIST: `${NOTIFICATIONS_DOMAIN}${NOTIFICATIONS_PATHS.list}`,
  FILES_NEW: `${FILES_DOMAIN}${FILES_PATHS.new}`,
  SMS_LIST: `${SMS_DOMAIN}${SMS_PATHS.list}`,
  PROFILE_DETAILS: `/${PROFILE_DOMAIN}${PROFILE_PATHS.details}`,
  ADMIN_USERS: `/${ADMIN_DOMAIN}${ADMIN_DOMAIN_PATHS.users}`,
  ADMIN_USER_UPDATE: `/${ADMIN_DOMAIN}${ADMIN_DOMAIN_PATHS.updateUser}`,
  ADMIN_TEMPLATES: `/${ADMIN_DOMAIN}/templates`,
  ADMIN_BILLING: `/${ADMIN_DOMAIN}${ADMIN_DOMAIN_PATHS.billing}`,
  ADMIN_PREFERENCES: `/${ADMIN_DOMAIN}/preferences`,
  ADMIN_INTEGRATIONS: `/${ADMIN_DOMAIN}/integrations`,
  ADMIN_SMS: `/${ADMIN_DOMAIN}/sms`,
};

/**
 * Centralized list of route paths with link generators
 * for consistency throughout the app.
 *
 * NOTE: This file is a first step towards flattening our routing
 * structures and removing the hierarchical nature of our routes
 * for flexibility and maintainability moving forward.
 */
export const routes = {
  // Accept invitation route
  invitation: {
    path: ROUTE_PATHS.INVITATION,
    link: makeRouteParamsFn(ROUTE_PATHS.INVITATION, 'token'),
  },
  dashboard: {
    path: ROUTE_PATHS.DASHBOARD,
    link: () => ROUTE_PATHS.DASHBOARD,
  },
  emailInbox: {
    path: '/email/list',
    link: () => '/email/list',
  },
  messagesInbox: {
    path: ROUTE_PATHS.MESSAGES_INBOX,
    link: () => ROUTE_PATHS.MESSAGES_INBOX,
  },
  messagesDetails: {
    path: ROUTE_PATHS.MESSAGES_DETAIL,
    link: makeRouteParamsFn(ROUTE_PATHS.MESSAGES_DETAIL, 'id'),
  },

  tasksList: {
    path: ROUTE_PATHS.TASKS_LIST,
    link: () => ROUTE_PATHS.TASKS_LIST,
  },
  requestsListProtected: {
    path: ROUTE_PATHS.REQUESTS_LIST_PROTECTED,
    link: () => ROUTE_PATHS.REQUESTS_LIST_PROTECTED,
  },
  accountsList: {
    path: ROUTE_PATHS.ACCOUNTS_LIST,
    link: () => ROUTE_PATHS.ACCOUNTS_LIST,
  },
  contactsList: {
    path: ROUTE_PATHS.CONTACTS_LIST,
    link: () => ROUTE_PATHS.CONTACTS_LIST,
  },
  eDocsList: {
    path: ROUTE_PATHS.EDOCS,
    link: () => ROUTE_PATHS.EDOCS,
  },
  filesList: {
    path: ROUTE_PATHS.FILES_LIST,
    link: () => ROUTE_PATHS.FILES_LIST,
  },
  filesNew: {
    path: ROUTE_PATHS.FILES_NEW,
    link: () => ROUTE_PATHS.FILES_NEW,
  },
  invoicesList: {
    path: ROUTE_PATHS.INVOICES_LIST,
    link: () => ROUTE_PATHS.INVOICES_LIST,
  },
  notiicationsList: {
    path: ROUTE_PATHS.NOTIFICATIONS_LIST,
    link: () => ROUTE_PATHS.NOTIFICATIONS_LIST,
  },
  smsList: {
    path: ROUTE_PATHS.SMS_LIST,
    link: () => ROUTE_PATHS.SMS_LIST,
  },
  profileDetails: {
    path: ROUTE_PATHS.PROFILE_DETAILS,
    link: () => ROUTE_PATHS.PROFILE_DETAILS,
  },
  adminUsers: {
    path: ROUTE_PATHS.ADMIN_USERS,
    link: () => ROUTE_PATHS.ADMIN_USERS,
  },
  adminUserUpdate: {
    path: ROUTE_PATHS.ADMIN_USER_UPDATE,
    link: () => ROUTE_PATHS.ADMIN_USER_UPDATE,
  },
  adminTemplates: {
    path: ROUTE_PATHS.ADMIN_TEMPLATES,
    link: () => ROUTE_PATHS.ADMIN_TEMPLATES,
  },
  adminBilling: {
    path: ROUTE_PATHS.ADMIN_BILLING,
    link: () => ROUTE_PATHS.ADMIN_BILLING,
  },
  adminPreferences: {
    path: ROUTE_PATHS.ADMIN_PREFERENCES,
    link: () => ROUTE_PATHS.ADMIN_PREFERENCES,
  },
  adminIntegrations: {
    path: ROUTE_PATHS.ADMIN_INTEGRATIONS,
    link: () => ROUTE_PATHS.ADMIN_INTEGRATIONS,
  },
  adminSMS: {
    path: ROUTE_PATHS.ADMIN_SMS,
    link: () => ROUTE_PATHS.ADMIN_SMS,
  },
  requestDetail: {
    path: ROUTE_PATHS.REQUEST_DETAIL,
    link: makeRouteParamsFn(ROUTE_PATHS.REQUEST_DETAIL, 'id'),
  },
  requestDetailSection: {
    path: ROUTE_PATHS.REQUEST_DETAIL_SECTION,
    link: makeRouteParamsFn(
      ROUTE_PATHS.REQUEST_DETAIL_SECTION,
      'id',
      'sectionId'
    ),
  },
  requestDetailQuestion: {
    path: ROUTE_PATHS.REQUEST_DETAIL_QUESTION,
    link: makeRouteParamsFn(
      ROUTE_PATHS.REQUEST_DETAIL_QUESTION,
      'requestId',
      'sectionId',
      'questionId'
    ),
  },
  // TODO: Expand with all other app route paths/links
};
