/* eslint-disable @typescript-eslint/no-explicit-any */
import { Select } from '@mui/material';
import type { SelectProps as MuiSelectProps } from '@mui/material';
import { Controller } from 'react-hook-form';

import { MenuItem } from '@liscio/ui';

import { useEmployeeList } from 'custom-hooks/useEmployeeList';

type SelectOwnerDropdownProps = MuiSelectProps & {
  control?: any;
  name: string;
  value?: string | undefined;
};

/**
 * This component is used to select an owner from a dropdown.
 */
export const SelectOwnerDropdown = ({
  control = undefined,
  name,
  value,
  onChange,
}: SelectOwnerDropdownProps) => {
  const { employeeList: ownersList } = useEmployeeList(true);

  if (control)
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select fullWidth {...field}>
            {ownersList.map((owner) => (
              <MenuItem key={owner.uuid} value={owner.uuid}>
                {owner.first_name} {owner.last_name}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    );

  return (
    <Select onChange={onChange} fullWidth value={value || undefined}>
      {ownersList.map((owner) => (
        <MenuItem key={owner.uuid} value={owner.uuid}>
          {owner.first_name} {owner.last_name}
        </MenuItem>
      ))}
    </Select>
  );
};
