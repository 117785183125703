/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo } from 'react';

import { format, parse } from 'date-fns';
import { useController } from 'react-hook-form';

import { DateInput, DateInputProps } from './DateInput';

export interface RHFDateInputProps extends DateInputProps {
  name: string;
  control: any;
  // date-fns format for value stored to RHF
  valueFormat?: string;
  required?: boolean;
}

/**
 * react-hook-form compatible datepicker.
 * Form value stored as foramtted date string.
 */
export const RHFDateInput = ({
  name,
  control,
  valueFormat = 'MM/dd/yyyy',
  required,
  ...rest
}: RHFDateInputProps) => {
  const { field } = useController({ name, control, rules: { required } });
  const { value: fieldValue, onChange: fieldOnChange } = field;

  // transform value to string for rhf
  const value = useMemo(() => {
    if (fieldValue) {
      const dateValue = parse(fieldValue, valueFormat, new Date());
      return dateValue;
    }
    return null;
  }, [valueFormat, fieldValue]);
  const onChange = useCallback(
    (date: Date | null) => {
      if (date) {
        const formattedDate = format(date, valueFormat);
        fieldOnChange(formattedDate);
      } else {
        fieldOnChange(null);
      }
    },
    [valueFormat, fieldOnChange]
  );

  return <DateInput {...rest} name={name} value={value} onChange={onChange} />;
};
