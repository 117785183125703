import React, { useCallback } from 'react';

import {
  Button,
  styled,
  Box,
  ScreenWrapper,
  BodyWrapper,
  FooterWrapper,
} from '@liscio/ui';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';

import { AppBar } from '@liscio/common';

import { StateElement, TagsFilterForm, UploaderTypeForm } from '.';
import { AppHeader, RHFCheckboxList, SortingForm } from 'components';
import {
  monthOptions,
  numberRangeOptions,
} from 'components/Select/option-sets';
import FILE_SORTING_OPTIONS from 'constants/fileSortingOptions';
import { defaultFileFilters } from 'fetch-utils/documents/document-filters';

// Options
const years = numberRangeOptions(2010, new Date().getFullYear() + 1)
  .reverse()
  .map((year) => ({ value: String(year), label: String(year) }));
const months = monthOptions.map(({ full }) => ({
  value: full,
  label: full,
}));

interface FiltersForm {
  sort_by?: string;
  field?: string;
  file_uploaded_by?: string;
  tags?: string[];
}

export interface FileFiltersFormProps {
  filters?: FiltersForm;
  onFilter?: SubmitHandler<FiltersForm>;
  onClose?: () => void;
}

const FormWrapper = styled(Box)({
  padding: '26px',
});

export const FileFiltersForm: React.FC<FileFiltersFormProps> = ({
  filters = defaultFileFilters,
  onClose = () => {},
  onFilter = () => {},
}) => {
  const formCtx = useForm<FiltersForm>({
    defaultValues: filters,
  });
  const { handleSubmit, reset, control } = formCtx;

  const onSubmit: SubmitHandler<FiltersForm> = (data) => {
    onFilter(data);
    onClose();
  };
  const clear = useCallback(() => {
    reset(defaultFileFilters);
  }, [reset]);

  return (
    <FormProvider {...formCtx}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ScreenWrapper>
          <AppBar
            onNavigationClick={onClose}
            DefaultStateElement={(props) => (
              <StateElement {...props} onClear={clear} />
            )}
            title="Filters"
            hideSecondaryActionButton
          />
          <BodyWrapper>
            <AppHeader title="Sort By" />
            <FormWrapper>
              <SortingForm options={FILE_SORTING_OPTIONS} />
            </FormWrapper>
            <AppHeader title="Filter By Uploader" />
            <FormWrapper>
              <UploaderTypeForm />
            </FormWrapper>
            <AppHeader title="Filter By Tag" />
            <FormWrapper>
              <TagsFilterForm />
            </FormWrapper>
            <AppHeader title="Filter By Year" />
            <FormWrapper>
              <RHFCheckboxList name="year" options={years} control={control} />
            </FormWrapper>
            <AppHeader title="Filter By Month" />
            <FormWrapper>
              <RHFCheckboxList
                name="month"
                options={months}
                control={control}
              />
            </FormWrapper>
          </BodyWrapper>
          <FooterWrapper>
            <Button variant="contained" type="submit" fullWidth>
              Apply
            </Button>
          </FooterWrapper>
        </ScreenWrapper>
      </form>
    </FormProvider>
  );
};
