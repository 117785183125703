import format from 'date-fns/format';
import toast from 'react-hot-toast';

import { WorkflowSortOption, WorkflowStatus } from '@liscio/api/graphql';
import {
  Icon,
  Typography,
  List,
  IconButton,
  Stack,
  Tooltip,
  Box,
  VerboseLoading,
} from '@liscio/ui';

import { ListItemStyled } from '../NewRequestsModal/NewRequestsModal';
import { generateRedirectUrl } from 'components/DesktopSiteRedirect/desktop-site-redirect-utils';
import {
  useUpdateWorkflow,
  useWorkflowsQuery,
} from 'fetch-utils/requests/requests';
import { REQUESTS_BUILDER } from 'modules/requests/route-constants';

export function NewRequestsModalDrafts() {
  const {
    data: draftRequests,
    isLoading,
    refetch,
  } = useWorkflowsQuery({
    status: WorkflowStatus.Draft,
    sortField: WorkflowSortOption.LastActivity,
    sortDirection: 'desc',
  });

  const { mutate: updateWorkflowStatusMutation } = useUpdateWorkflow();

  const handleDraftClicked = (id: string) => {
    window.location.href = generateRedirectUrl(`/${REQUESTS_BUILDER}/${id}`);
  };

  const handleDelete = (id: string) => {
    updateWorkflowStatusMutation(
      {
        status: WorkflowStatus.Deleted,
        workflowId: id,
      },
      {
        onSuccess: () => {
          toast('Draft deleted successfully');
          refetch();
        },
        onError: () => {
          toast.error('Error deleting draft');
        },
      }
    );
  };

  if (isLoading) {
    return (
      <Stack overflow="hidden" height="100vh">
        <VerboseLoading isLoading />;
      </Stack>
    );
  }

  return (
    <>
      <Typography variant="h4" paddingBottom={2}>
        Continue Building
      </Typography>
      <List component={Stack} gap={1.5} role="list">
        {draftRequests?.workflows?.collection?.map((workflow) => (
          <ListItemStyled
            sx={{ justifyContent: 'space-between' }}
            key={workflow.id}
            aria-label={workflow.title}
          >
            <Typography>{workflow.title}</Typography>

            <Box display="flex" alignItems={'center'} gap={2}>
              <Typography color={'text.secondary'}>
                {`Last Edit: ${format(new Date(workflow.updatedAt), 'P')}`}
              </Typography>
              <Tooltip title="Delete" placement="top">
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDelete(workflow.id)}
                >
                  <Icon fontSize="small" icon="delete" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit" placement="top">
                <IconButton
                  aria-label="edit"
                  onClick={() => handleDraftClicked(workflow.id)}
                >
                  <Icon color="primary" fontSize="small" icon="penOutline" />
                </IconButton>
              </Tooltip>
            </Box>
          </ListItemStyled>
        ))}
      </List>
    </>
  );
}
