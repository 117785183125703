import { useEffect, useMemo, useState } from 'react';

import { Uuid } from '@liscio/api';
import { Button, Stack, Typography, useTheme, Box, Checkbox } from '@liscio/ui';

import { useRequestFilterStore } from 'modules/requests/hooks/useRequestFilterStore';

export const OwnersFiltersPanel = () => {
  const theme = useTheme();

  const {
    getFilters,
    setOwnerIds,
    ownersList,
    ownersListMap,
    currenUserEmployeeUuid,
  } = useRequestFilterStore();

  // need to unbungle the owner_ids strings coming from the API into an array
  const ownerIds = useMemo(() => {
    // match commas with whitespace ", ", ", ", "," and ", "
    const matchCommasWithWhitespace = /\s*,\s*/;
    return getFilters()
      .owner_id?.split(matchCommasWithWhitespace)
      .map(String)
      .filter(Boolean);
  }, [getFilters]);

  const [values, setValues] = useState<Uuid[]>(ownerIds || []);

  useEffect(() => {
    setOwnerIds(values);
  }, [values, setOwnerIds]);

  const handleOwnerChange = (e: React.ChangeEvent, uuid?: Uuid) => {
    const target = e.target as HTMLInputElement;
    const checked = target.checked;

    if (uuid) {
      setValues((prev) => {
        if (checked) {
          return [...prev, uuid];
        }
        return prev.filter((value) => value !== uuid);
      });
    }
  };

  const isCheckboxChecked = (uuid?: Uuid) => {
    if (uuid) {
      return values.includes(uuid);
    }
    return false;
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        py={1}
        px={2}
        sx={{
          width: '330px',
          position: 'fixed',
          zIndex: 1,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Typography variant="h6" mb={1} color={theme.palette.text.secondary}>
          {values.length} OWNER{values.length !== 1 && 'S'} SELECTED{' '}
        </Typography>
        <Button
          size="small"
          disabled={!values.length}
          onClick={() => setValues([])}
        >
          Clear
        </Button>
      </Stack>
      <Box p={2} mt={3}>
        <Box>
          <Checkbox
            edge="start"
            tabIndex={0}
            disableRipple
            color="info"
            checked={isCheckboxChecked(currenUserEmployeeUuid)}
            onChange={(e) => handleOwnerChange(e, currenUserEmployeeUuid)}
            inputProps={{
              'aria-label': ownersListMap[currenUserEmployeeUuid],
            }}
          />
          {ownersListMap[currenUserEmployeeUuid]}
        </Box>
        {ownersList.length &&
          ownersList
            ?.filter((x) => x.uuid !== currenUserEmployeeUuid)
            .map((employee, i) => (
              <Box key={employee.uuid}>
                <Checkbox
                  edge="start"
                  key={employee.uuid}
                  tabIndex={i + 1}
                  disableRipple
                  color="info"
                  checked={isCheckboxChecked(employee.uuid)}
                  onChange={(e) => handleOwnerChange(e, employee.uuid)}
                  inputProps={{
                    'aria-label': `${employee.first_name} ${employee.last_name}`,
                  }}
                />
                {employee.first_name} {employee.last_name}
              </Box>
            ))}
      </Box>
    </>
  );
};
