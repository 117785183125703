import { format, parse } from 'date-fns';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import {
  IndexRequestSchemaDataItem,
  usePostApiV5FirmsRequestsBulkUpdate,
} from '@liscio/api';
import { Stack, Typography, InputLabel } from '@liscio/ui';

import { StyledButton, StyledDrawer } from '../RequestEdit/StyledComponents';
import { RHFDateInput } from 'components';
import { SelectOwnerDropdown } from 'components/SelectOwnerDropdown/SelectOwnerDropdown';
import { useRequestFilterStore } from 'modules/requests/hooks/useRequestFilterStore';

type BulkEditDrawerProps = {
  open: boolean;
  rows: (IndexRequestSchemaDataItem | undefined)[];
  onClose: () => void;
};

type FormDataType = {
  owner?: string;
  dueDate?: string;
};

const DATE_FORMAT = 'MMM d, yyyy';

/**
 * Drawer for bulk edit mode
 */
export function BulkEditDrawer({ open, rows, onClose }: BulkEditDrawerProps) {
  const { handleSubmit, control, formState } = useForm<FormDataType>({
    defaultValues: {
      owner: '',
      dueDate: '',
    },
  });
  const { getRefetchRequests } = useRequestFilterStore();
  const { mutateAsync: postBulkUpdate } = usePostApiV5FirmsRequestsBulkUpdate();

  const rowCount = rows.length;

  const onSubmit = (formData: FormDataType) => {
    const isoDate = formData.dueDate
      ? format(
          parse(formData.dueDate, 'yyyy-MM-dd', new Date()),
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
        )
      : '';

    // Update all selected rows
    postBulkUpdate(
      {
        data: rows.map((row) => ({
          id: row?.uuid || '',
          ...(formData.owner && { owner_id: formData.owner }),
          ...(formData.dueDate && { due_date: isoDate }),
        })),
      },
      {
        onSuccess: () => {
          const refetchRequests = getRefetchRequests();
          if (refetchRequests) {
            refetchRequests();
          }
          toast.success(`${rowCount} drafts updated`, { duration: 3000 });
          onClose();
        },
        onError: (error) => {
          toast.error('Error updating drafts');
          console.error('Error updating drafts', error);
          onClose();
        },
      }
    );
  };

  return (
    <StyledDrawer open={open} anchor="right" onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack height="100vh">
          <Stack flexGrow={0.97} gap={2} padding="1rem">
            <Typography variant="h5">Bulk Edit</Typography>
            <Stack>
              <Typography variant="body2" fontWeight="normal">
                Changing the values below will update{' '}
                <Typography variant="body2" color="purple" display={'inline'}>
                  {rowCount} selected
                </Typography>{' '}
                drafts.
              </Typography>
            </Stack>
            <Stack>
              <InputLabel>DUE DATE</InputLabel>
              <RHFDateInput
                name="dueDate"
                control={control}
                format={DATE_FORMAT}
                valueFormat="yyyy-MM-dd"
                minDate={new Date()}
              />
            </Stack>
            <Stack>
              <InputLabel>OWNER</InputLabel>
              <SelectOwnerDropdown control={control} name="owner" />
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-evenly"
            padding={1}
            sx={(theme) => ({
              borderTop: `1px solid ${theme.palette.divider}`,
            })}
          >
            <StyledButton
              variant="outlined"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
            >
              Cancel
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={!formState.isDirty}
            >
              Save
            </StyledButton>
          </Stack>
        </Stack>
      </form>
    </StyledDrawer>
  );
}
