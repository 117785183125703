import { format, parseISO } from 'date-fns';

import { Box } from '@liscio/ui';

import { RequestsFilterChip } from '../RequestsFilterChip/RequestsFilterChip';
import { useRequestFilterStore } from 'modules/requests/hooks/useRequestFilterStore';

export const LastActivityChip = () => {
  const { getFilters, setUpdatedAtLTE, setUpdatedAtGTE } =
    useRequestFilterStore();

  const updatedAtLte = getFilters()['updated_at[lte]'];
  const updatedAtGte = getFilters()['updated_at[gte]'];

  const handleDelete = () => {
    setUpdatedAtLTE('');
    setUpdatedAtGTE('');
  };

  const getLabel = () => {
    const lte = updatedAtLte
      ? format(parseISO(updatedAtLte), 'M/d/yyyy')
      : 'not set';
    const gte = updatedAtGte
      ? format(parseISO(updatedAtGte), 'M/d/yyyy')
      : 'not set';
    return `${gte} - ${lte}`;
  };

  return (
    <Box component="span">
      {(updatedAtGte || updatedAtLte) && (
        <RequestsFilterChip
          labelKey="Last Activity"
          labelValue={getLabel()}
          handleDelete={handleDelete}
          typeKey="updated_at"
        />
      )}
    </Box>
  );
};
