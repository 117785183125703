import { useEffect, useState } from 'react';

import { GetApiV5FirmsRequestsStatus } from '@liscio/api';
import { Box, Checkbox } from '@liscio/ui';

import { useRequestFilterStore } from 'modules/requests/hooks/useRequestFilterStore';

export const StatusFiltersPanel = () => {
  const { getFilters, setStatus } = useRequestFilterStore();

  const status = getFilters().status || ([] as GetApiV5FirmsRequestsStatus[]);

  const { open, pending, archived, draft, recalled } =
    GetApiV5FirmsRequestsStatus;

  // remember the `status` state is stored as a CSV string in the useRequestFilterStore
  // "open,pending,draft"
  const [values, setValues] = useState<{
    [key in GetApiV5FirmsRequestsStatus]: boolean;
  }>({
    open: status?.includes(open) || false,
    pending: status?.includes(pending) || false,
    archived: status?.includes(archived) || false,
    draft: status?.includes(draft) || false,
    recalled: status?.includes(recalled) || false,
  });

  useEffect(() => {
    const trueKeys = Object.keys(values).filter(
      (key) => values[key as keyof typeof values]
    );

    setStatus(trueKeys as GetApiV5FirmsRequestsStatus[]);
  }, [values, setStatus]);

  const handleTypeChange = (
    e: React.ChangeEvent,
    status: GetApiV5FirmsRequestsStatus
  ) => {
    const target = e.target as HTMLInputElement;
    const checked = target.checked;

    setValues((values) => ({ ...values, [status]: checked }));
  };

  return (
    <Box>
      <Box>
        <Checkbox
          edge="start"
          tabIndex={0}
          disableRipple
          color="info"
          checked={values.open}
          onChange={(e) => handleTypeChange(e, open)}
          inputProps={{
            'aria-label': 'Open',
          }}
        />
        Open
      </Box>
      <Box>
        <Checkbox
          edge="start"
          tabIndex={1}
          disableRipple
          color="info"
          checked={values.pending}
          onChange={(e) => handleTypeChange(e, pending)}
          inputProps={{
            'aria-label': 'Pending',
          }}
        />
        Pending
      </Box>
      <Box>
        <Checkbox
          edge="start"
          tabIndex={2}
          disableRipple
          color="info"
          checked={values.archived}
          onChange={(e) => handleTypeChange(e, archived)}
          inputProps={{
            'aria-label': 'Archived',
          }}
        />
        Archived
      </Box>
      <Box>
        <Checkbox
          edge="start"
          tabIndex={3}
          disableRipple
          color="info"
          checked={values.draft}
          onChange={(e) => handleTypeChange(e, draft)}
          inputProps={{
            'aria-label': 'Draft',
          }}
        />
        Draft
      </Box>
      {/* <Box>
        <Checkbox
          edge="start"
          tabIndex={4}
          disableRipple
          color="info"
          checked={values.recalled}
          onChange={(e) => handleTypeChange(e, recalled)}
          inputProps={{
            'aria-label': 'Recalled',
          }}
        />
        Recalled
    </Box> */}
    </Box>
  );
};
