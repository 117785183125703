import { format, parseISO } from 'date-fns';

import { Box } from '@liscio/ui';

import { RequestsFilterChip } from '../RequestsFilterChip/RequestsFilterChip';
import { useRequestFilterStore } from 'modules/requests/hooks/useRequestFilterStore';

export const DueDateChip = () => {
  const {
    getFilters,
    showOnlyOverdue,
    setShowOnlyOverdue,
    setDueDateGTE,
    setDueDateLTE,
  } = useRequestFilterStore();

  const dueDateLte = getFilters()['due_date[lte]'];
  const dueDateGte = getFilters()['due_date[gte]'];

  const handleDelete = () => {
    setShowOnlyOverdue(false);
    setDueDateGTE('');
    setDueDateLTE('');
  };

  const getLabel = () => {
    const lte = dueDateLte
      ? format(parseISO(dueDateLte), 'M/d/yyyy')
      : 'not set';
    const gte = dueDateGte
      ? format(parseISO(dueDateGte), 'M/d/yyyy')
      : 'not set';
    return `${gte} - ${lte}`;
  };

  return (
    <Box component="span">
      {showOnlyOverdue && (
        <RequestsFilterChip
          labelKey="Due Date"
          labelValue={'Overdue'}
          handleDelete={handleDelete}
          typeKey="showOnlyOverdue"
        />
      )}
      {!showOnlyOverdue && (dueDateGte || dueDateLte) && (
        <RequestsFilterChip
          labelKey="Due Date"
          labelValue={getLabel()}
          handleDelete={handleDelete}
          typeKey="dueDate"
        />
      )}
    </Box>
  );
};
