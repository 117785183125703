import { useCallback, useEffect, useState } from 'react';

import { LoginInput } from '@liscio/api';
import { getAuthSession, useAuthStore } from '@liscio/common';

import { useLoginMutation, useLogoutMutation } from './auth-hooks';

export function useAuth() {
  const auth_token = useAuthStore(({ auth_token }) => auth_token);
  const email = useAuthStore(({ email }) => email);
  const companyLogoUrl = useAuthStore(({ companyLogoUrl }) => companyLogoUrl);
  const setAuth = useAuthStore(({ setAuth }) => setAuth);
  const isInitialized = useAuthStore(({ isInitialized }) => isInitialized);
  const [mfaCredentials, setMfaCredentials] = useState<LoginInput>();
  const setIsInitialized = useAuthStore(
    ({ setIsInitialized }) => setIsInitialized
  );
  const explicitLogout = useAuthStore(({ explicitLogout }) => explicitLogout);

  const {
    mutateAsync: loginMutation,
    isLoading: isLoggingIn,
    error: loginError,
    reset: resetLogin,
  } = useLoginMutation();
  const { mutate: logout, isLoading: isLoggingOut } = useLogoutMutation();

  // Wrap login to handle MFA required
  const login = useCallback(
    async (data: LoginInput) => {
      try {
        const result = await loginMutation(data);

        // Check for mfa required
        if (result.two_factor_enabled) {
          setMfaCredentials(data);
        }

        return result;
      } catch {
        // error handled by mutation hook
      }
    },
    [loginMutation, setMfaCredentials]
  );

  // Login with stored credentials and mfa pass code
  const loginWithMfaCode = useCallback(
    async (passcode: string) => {
      try {
        if (!mfaCredentials) {
          throw new Error('Missing login credentials for MFA');
        }
        const result = await loginMutation({
          ...mfaCredentials,
          passcode,
        });

        return result;
      } catch {
        // error handled by mutation
      }
    },
    [loginMutation, mfaCredentials]
  );
  const clearMfaCredentials = useCallback(() => {
    setMfaCredentials(undefined);
    // Clear any potential mfa login errors to prevent double dialog
    resetLogin();
  }, [setMfaCredentials, resetLogin]);

  // Initialize auth
  useEffect(() => {
    const initializeAuth = async () => {
      const session = await getAuthSession();

      // Set loaded session to app state
      if (session) {
        setAuth(session);
      }

      setIsInitialized(true);
    };

    if (!isInitialized) {
      initializeAuth();
    }
  }, [isInitialized, setIsInitialized, setAuth]);

  return {
    isInitialized,
    isLoggedIn: Boolean(auth_token),
    logout,
    isLoggingOut,
    login,
    loginWithMfaCode,
    isLoggingIn,
    loginError,
    mfaRequired: Boolean(mfaCredentials),
    mfaEmail: mfaCredentials?.email,
    clearMfaCredentials,
    auth_token,
    email,
    companyLogoUrl,
    explicitLogout,
  };
}

export type UseAuthPayload = ReturnType<typeof useAuth>;
