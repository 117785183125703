import { Box } from '@liscio/ui';

interface RequestsTableFiltersMenuPanelProps {
  children?: React.ReactNode;
  index: number;
  currentIndex: number;
}

export const RequestsTableFiltersMenuPanel = ({
  children,
  currentIndex,
  index,
}: RequestsTableFiltersMenuPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={currentIndex !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {/* Owners filters needs a special panel for fixed header */}
      {currentIndex === index && currentIndex === 2 && (
        <Box height={403} overflow="auto">
          {children}
        </Box>
      )}
      {currentIndex === index && currentIndex !== 2 && (
        <Box p={2} pt={1} height={403} overflow="auto">
          {children}
        </Box>
      )}
    </div>
  );
};
