import { useEffect, useMemo, useState } from 'react';

import { parseISO, subDays } from 'date-fns';

import {
  DatePicker,
  DateValidationError,
  Box,
  Checkbox,
  Typography,
  useTheme,
} from '@liscio/ui';

import { useRequestFilterStore } from 'modules/requests/hooks/useRequestFilterStore';

export const DueDateFiltersPanel = () => {
  const theme = useTheme();

  const {
    getFilters,
    setDueDateGTE,
    setDueDateLTE,
    showOnlyOverdue,
    setShowOnlyOverdue,
  } = useRequestFilterStore();

  const [errorLte, setErrorLte] = useState<DateValidationError | null>(null);
  const [errorGte, setErrorGte] = useState<DateValidationError | null>(null);

  const [values, setValues] = useState<{
    dueDateLte: string;
    dueDateGte: string;
  }>({
    dueDateLte: getFilters()['due_date[lte]'] || '',
    dueDateGte: getFilters()['due_date[gte]'] || '',
  });

  useEffect(() => {
    setDueDateGTE(values.dueDateGte);
    setDueDateLTE(values.dueDateLte);
  }, [values, setDueDateGTE, setDueDateLTE]);

  const handleDateChange = (value: unknown, type: keyof typeof values) => {
    const formattedValue = value ? (value as Date).toISOString() : '';

    setValues((values) => ({ ...values, [type]: formattedValue }));
  };

  const handleShowOnlyOverdueChange = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    const checked = target.checked;

    setShowOnlyOverdue(checked);

    const yesterday = subDays(new Date(), 1);
    const yesterdayDateString = yesterday.toISOString().split('T')[0];
    const timeString = 'T23:59:59.999Z';

    setValues(() => ({
      dueDateLte: checked ? `${yesterdayDateString}${timeString}` : '',
      dueDateGte: '',
    }));
  };

  const getErrorMessage = (error: DateValidationError | null) => {
    switch (error) {
      case 'maxDate': {
        return 'Please select a date that is before the "to" date';
      }
      case 'minDate': {
        return 'Please select a date that is after the "from" date';
      }

      case 'invalidDate': {
        return 'Please select a valid date';
      }

      default: {
        return '';
      }
    }
  };

  const errorMessage = useMemo(() => {
    return {
      lte: getErrorMessage(errorLte),
      gte: getErrorMessage(errorGte),
    };
  }, [errorLte, errorGte]);

  return (
    <Box>
      <Box mt={1} mb={2}>
        <Typography variant="h6" mb={1} color={theme.palette.text.secondary}>
          OVERDUE
        </Typography>
        <Checkbox
          edge="start"
          tabIndex={0}
          disableRipple
          color="info"
          checked={showOnlyOverdue}
          onChange={handleShowOnlyOverdueChange}
          inputProps={{
            'aria-label': 'Show only Overdue',
          }}
        />
        Show only Overdue
      </Box>
      <Box mt={1} mb={2}>
        <Typography variant="h6" mb={1} color={theme.palette.text.secondary}>
          FROM
        </Typography>
        <DatePicker
          disabled={showOnlyOverdue}
          maxDate={values.dueDateLte ? parseISO(values.dueDateLte) : null}
          value={values.dueDateGte ? parseISO(values.dueDateGte) : null}
          onChange={(e) => handleDateChange(e, 'dueDateGte')}
          onError={(newError) => setErrorLte(newError)}
          sx={{
            width: '100%',
          }}
          slotProps={{
            field: {
              clearable: true,
            },
            textField: {
              helperText: errorMessage.lte,
            },
          }}
        />
      </Box>
      <Box>
        <Typography variant="h6" mb={1} color={theme.palette.text.secondary}>
          TO
        </Typography>
        <DatePicker
          disabled={showOnlyOverdue}
          minDate={values.dueDateGte ? parseISO(values.dueDateGte) : null}
          value={values.dueDateLte ? parseISO(values.dueDateLte) : null}
          onChange={(e) => handleDateChange(e, 'dueDateLte')}
          onError={(newError) => setErrorGte(newError)}
          sx={{
            width: '100%',
          }}
          slotProps={{
            field: {
              clearable: true,
            },
            textField: {
              helperText: errorMessage.gte,
            },
          }}
        />
      </Box>
    </Box>
  );
};
