import React, { useEffect } from 'react';

import { Capacitor } from '@capacitor/core';

import {
  ClosePaymentDialogReasons,
  RegisteredAddingCardSessionResponse,
} from '@liscio/api';
import { GlobalLoader, ErrorDialog } from '@liscio/ui';

export interface StripeCardRegistrationStateProps {
  closeFunction(closeReason: ClosePaymentDialogReasons): void;
  registerStripePaymentSessionError?: Error | null;
  stripeRegisteredPaymentSourceSession?: RegisteredAddingCardSessionResponse;
}

export const StripeCardRegistrationState: React.FC<
  StripeCardRegistrationStateProps
> = ({
  closeFunction,
  registerStripePaymentSessionError,
  stripeRegisteredPaymentSourceSession,
}) => {
  useEffect(() => {
    const redirectUrl = stripeRegisteredPaymentSourceSession?.session_id?.url;
    if (redirectUrl) {
      closeFunction(ClosePaymentDialogReasons.VOID);
      if (Capacitor.isNativePlatform()) {
        window.open(redirectUrl);
      } else {
        window.location.replace(redirectUrl);
      }
    }
  }, [closeFunction, stripeRegisteredPaymentSourceSession]);

  return (
    <>
      <GlobalLoader open={!registerStripePaymentSessionError} />
      <ErrorDialog
        error={registerStripePaymentSessionError}
        onClose={() => closeFunction(ClosePaymentDialogReasons.VOID)}
      />
    </>
  );
};

export default StripeCardRegistrationState;
