import React from 'react';

import { TextField } from '@mui/material';
import format from 'date-fns/format';

import { DatePicker, DatePickerProps } from '@liscio/ui';

export interface DateInputProps extends DatePickerProps<Date> {
  error?: boolean;
  helperText?: string;
}

export const DateInput = (props: DateInputProps) => {
  if (!props.format) return <DatePicker sx={{ width: '100%' }} {...props} />;

  return (
    <DatePicker
      sx={{ width: '100%' }}
      {...props}
      slots={{
        textField: TextField,
      }}
      slotProps={{
        textField: {
          error: props.error,
          helperText: props.helperText,
          InputProps: {
            placeholder: '',
            value: props.value ? format(props.value, props.format || '') : '',
          },
        },
      }}
    />
  );
};
