import { Box } from '@liscio/ui';

import { DueDateChip } from './DueDateChip/DueDateChip';
import { LastActivityChip } from './LastActivityChip/LastActivityChip';
import { OwnerChip } from './OwnerChip/OwnerChip';
import { ProgressChip } from './ProgressChip/ProgressChip';
import { StatusChip } from './StatusChip/StatusChip';
import { TypeChip } from './TypeChip/TypeChip';

export const RequestsFilterChips = () => {
  return (
    <Box>
      <StatusChip />
      <TypeChip />
      <DueDateChip />
      <LastActivityChip />
      <ProgressChip />
      <OwnerChip />
    </Box>
  );
};
