type AccountCellProps = {
  account: {
    name: string;
  } | null;
};

export const AccountCell = ({ account }: AccountCellProps) => {
  if (!account) {
    return null;
  }

  return <>{account?.name}</>;
};
