import { useEffect, useMemo, useState } from 'react';

import { parseISO } from 'date-fns';

import {
  DatePicker,
  DateValidationError,
  Box,
  Typography,
  useTheme,
} from '@liscio/ui';

import { useRequestFilterStore } from 'modules/requests/hooks/useRequestFilterStore';

export const LastActivityFiltersPanel = () => {
  const theme = useTheme();
  const [errorLte, setErrorLte] = useState<DateValidationError | null>(null);
  const [errorGte, setErrorGte] = useState<DateValidationError | null>(null);

  const { getFilters, setUpdatedAtGTE, setUpdatedAtLTE } =
    useRequestFilterStore();

  const [values, setValues] = useState<{
    updatedAtLte: string;
    updatedAtGte: string;
  }>({
    updatedAtLte: getFilters()['updated_at[lte]'] || '',
    updatedAtGte: getFilters()['updated_at[gte]'] || '',
  });

  useEffect(() => {
    setUpdatedAtGTE(values.updatedAtGte);
    setUpdatedAtLTE(values.updatedAtLte);
  }, [values, setUpdatedAtGTE, setUpdatedAtLTE]);

  const handleChange = (value: unknown, type: keyof typeof values) => {
    const formattedValue = value ? (value as Date).toISOString() : '';

    setValues((values) => ({ ...values, [type]: formattedValue }));
  };

  const getErrorMessage = (error: DateValidationError | null) => {
    switch (error) {
      case 'maxDate': {
        return 'Please select a date that is before the "to" date';
      }
      case 'minDate': {
        return 'Please select a date that is after the "from" date';
      }

      case 'invalidDate': {
        return 'Please select a valid date';
      }

      default: {
        return '';
      }
    }
  };

  const errorMessage = useMemo(() => {
    return {
      lte: getErrorMessage(errorLte),
      gte: getErrorMessage(errorGte),
    };
  }, [errorLte, errorGte]);

  return (
    <Box>
      <Box mt={1} mb={2}>
        <Typography variant="h6" mb={1} color={theme.palette.text.secondary}>
          FROM
        </Typography>
        <DatePicker
          disableFuture
          maxDate={values.updatedAtLte ? parseISO(values.updatedAtLte) : null}
          value={values.updatedAtGte ? parseISO(values.updatedAtGte) : null}
          onChange={(e) => handleChange(e, 'updatedAtGte')}
          onError={(newError) => setErrorLte(newError)}
          sx={{
            width: '100%',
          }}
          slotProps={{
            field: {
              clearable: true,
            },
            textField: {
              helperText: errorMessage.lte,
            },
          }}
        />
      </Box>
      <Box>
        <Typography variant="h6" mb={1} color={theme.palette.text.secondary}>
          TO
        </Typography>
        <DatePicker
          disableFuture
          minDate={values.updatedAtGte ? parseISO(values.updatedAtGte) : null}
          value={values.updatedAtLte ? parseISO(values.updatedAtLte) : null}
          onChange={(e) => handleChange(e, 'updatedAtLte')}
          onError={(newError) => setErrorGte(newError)}
          sx={{
            width: '100%',
          }}
          slotProps={{
            field: {
              clearable: true,
            },
            textField: {
              helperText: errorMessage.gte,
            },
          }}
        />
      </Box>
    </Box>
  );
};
