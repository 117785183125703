import React, { useCallback, useMemo, useState } from 'react';

import { BiometryType } from '@capgo/capacitor-native-biometric';
import { Control } from 'react-hook-form';

import { useBiometrics } from '@liscio/common';
import { ConfirmationDialog } from '@liscio/ui';

import { Checkbox, RHFCheckbox } from 'components';
import { config } from 'config';

export interface BiometricsToggleProps {
  name: string;
  control: Control<any>;
}

/**
 * Toggle checkbox for enabling biometric authentication.
 * When biometry is enabled unchecking prompts user to confirm disable.
 * Otherwise sets flag for login form to save biotmetry credentials on submit.
 */
export const BiometricsToggle: React.FC<BiometricsToggleProps> = ({
  name,
  control,
}: BiometricsToggleProps) => {
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const {
    isBiometryAvailable,
    biometryType,
    isBiometricLoginEnabled,
    clearBiometricsCredentials,
  } = useBiometrics({ webAppUrl: config.webApp.url });

  const showConfirm = useCallback(
    () => setIsConfirmVisible(true),
    [setIsConfirmVisible]
  );
  const closeConfirm = useCallback(
    () => setIsConfirmVisible(false),
    [setIsConfirmVisible]
  );
  const confirmDisableBiometry = useCallback(async () => {
    await clearBiometricsCredentials();
    closeConfirm();
  }, [clearBiometricsCredentials, closeConfirm]);

  const biometryLabel = useMemo(() => {
    switch (biometryType) {
      // Android
      case BiometryType.FACE_AUTHENTICATION:
        return 'Face Recognition';
      case BiometryType.FINGERPRINT:
        return 'Fingerprint';
      // NOTE: On Android multiple methods may be enabled
      // but we can't discern between them from the app
      case BiometryType.MULTIPLE:
        return 'Biometric Verification';
      // iOS
      case BiometryType.FACE_ID:
        return 'Face ID';
      case BiometryType.TOUCH_ID:
        return 'Touch ID';
      default:
        return '';
    }
  }, [biometryType]);

  if (isBiometryAvailable) {
    // If biometry already enabled, we render a checkbox with a confirm dialog for confirming disable
    if (isBiometricLoginEnabled) {
      return (
        <>
          <Checkbox
            type="inline"
            label={`Enable ${biometryLabel}`}
            checked
            onClick={showConfirm}
          />
          <ConfirmationDialog
            open={isConfirmVisible}
            title={`Disable ${biometryLabel}`}
            content={`Are you sure you want to disable ${biometryLabel} authentication?`}
            onCancel={closeConfirm}
            onConfirm={confirmDisableBiometry}
          />
        </>
      );
    }

    return (
      <RHFCheckbox
        type="inline"
        name={name}
        control={control}
        label={`Enable ${biometryLabel}`}
      />
    );
  }

  return null;
};
