import { useState, useRef, useMemo, useEffect } from 'react';

import { GridRowSelectionModel, gridClasses } from '@mui/x-data-grid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';

import {
  IndexRequestSchema,
  IndexRequestSchemaDataItemStatus,
} from '@liscio/api';
import {
  GridColDef,
  GridRowId,
  GridRowParams,
  GridPaginationModel,
  GridPaginationMeta,
  GridSortModel,
  Box,
} from '@liscio/ui';

import { BulkSendFooter } from './BulkSendFooter';
import { RequestsTableStyled } from './RequestsTable.styled';
import { AccountCell } from '../RequestsTableCells/AccountCell/AccountCell';
import { AssigneeCell } from '../RequestsTableCells/AssigneeCell/AssigneeCell';
import { DueDateCell } from '../RequestsTableCells/DueDateCell/DueDateCell';
import { LastActivityCell } from '../RequestsTableCells/LastActivityCell/LastActivityCell';
import { PercentCompleteCell } from '../RequestsTableCells/PercentCompleteCell/PercentCompleteCell';
import { RowMenuCell } from '../RequestsTableCells/RowMenuCell/RowMenuCell';
import { StatusCell } from '../RequestsTableCells/StatusCell/StatusCell';
import { TypeCell } from '../RequestsTableCells/TypeCell/TypeCell';
import { generateRedirectUrl } from 'components/DesktopSiteRedirect/desktop-site-redirect-utils';
import { useRequestFilterStore } from 'modules/requests/hooks/useRequestFilterStore';

type RequestsTableProps = {
  requestSchema?: IndexRequestSchema;
  loading: boolean;
};

const PAGE_SIZE = 25;

export const RequestsTable = ({
  requestSchema,
  loading,
}: RequestsTableProps) => {
  const [selectedRows, setSelelectedRows] = useState<GridRowSelectionModel>([]);
  const { setNext, setSort, filters, bulkSendModeOn } = useRequestFilterStore();
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const navigate = useNavigate();
  const { requestRefactorFirmShow, requestRefactorDispatchShow } = useFlags();

  const getSortModel = useMemo((): GridSortModel => {
    return filters.sort
      ? [
          {
            field:
              filters?.sort?.[0] === '-' ? filters.sort.slice(1) : filters.sort,
            sort: filters?.sort?.[0] === '-' ? 'desc' : 'asc',
          },
        ]
      : [];
  }, [filters.sort]);

  const rows = requestSchema?.data || [];
  const hasNextPage = !!requestSchema?.meta?.next;
  const nextCursor = requestSchema?.meta?.next;
  const totalRowCount = requestSchema?.meta?.total_entries;

  const paginationMetaRef = useRef<GridPaginationMeta>();
  const mapPageToNextCursor = useRef<{ [page: number]: GridRowId }>({});
  const paginationModel = useRef<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE,
  });

  // reset the pagination model when the filters change/filters.next gets reset
  useEffect(() => {
    if (!filters.next) {
      mapPageToNextCursor.current = {};
      paginationModel.current = { page: 0, pageSize: PAGE_SIZE };
    }
  }, [filters.next]);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    // We have a cursor in the map, we can show the next page.
    if (
      newPaginationModel.page === 0 ||
      mapPageToNextCursor.current[newPaginationModel.page - 1]
    ) {
      paginationModel.current = newPaginationModel;

      const selectedPageCursor =
        mapPageToNextCursor.current[paginationModel.current.page - 1];

      setNext(selectedPageCursor as string);
    }
  };

  // Memoize to avoid flickering when the `hasNextPage` is `undefined` during refetch
  const paginationMeta = useMemo(() => {
    if (
      hasNextPage !== undefined &&
      paginationMetaRef.current?.hasNextPage !== hasNextPage
    ) {
      paginationMetaRef.current = { hasNextPage };
    }
    return paginationMetaRef.current;
  }, [hasNextPage]);

  useEffect(() => {
    if (!loading && nextCursor) {
      // add nextCursor when available
      mapPageToNextCursor.current[paginationModel.current.page] = nextCursor;
    }
  }, [loading, nextCursor]);

  // prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = useState(totalRowCount || 0);
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalRowCount !== undefined ? totalRowCount : prevRowCountState
    );
  }, [paginationMeta?.hasNextPage, totalRowCount]);

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      renderCell: ({ value }) => <StatusCell status={value} />,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 65,
      align: 'center',
      renderCell: ({ value }) => <TypeCell type={value} />,
    },
    { field: 'title', headerName: 'Request Name', minWidth: 300, flex: 1 },
    {
      field: 'assigned_contacts.name', //.name for sortModel
      headerName: 'Assignee',
      flex: 1,
      valueGetter: (_value, row) => row?.assigned_contacts,
      renderCell: ({ value }) => <AssigneeCell assignees={value} />,
    },
    {
      field: 'assigned_account.name', //.name for sortModel
      headerName: 'Account',
      flex: 1,
      valueGetter: (_value, row) => row?.assigned_account,
      renderCell: ({ value }) => <AccountCell account={value} />,
    },
    {
      field: 'owner.name', //.name for sortModel
      headerName: 'Owner',
      flex: 1,
      valueGetter: (_value, row) =>
        row?.owner && `${row.owner?.first_name} ${row.owner?.last_name}`,
    },
    {
      field: 'due_date',
      headerName: 'Due Date',
      width: 150,
      align: 'center',
      renderCell: ({ value }) => <DueDateCell date={value} />,
    },
    {
      field: 'percent_complete',
      headerName: 'Progress',
      width: 86,
      align: 'center',

      renderCell: ({ value }) => <PercentCompleteCell value={value} />,
    },
    {
      field: 'updated_at',
      headerName: 'Last Activity',
      width: 120,
      renderCell: ({ value }) => <LastActivityCell date={value} />,
    },
    {
      field: '_rowMenu',
      headerName: '',
      sortable: false,
      width: 40,
      minWidth: 10,
      align: 'center',
      renderCell: ({ row }) => <RowMenuCell row={row} />,
      cellClassName: 'row-menu-cell',
    },
  ];

  // switch to the legacy request page until the new request/:uuid page is ready
  const handleRowClick = (params: GridRowParams) => {
    if (
      params.row.status === IndexRequestSchemaDataItemStatus.draft &&
      requestRefactorDispatchShow
    ) {
      navigate(`/requests/dispatch/${params.row.uuid}`);
    } else if (params.row.status === IndexRequestSchemaDataItemStatus.draft) {
      window.location.href = generateRedirectUrl(
        `/requests/dispatch/${params.row.uuid}`
      );
    } else if (requestRefactorFirmShow) {
      navigate(`/requests/${params.row.uuid}`);
    } else {
      window.location.href = generateRedirectUrl(
        `/requests/${params.row.uuid}`
      );
    }
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    setSortModel(sortModel);
    setSort(sortModel);
  };

  // make sure the sortModel is reset when the filters.sort is reset/empty string
  useEffect(() => {
    if (!filters.sort) {
      setSortModel([]);
    }
  }, [filters.sort]);

  const getSelectedRows = () => {
    return selectedRows.map((rowId) => rows.find((row) => row.uuid === rowId));
  };

  return (
    // https://github.com/mui/mui-x/issues/8895#issuecomment-1793433389
    <Box sx={{ flex: 1, position: 'relative' }}>
      <Box sx={{ position: 'absolute', inset: 0 }}>
        <RequestsTableStyled
          initialState={{
            sorting: {
              sortModel: getSortModel,
            },
          }}
          checkboxSelection={bulkSendModeOn}
          onRowSelectionModelChange={(rows) => setSelelectedRows(rows)}
          getRowId={(row) => row.uuid}
          rows={rows}
          pageSizeOptions={[PAGE_SIZE]}
          loading={loading}
          columns={columns}
          disableColumnMenu
          columnHeaderHeight={36}
          rowHeight={64}
          rowCount={rowCountState}
          slots={{
            footer: bulkSendModeOn
              ? (props) => (
                  <BulkSendFooter {...props} selectedRows={getSelectedRows()} />
                )
              : undefined,
          }}
          sx={{
            // use css vs slotProps onMouseEnter/Leave to prevent rerendering on row hover
            [`& .row-menu-cell`]: {
              '& > *': {
                opacity: 0,
                transition: 'opacity 0.2s',
              },
            },
            [`& .${gridClasses.row}:hover .row-menu-cell`]: {
              '& > *': {
                opacity: 1,
              },
            },
          }}
          onRowCountChange={(newRowCount) => setRowCountState(newRowCount)}
          paginationMeta={paginationMeta}
          paginationMode="server"
          onPaginationModelChange={handlePaginationModelChange}
          paginationModel={paginationModel.current}
          disableRowSelectionOnClick
          onRowClick={handleRowClick}
          sortModel={sortModel}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
        />
      </Box>
    </Box>
  );
};
