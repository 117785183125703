import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Button } from '@liscio/ui';

import { MarkCompleteRequestDialog } from 'modules/requests/dialogs/MarkCompleteRequestDialog/MarkCompleteRequestDialog';
import { routes } from 'routes';

export type MarkCompleteButtonProps = {
  requestId: string;
};

export const MarkCompleteButton = ({ requestId }: MarkCompleteButtonProps) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        disabled={false}
        variant="contained"
      >
        Mark Complete
      </Button>
      <MarkCompleteRequestDialog
        open={open}
        onClose={() => setOpen(false)}
        requestId={requestId}
        onSuccessfulComplete={() => {
          navigate(routes.requestsListProtected.link());
        }}
      />
    </>
  );
};
