import { useCallback, useEffect, useState } from 'react';

import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';

const REMEMBER_ME_EMAIL = 'remember_me_email';

/**
 * Methods and state for managing "Remember Me" auth feature
 */
export const useRememberMe = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState('');

  // Store email
  const rememberEmail = useCallback(
    async (emailToRemember: string) => {
      await SecureStoragePlugin.set({
        key: REMEMBER_ME_EMAIL,
        value: emailToRemember,
      });
      setEmail(emailToRemember);
    },
    [setEmail]
  );

  // Remove stored email and
  const forgetEmail = useCallback(async () => {
    try {
      await SecureStoragePlugin.remove({ key: REMEMBER_ME_EMAIL });
      setEmail('');
    } catch {
      // Fail silently
    }
  }, [setEmail]);

  useEffect(() => {
    // Init to load data async from storage
    const initRememberMe = async () => {
      try {
        const { value: email } = await SecureStoragePlugin.get({
          key: REMEMBER_ME_EMAIL,
        });
        if (email) {
          setEmail(email);
        }
      } catch {
        // No stored email
      }
      setIsLoading(false);
    };
    initRememberMe();
  }, [setEmail, setIsLoading]);

  return {
    isLoading,
    email,
    rememberEmail,
    forgetEmail,
  };
};
