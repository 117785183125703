export const WORKFLOWS_PUBLIC_DOMAIN = 'requests';
export const WORKFLOWS_PROTECTED_DOMAIN = 'requests';
export const WORKFLOWS_PUBLIC_DOMAIN_URL = `/${WORKFLOWS_PUBLIC_DOMAIN}/*`;
export const WORKFLOWS_PROTECTED_DOMAIN_URL = `/${WORKFLOWS_PROTECTED_DOMAIN}/*`;

export const WORKFLOWS_PATHS = {
  list: '/',
};

export const WORKFLOWS_PAGE_TITLES = {
  list: 'Requests',
};
