import { GetApiV5FirmsRequestsType } from '@liscio/api';
import { useRequestStore, useRequestPdfPreviewLink } from '@liscio/common';
import { IconButton, Menu, useMenuAnchor, styled } from '@liscio/ui';

import {
  ArchiveRequestMenuItem,
  CopyLinkMenuItem,
  ExportZipMenuItem,
  PrintPdfMenuItem,
  RecallRequestMenuItem,
} from './components';

const MenuStyled = styled(Menu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

export const RequestContextMenu = () => {
  const { anchorEl, open, onOpen, onClose } = useMenuAnchor();
  const { requestId, request } = useRequestStore();
  const previewUrl = useRequestPdfPreviewLink(requestId);

  const isEditable = request?.isEditable;

  return (
    <>
      <IconButton
        icon="ellipsis"
        onClick={onOpen}
        aria-label="open request options menu"
        data-testid="requestSectionsContextMenu"
      />
      <MenuStyled anchorEl={anchorEl} open={open} onClose={onClose}>
        <CopyLinkMenuItem onClose={onClose} />
        {isEditable && (
          <RecallRequestMenuItem onClose={onClose} requestId={requestId} />
        )}
        {isEditable && (
          <ArchiveRequestMenuItem onClose={onClose} requestId={requestId} />
        )}
        {request?.type !== GetApiV5FirmsRequestsType.tax_delivery && (
          <PrintPdfMenuItem
            previewUrl={previewUrl}
            onClose={onClose}
            title={request?.title || ''}
          />
        )}
        {request?.type !== GetApiV5FirmsRequestsType.tax_delivery && (
          <ExportZipMenuItem onClose={onClose} requestId={requestId} />
        )}
      </MenuStyled>
    </>
  );
};

export default RequestContextMenu;
