import { Theme } from '@mui/material';

import { IconButton, styled } from '@liscio/ui';

// Define the type for the StyledProps
type StyledProps = {
  theme: Theme;
  bulkSendModeOn: boolean;
};

/**
 * Styled IconButton for the BulkSendToggler component.
 */
export const StyledIconButton = styled(IconButton)(
  ({ theme, bulkSendModeOn }: StyledProps) => ({
    '&:hover': {
      backgroundColor: bulkSendModeOn
        ? theme.palette.info.main
        : theme.palette.common.white,
    },
    color: bulkSendModeOn
      ? theme.palette.common.white
      : theme.palette.info.main,
    backgroundColor: bulkSendModeOn
      ? theme.palette.info.main
      : theme.palette.common.white,
    border: `1px solid ${theme.palette.info.light}`,
    borderRadius: '4px',
    marginLeft: '1rem',
  })
);
