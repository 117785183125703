import { useEffect, useState } from 'react';

import { GetApiV5FirmsRequestsType } from '@liscio/api';
import { Box, Checkbox } from '@liscio/ui';

import { useRequestFilterStore } from 'modules/requests/hooks/useRequestFilterStore';

export const TypeFiltersPanel = () => {
  const { getFilters, setType } = useRequestFilterStore();

  const type = getFilters().type || ([] as GetApiV5FirmsRequestsType[]);

  const { standard, tax_delivery, tax_organizer } = GetApiV5FirmsRequestsType;

  // remember the `type` state is stored as a CSV string in the useRequestFilterStore
  // "standard,tax_delivery,tax_organizer"
  const [values, setValues] = useState<{
    [key in GetApiV5FirmsRequestsType]: boolean;
  }>({
    standard: type?.includes(standard) || false,
    tax_delivery: type?.includes(tax_delivery) || false,
    tax_organizer: type?.includes(tax_organizer) || false,
  });

  useEffect(() => {
    const trueKeys = Object.keys(values).filter(
      (key) => values[key as keyof typeof values]
    );

    setType(trueKeys as GetApiV5FirmsRequestsType[]);
  }, [values, setType]);

  const handleTypeChange = (e: React.ChangeEvent, type: string) => {
    const target = e.target as HTMLInputElement;
    const checked = target.checked;

    setValues((values) => ({ ...values, [type]: checked }));
  };

  return (
    <Box>
      <Box>
        <Checkbox
          edge="start"
          tabIndex={0}
          disableRipple
          color="info"
          checked={values.tax_delivery}
          onChange={(e) => handleTypeChange(e, tax_delivery)}
          inputProps={{
            'aria-label': 'Tax Delivery',
          }}
        />
        Tax Return
      </Box>
      <Box>
        <Checkbox
          edge="start"
          tabIndex={1}
          disableRipple
          color="info"
          checked={values.tax_organizer}
          onChange={(e) => handleTypeChange(e, tax_organizer)}
          inputProps={{
            'aria-label': 'Tax Organizer',
          }}
        />
        Tax Organizer
      </Box>
      <Box>
        <Checkbox
          edge="start"
          tabIndex={2}
          disableRipple
          color="info"
          checked={values.standard}
          onChange={(e) => handleTypeChange(e, standard)}
          inputProps={{
            'aria-label': 'Other',
          }}
        />
        Other
      </Box>
    </Box>
  );
};
