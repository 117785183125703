import { useEffect, useMemo, useRef } from 'react';

import {
  GetApiV5FirmsRequestsParams,
  useGetApiV5FirmsRequests,
} from '@liscio/api';
import { Typography, Skeleton } from '@liscio/ui';

import { useRequestFilterStore } from 'modules/requests/hooks/useRequestFilterStore';

interface TotalCountHeaderProps {
  totalEntries: number | undefined | null;
  loading: boolean;
  filters: Partial<GetApiV5FirmsRequestsParams>;
}

export const TotalCountHeader = ({
  totalEntries,
  loading,
  filters,
}: TotalCountHeaderProps) => {
  const totalCountRef = useRef<TotalCountHeaderProps['totalEntries']>();
  const filtersCount =
    useRef<TotalCountHeaderProps['totalEntries']>(totalEntries);

  const { bulkSendModeOn } = useRequestFilterStore();

  const {
    data: requestSearchData,
    isLoading,
    fetchStatus,
  } = useGetApiV5FirmsRequests(
    { per_page: 1 },
    {
      query: {
        queryKey: ['requests-total-counts'],
        staleTime: Infinity, // never refetch
        retry: false,
      },
    }
  );

  // this sets the initial totalCount
  useEffect(() => {
    if (
      requestSearchData?.meta?.total_entries !== undefined &&
      requestSearchData?.meta?.total_entries !== null
    ) {
      totalCountRef.current = requestSearchData.meta.total_entries;
    }
  }, [requestSearchData]);

  const filtersWithPerPageRemoved = filters;
  // remove default per_page from filters for empty check
  if (filtersWithPerPageRemoved.per_page) {
    delete filtersWithPerPageRemoved.per_page;
  }

  const filtersIsEmpty = Object.keys(filtersWithPerPageRemoved).length === 0;

  const getFiltersCount = useMemo(() => {
    if (
      totalEntries !== undefined &&
      totalEntries !== null &&
      totalEntries !== filtersCount.current
    ) {
      filtersCount.current = totalEntries;
    }
    return filtersCount.current;
  }, [totalEntries]);

  useEffect(() => {
    // only update if the filters are empty meaning a "new total" count has been returned
    if (filtersIsEmpty) {
      totalCountRef.current = totalEntries;
    }
  }, [filtersIsEmpty, totalEntries]);

  const totalCount = filtersIsEmpty ? totalEntries : totalCountRef.current;

  const initLoading = isLoading && fetchStatus !== 'idle';
  const textColor = loading ? 'GrayText' : 'textPrimary';

  const totalCountText = bulkSendModeOn
    ? `Bulk edit drafts (${getFiltersCount || '0'})`
    : `Requests (${getFiltersCount || '0'} of ${totalCount || '0'})`;

  return (
    <Typography pl={1} variant="h2" color={textColor}>
      {totalCount && !initLoading ? (
        totalCountText
      ) : (
        // skeletons testid gets stripped when used inside a Typography component
        // discussions around accessibility https://github.com/mui/material-ui/issues/26930
        <Skeleton data-testid="skeleton" width={500} />
      )}
    </Typography>
  );
};
