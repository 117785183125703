import { CustomToast } from '@liscio/common';

export const CopyUrlSuccessToast = () => {
  return (
    <CustomToast
      icon="copy"
      severity="success"
      message="Link copied to the clipboard"
    />
  );
};
