import { useEffect, useState } from 'react';

import { Box, Checkbox, Slider, Stack, Typography, useTheme } from '@liscio/ui';

import { useRequestFilterStore } from 'modules/requests/hooks/useRequestFilterStore';

export const ProgressFiltersPanel = () => {
  const theme = useTheme();

  const {
    getFilters,
    setPercentCompleteGTE,
    setPercentCompleteLTE,
    setNotStarted,
  } = useRequestFilterStore();

  const notStarted = getFilters().not_started || false;
  const percentCompleteLte = getFilters()['percent_complete[lte]'];
  const percentCompleteGte = getFilters()['percent_complete[gte]'];

  // for onChangeCommitted changes/submit values to filters
  const [committedValues, setCommittedValues] = useState<{
    percentCompleteLte: number;
    percentCompleteGte: number;
  }>({
    percentCompleteLte: percentCompleteLte || 100,
    percentCompleteGte: percentCompleteGte || 0,
  });

  // for onChange changes/update the UI
  const [values, setValues] = useState<{
    percentCompleteLte: number;
    percentCompleteGte: number;
  }>({
    percentCompleteLte: percentCompleteLte || 100,
    percentCompleteGte: percentCompleteGte || 0,
  });

  useEffect(() => {
    setPercentCompleteGTE(committedValues.percentCompleteGte);
    setPercentCompleteLTE(committedValues.percentCompleteLte);
  }, [
    committedValues,
    setNotStarted,
    setPercentCompleteGTE,
    setPercentCompleteLTE,
  ]);

  const handleNotStartedChange = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    const checked = target.checked;

    setNotStarted(checked);
  };

  const handleSliderChange = (
    e: Event | React.SyntheticEvent,
    newValue: number | number[]
  ) => {
    const [percentCompleteGte, percentCompleteLte] = newValue as number[];

    setValues((values) => ({
      ...values,
      percentCompleteLte,
      percentCompleteGte,
    }));
  };

  const handleSliderChangeCommitted = (
    e: Event | React.SyntheticEvent,
    newValue: number | number[]
  ) => {
    const [percentCompleteGte, percentCompleteLte] = newValue as number[];

    setCommittedValues({
      percentCompleteLte,
      percentCompleteGte,
    });
  };

  return (
    <Box maxWidth={298} mt={1.5} overflow="hidden">
      <Box>
        <Typography variant="h6" mb={1} color={theme.palette.text.secondary}>
          NOT STARTED
        </Typography>
        <Checkbox
          edge="start"
          tabIndex={0}
          disableRipple
          color="info"
          checked={notStarted}
          onChange={handleNotStartedChange}
          inputProps={{
            'aria-label': 'Show only Not Started',
          }}
        />
        Show only Not Started
      </Box>
      <Box mt={4}>
        <Typography variant="h6" mb={1} color={theme.palette.text.secondary}>
          PROGRESS
        </Typography>
        <Typography variant="body1">
          {values.percentCompleteGte || 0} to {values.percentCompleteLte || 100}
        </Typography>
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
          mt={1}
        >
          <Box width={265} mt={64}>
            <Slider
              getAriaLabel={() => 'Prgoress range'}
              disabled={notStarted}
              value={[values.percentCompleteGte, values.percentCompleteLte]}
              onChange={handleSliderChange}
              onChangeCommitted={handleSliderChangeCommitted}
              valueLabelDisplay="auto"
              disableSwap
              color="secondary"
              step={5}
            />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
