/* eslint-disable no-console */
import { MouseEvent, useState } from 'react';

import toast from 'react-hot-toast';

import {
  IndexRequestSchemaDataItem,
  usePostApiV5FirmsRequestsIdZip,
} from '@liscio/api';
import { useAuthStore } from '@liscio/common';
import {
  Icon,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useTheme,
} from '@liscio/ui';

import {
  RowMenuRecallRequestDialog,
  RowMenuArchiveRequestDialogDialog,
} from './components';
import { DeleteRequestDialog } from '../../../dialogs/DeleteRequestDialog/DeleteRequestDialog';
import { EditDrawer } from '../../EditDrawer/EditDrawer';
import { config } from 'config';
import { ReOpenRequestDialog } from 'modules/requests/dialogs/ReOpenRequestDialog/ReOpenRequestDialog';
import { RequestPdfDialog } from 'modules/requests/dialogs/RequestPdfDialog/RequestPdfDialog';

type RowMenuProps = {
  row: IndexRequestSchemaDataItem;
  anchorEl: Element | null;
  handleClose: (event: React.MouseEvent<Element>) => void;
};

export const RowMenu = ({ row, anchorEl, handleClose }: RowMenuProps) => {
  const theme = useTheme();
  const authToken = useAuthStore(({ auth_token }) => auth_token);
  const { mutateAsync: startZipExport } = usePostApiV5FirmsRequestsIdZip();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isArchiveRequestDialogOpen, setIsArchiveRequestDialogOpen] =
    useState(false);
  const [isRecallRequestDialogOpen, setIsRecallRequestDialogOpen] =
    useState(false);
  const [isDeleteRequestDialogOpen, setIsDeleteRequestDialogOpen] =
    useState(false);
  const [isReOpenRequestDialogOpen, setIsReOpenRequestDialogOpen] =
    useState(false);

  const [showPdf, setShowPdf] = useState(false);

  const requestStatusMenuItemsMap = {
    open: ['edit', 'recall', 'archive', 'print-pdf', 'exportZip'],
    pending: ['edit', 'reopen', 'recall', 'archive', 'print-pdf', 'exportZip'],
    archived: [],
    draft: ['edit', 'delete'],
  };

  const menuItemNames: string[] =
    requestStatusMenuItemsMap[
      row.status as keyof typeof requestStatusMenuItemsMap
    ] || [];

  const handleQuickEditClick = (event: MouseEvent) => {
    handleClose(event);
    setIsDrawerOpen(true);
  };

  const handleQuickEditCancel = (event: MouseEvent) => {
    handleClose(event);
    setIsDrawerOpen(false);
  };

  const handleArchiveClick = (event: MouseEvent) => {
    handleClose(event);
    setIsArchiveRequestDialogOpen(true);
  };

  const handleArchiveRequestDialogClose = () => {
    setIsArchiveRequestDialogOpen(false);
  };

  const handleReopenClick = (event: MouseEvent) => {
    handleClose(event);
    setIsReOpenRequestDialogOpen(true);
  };

  const handleReopenRequestDialogClose = () => {
    setIsReOpenRequestDialogOpen(false);
  };

  const handleRecallClick = (event: MouseEvent) => {
    handleClose(event);
    setIsRecallRequestDialogOpen(true);
  };

  const handleRecallRequestDialogClose = () => {
    setIsRecallRequestDialogOpen(false);
  };

  const handlePrintPdfClick = (event: MouseEvent) => {
    handleClose(event);
    setShowPdf(true);
  };

  const handleExportZipClick = (event: MouseEvent) => {
    startZipExport(
      { id: row.uuid || '' },
      {
        onSuccess: () => {
          toast.success(
            'ZIP export successfully initiated. Depending on the size of this request and any uploaded files, it may take some time to process. Once complete it will become available for download from the Liscio Vault.',
            {
              duration: 5000,
            }
          );
          handleClose(event);
        },
        onError: (error) => {
          toast.error('Error starting zip export.');
          console.error('Error starting zip export:', error);
          handleClose(event);
        },
      }
    );
    handleClose(event);
  };

  const handleDeleteClick = (event: MouseEvent) => {
    handleClose(event);
    setIsDeleteRequestDialogOpen(true);
  };

  const handleDeleteRequestDialogClose = () => {
    setIsDeleteRequestDialogOpen(false);
  };

  return (
    <>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {menuItemNames.includes('edit') && (
          <MenuItem onClick={handleQuickEditClick}>
            <ListItemIcon>
              <Icon icon="penOutline" fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit Request</ListItemText>
          </MenuItem>
        )}
        {menuItemNames.includes('archive') && (
          <MenuItem onClick={handleArchiveClick}>
            <ListItemIcon>
              <Icon icon="archive" fontSize="small" />
            </ListItemIcon>
            <ListItemText>Archive Request</ListItemText>
          </MenuItem>
        )}
        {menuItemNames.includes('reopen') && (
          <MenuItem onClick={handleReopenClick}>
            <ListItemIcon>
              <Icon icon="folderOpen" fontSize="small" />
            </ListItemIcon>
            <ListItemText>Re-open Request</ListItemText>
          </MenuItem>
        )}
        {menuItemNames.includes('recall') && (
          <MenuItem onClick={handleRecallClick}>
            <ListItemIcon>
              <Icon icon="recurringCharge" fontSize="small" />
            </ListItemIcon>
            <ListItemText>Recall Request</ListItemText>
          </MenuItem>
        )}
        {menuItemNames.includes('print-pdf') && (
          <MenuItem onClick={handlePrintPdfClick}>
            <ListItemIcon>
              <Icon icon="print" fontSize="small" />
            </ListItemIcon>
            <ListItemText>Print PDF</ListItemText>
          </MenuItem>
        )}
        {menuItemNames.includes('exportZip') && (
          <MenuItem onClick={handleExportZipClick}>
            <ListItemIcon>
              <Icon icon="fileZip" fontSize="small" />
            </ListItemIcon>
            <ListItemText>Export Zip</ListItemText>
          </MenuItem>
        )}
        {menuItemNames.includes('delete') && (
          <MenuItem
            iconBefore={<Icon icon="delete" fontSize="small" />}
            label="Delete"
            color={theme.palette.error.main}
            onClick={handleDeleteClick}
          />
        )}
      </Menu>
      <EditDrawer
        row={row}
        open={isDrawerOpen}
        onCancel={handleQuickEditCancel}
        onClose={() => setIsDrawerOpen(false)}
      />
      <DeleteRequestDialog
        open={isDeleteRequestDialogOpen}
        onClose={handleDeleteRequestDialogClose}
        row={row}
      />
      <RowMenuArchiveRequestDialogDialog
        open={isArchiveRequestDialogOpen}
        row={row}
        onClose={handleArchiveRequestDialogClose}
      />
      <ReOpenRequestDialog
        open={isReOpenRequestDialogOpen}
        row={row}
        onClose={handleReopenRequestDialogClose}
      />
      <RowMenuRecallRequestDialog
        open={isRecallRequestDialogOpen}
        row={row}
        onClose={handleRecallRequestDialogClose}
      />
      <RequestPdfDialog
        open={showPdf}
        onClose={() => setShowPdf(false)}
        title={row.title || ''}
        url={`${config.api.url}/api/v5/workflow/${row.uuid}/to_pdf?token=${authToken}`}
      />
    </>
  );
};
