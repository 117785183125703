import { styled, DataGrid, gridClasses } from '@liscio/ui';

export const RequestsTableStyled = styled(DataGrid)<{
  hasFilters?: boolean;
}>(({ theme }) => ({
  fontWeight: 400,
  letterSpacing: 'inherit',
  color: theme.palette.text.primary,
  minWidth: '600px',
  borderRadius: '0px',
  [`& .${gridClasses.overlayWrapper}`]: {
    height: '500px',
  },
  [`& .${gridClasses.columnHeaders}`]: {
    [`& .${gridClasses.columnHeader}`]: {
      background: theme.palette.grey[100],
    },
  },
  [`& .${gridClasses.columnHeader}:focus`]: {
    outline: 'none',
  },
  [`& .${gridClasses.cell}:focus`]: {
    outline: 'none',
  },
  [`& .${gridClasses.columnHeaderTitle}`]: {
    color: theme.palette.text.secondary,
    fontWeight: 600,
  },
  [`& .${gridClasses.row}`]: {
    cursor: 'pointer',
  },
  [`& .${gridClasses.footerContainer}`]: {
    minHeight: '65px',
  },
  [`& .${gridClasses.scrollbarFiller}`]: {
    background: theme.palette.grey[100],
  },
  [`& .${gridClasses.columnSeparator}`]: {
    display: 'none',
  },
}));
