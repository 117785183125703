import { Button, Drawer, styled } from '@liscio/ui';

/**
 * Styled components for the QuickEditDrawer component.
 */

export const StyledButton = styled(Button)({
  marginTop: '1rem',
  paddingTop: 5,
  paddingBottom: 5,
  paddingLeft: 100,
  paddingRight: 100,
  borderRadius: 2,
});

export const StyledDrawer = styled(Drawer)({
  '& .MuiDrawer-paper': {
    backgroundColor: 'white',
    color: 'inherit',
    maxWidth: '42rem',
    width: '42rem',
  },
});
