import { timeAgo } from '@liscio/utils';

interface LastActivityCellProps {
  date: string;
}

export const LastActivityCell = ({ date }: LastActivityCellProps) => {
  const parseDateToTimeAgo = (date: string) => {
    const createDate = new Date(date);
    return timeAgo(createDate, undefined, 'MMM, do, yyyy');
  };
  return date ? parseDateToTimeAgo(date) : '';
};
