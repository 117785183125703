import { GetApiV5FirmsRequestsType } from '@liscio/api';
import { Icon, IconType, Tooltip } from '@liscio/ui';

type TypeCellProps = {
  type: GetApiV5FirmsRequestsType | undefined;
};

export const TypeCell = ({ type }: TypeCellProps) => {
  const iconNameMap: {
    [key in GetApiV5FirmsRequestsType]: { icon: IconType; label: string };
  } = {
    tax_delivery: { icon: 'taxDoc', label: 'Tax Return' },
    tax_organizer: { icon: 'folder', label: 'Tax Organizer' },
    standard: { icon: 'file', label: 'Other' },
  };

  const getIcon = iconNameMap[type as keyof typeof iconNameMap];

  return getIcon ? (
    <Tooltip title={getIcon.label} placement="top">
      <Icon
        icon={getIcon.icon}
        fontSize="small"
        sx={(theme) => ({ color: theme.palette.text.secondary, mt: 3 })}
      />
    </Tooltip>
  ) : null;
};
