import { useCallback, useMemo, useState } from 'react';

import {
  debounce,
  InputAdornment,
  inputBaseClasses,
  autocompleteClasses,
} from '@mui/material';

// import { useGetApiV5FirmsRequests } from '@liscio/api';
import { IndexRequestSchemaDataItem } from '@liscio/api';
import { AutocompleteField, Box, Icon, Typography, IconButton } from '@liscio/ui';

import { useRequestFilterStore } from 'modules/requests/hooks/useRequestFilterStore';

export function RequestsSearchInput() {
  const { setQuery: setQueryFilter, getFilters } = useRequestFilterStore();

  const filters = getFilters();
  const [input, setInput] = useState(filters.query ?? '');

  // const { data: requestSearchData, isLoading } = useGetApiV5FirmsRequests(
  //   {
  //     ...filters,
  //     query: input,
  //   },
  //   {
  //     query: { enabled: Boolean(input) },
  //   }
  // );

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      debouncedOnInputChange.clear();
      updateQuery(input);
    }
  };

  // const onSelect = (e: React.SyntheticEvent, value: string) => {
  //   // onSelectRequest(value.uuid as string);
  // };

  const handleInputChange = (e: React.SyntheticEvent, value: string) => {
    setInput(value);
    debouncedOnInputChange(value);
  };

  const updateQuery = useCallback(
    (value: string) => {
      const trimmedValue = value.trim();
      if (trimmedValue.length < 3) {
        setQueryFilter('');
        return;
      }
      setInput(trimmedValue);
      setQueryFilter(trimmedValue);
    },
    [setInput, setQueryFilter]
  );

  const debouncedOnInputChange = useMemo(
    () => debounce(updateQuery, 500),
    [updateQuery]
  );

  const onClearClick = () => {
    setInput('');
    setQueryFilter('');
  };

  const options: IndexRequestSchemaDataItem[] = [];

  return (
    <AutocompleteField
      freeSolo
      open={false}
      placeholder="Search request name, assignee, account, or owner..."
      options={options}
      value={input}
      loading={false && /*isLoading &&*/ input.length > 2}
      onInputChange={handleInputChange}
      renderInputProps={{
        onKeyDown: onKeyDown,
        startAdornment: (
          <InputAdornment position="start">
            <Icon fontSize="inherit" icon="search"></Icon>
          </InputAdornment>
        ),
        endAdornment: input.length > 0 ? (
          <InputAdornment position="end" sx={{ mr: -4 }}>
            <IconButton
              aria-label="clear-search"
              onClick={onClearClick}
              edge="end"
              size="small"
            >
              <Icon icon="circleX" />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Box>
              <Box>
                <Typography variant="h6">{option.title}</Typography>
              </Box>
              <Box>
                <Typography>{`${option.owner?.first_name} ${option.owner?.last_name}`}</Typography>
              </Box>
            </Box>
          </li>
        );
      }}
      isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
      /* this is to avoid displaying Object[Object] in the input while opening the selected request */
      getOptionLabel={() => input}
      sx={(theme) => ({
        padding: theme.spacing(2),
        width: '33rem',
        [`& .${inputBaseClasses.root}`]: { borderRadius: '2px' },
        [`& .${autocompleteClasses.clearIndicator}`]: { visibility: 'visible' },
      })}
    />
  );
}
