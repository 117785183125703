/* eslint-disable react-hooks/exhaustive-deps */
import { MouseEvent, useEffect, useRef, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { format, parse } from 'date-fns';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import {
  getGetApiV5FirmsRequestsIdQueryKey,
  RequestSchemaStatus,
  useGetApiV5FirmsRequestsId,
  usePostApiV5DocumentsSignedUrls,
  usePostApiV5FirmsWorkflowRequestsBulkDispatch,
  usePutApiV5FirmsRequestsId,
} from '@liscio/api';
import { RecipientSelector } from '@liscio/common';
import { Stack, InputLabel, Link, Icon, ErrorDialog } from '@liscio/ui';

import { StyledButton } from './StyledComponents';
import { SelectOwnerDropdown } from '../../../../components/SelectOwnerDropdown/SelectOwnerDropdown';
import { RHFDateInput } from 'components';
import { RequestPdfDialog } from 'modules/requests/dialogs/RequestPdfDialog/RequestPdfDialog';
import { useRequestFilterStore } from 'modules/requests/hooks/useRequestFilterStore';
import { routes } from 'routes';

type RequestDispatchProps = {
  open: boolean;
  requestId: string;
  width?: string;
  maxWidth?: string;
};

type FormDataType = {
  title?: string;
  owner?: string;
  dueDate?: string;
  assignedAccountId?: string;
  assignedContactIds?: string[];
};

const DATE_FORMAT = 'MMM d, yyyy';

/**
 * This component is used to edit a request in a drawer.
 */
export function RequestDispatch({
  open,
  requestId,
  width,
  maxWidth,
}: RequestDispatchProps) {
  const { data: requestData, isLoading } = useGetApiV5FirmsRequestsId(
    requestId || '',
    { query: { enabled: open } }
  );
  const { mutateAsync: getSignedUrl } = usePostApiV5DocumentsSignedUrls();
  const { mutateAsync: updateRequest } = usePutApiV5FirmsRequestsId();
  const { mutateAsync: dispatchRequest } =
    usePostApiV5FirmsWorkflowRequestsBulkDispatch();
  const { getRefetchRequests } = useRequestFilterStore();
  const [signedUrl, setSignedUrl] = useState('');
  const [showPdf, setShowPdf] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const updating = useRef(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    control,
    reset,
    trigger,
    getValues,
    formState: { isValid, isDirty },
  } = useForm<FormDataType>({
    mode: 'onChange',
    defaultValues: {
      title: '',
      owner: '',
      dueDate: '',
      assignedAccountId: '',
      assignedContactIds: [],
    },
  });

  useEffect(() => {
    if (requestData && open) {
      if (
        requestData.source_documents &&
        requestData.source_documents.length > 0
      ) {
        getSignedUrl(
          {
            data: {
              disposition: 'inline',
              retrieval_token:
                requestData.source_documents[0].retrieval_token || '',
            },
          },
          {
            onSuccess: (data) => {
              setSignedUrl(data.signed_url || '');
            },
            onError: (error) => {
              toast.error('Error getting signed URL.');
              console.error('Error getting signed URL:', error);
            },
          }
        );
      }
    }
  }, [requestData, reset, open]);

  useEffect(() => {
    if (requestData) {
      let dueDate = '';
      try {
        dueDate = requestData.due_date
          ? new Date(requestData.due_date).toISOString().split('T')[0]
          : '';
      } catch (error) {
        console.error('Error parsing date:', error);
      }

      reset({
        title: requestData.title || '',
        owner: requestData.owner?.uuid || '',
        dueDate,
        assignedAccountId: requestData.assigned_account?.uuid || '',
        assignedContactIds: requestData.assigned_contacts?.map(
          (contact) => contact.uuid
        ),
      });
      setInitialLoad(false);
    }
  }, [requestData, reset]);

  useEffect(() => {
    if (!initialLoad) {
      // this is necessary so that validation happens on initial load
      // so we can disable the submit if necessary
      trigger();
    }
  }, [initialLoad]);

  useEffect(() => {
    if (isDirty && !updating.current) {
      updating.current = true;
      const formValues = getValues();
      onFormChange(formValues);
    }
  }, [isDirty, getValues]);

  const onFormChange = (formData: FormDataType) => {
    let isoDate = '';
    try {
      isoDate = formData.dueDate
        ? format(
            parse(formData.dueDate, 'yyyy-MM-dd', new Date()),
            "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
          )
        : '';
    } catch (error) {
      console.error('Error parsing date:', error);
    }

    // Handle form "submission"
    updateRequest(
      {
        id: requestId || '',
        data: {
          title: formData.title,
          owner_id: formData.owner,
          due_date: isoDate,
          assigned_contact_ids: formData.assignedContactIds,
          assigned_account_id: formData.assignedAccountId,
        },
      },
      {
        onSuccess: (response) => {
          const refetchRequests = getRefetchRequests();
          queryClient.invalidateQueries(
            getGetApiV5FirmsRequestsIdQueryKey(requestId || '')
          );
          if (refetchRequests) {
            refetchRequests();
          }

          reset(
            {
              title: response.title || '',
              owner: response.owner?.uuid || '',
              dueDate: response.due_date
                ? new Date(response.due_date).toISOString().split('T')[0]
                : '',
              assignedAccountId: response.assigned_account?.uuid || '',
              assignedContactIds: response.assigned_contacts?.map(
                (contact) => contact.uuid
              ),
            },
            {
              keepIsValid: true,
              keepErrors: true,
            }
          );
          updating.current = false;
          // navigate(routes.requestsListProtected.link());
        },
        onError: (error) => {
          toast.error('Error updating request.');
          console.error('Error updating request:', error);
          updating.current = false;
        },
      }
    );
  };

  const handleDispatch = () => {
    dispatchRequest(
      {
        data: {
          request: {
            ids: [requestId || ''],
          },
        },
      },
      {
        onSuccess: () => {
          const refetchRequests = getRefetchRequests();
          if (refetchRequests) {
            refetchRequests();
          }
          toast.success('Request dispatched successfully.');
          navigate(routes.requestsListProtected.link());
        },
        onError: (error) => {
          toast.error('Error dispatching request.');
          console.error('Error dispatching request:', error);
        },
      }
    );
  };

  const handleSourceFileClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowPdf(true);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (
    requestData?.status !== RequestSchemaStatus.draft &&
    (requestData?.status as unknown) !== 'new_request'
  ) {
    return (
      <ErrorDialog
        title={requestData?.title}
        error={'Cannot dispatch this request.'}
        errorContent={'Cannot dispatch this request.'}
        closeLabel={'Close'}
        onClose={() => navigate(routes.requestsListProtected.link())}
      />
    );
  }
  return (
    <>
      <form
        onSubmit={handleSubmit(handleDispatch)}
        style={{ height: '100%', width, maxWidth }}
      >
        <Stack height="100%">
          <Stack flexGrow={0.97} gap={2} padding="1rem">
            {requestData?.source_documents &&
              requestData.source_documents.length > 0 && (
                <Stack>
                  <InputLabel>SOURCE FILE</InputLabel>
                  <Link
                    href={'#'}
                    onClick={handleSourceFileClick}
                    display="flex"
                    alignItems="center"
                  >
                    <Icon icon="filePdf" sx={{ marginRight: '5px' }} />
                    {requestData?.source_documents?.[0]?.filename}
                  </Link>
                </Stack>
              )}
            <Stack>
              <InputLabel>OWNER</InputLabel>
              <SelectOwnerDropdown control={control} name="owner" />
            </Stack>
            <Stack>
              <InputLabel>DUE DATE</InputLabel>
              <RHFDateInput
                name="dueDate"
                control={control}
                required={true}
                format={DATE_FORMAT}
                valueFormat="yyyy-MM-dd"
                minDate={new Date()}
              />
            </Stack>
            <Stack>
              <RecipientSelector
                name="recipients"
                control={control}
                account={requestData?.assigned_account}
                assignedContacts={requestData?.assigned_contacts}
                required={true}
              />
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-evenly"
            padding={1}
            sx={(theme) => ({
              borderTop: `1px solid ${theme.palette.divider}`,
            })}
          >
            <StyledButton
              disabled={!isValid}
              variant="contained"
              color="primary"
              type="submit"
            >
              Send
            </StyledButton>
          </Stack>
        </Stack>
      </form>
      {requestData &&
        requestData.source_documents &&
        requestData.source_documents.length > 0 && (
          <RequestPdfDialog
            open={showPdf}
            onClose={() => setShowPdf(false)}
            url={signedUrl}
            title={requestData.source_documents[0].filename || ''}
          />
        )}
    </>
  );
}
