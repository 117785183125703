import toast from 'react-hot-toast';

import { usePostApiV5FirmsRequestsIdArchive } from '@liscio/api';
import { Dialog, Typography } from '@liscio/ui';

export type ArchiveRequestDialogProps = {
  open: boolean;
  requestId: string;
  onClose: () => void;
  onSuccessfulRecall?: () => void;
  onErroredRecall?: (error: unknown) => void;
};

/**
 * This component is used to archive a request.
 */
export const ArchiveRequestDialog = ({
  open,
  requestId,
  onClose,
  onSuccessfulRecall,
  onErroredRecall,
}: ArchiveRequestDialogProps) => {
  const { mutateAsync: archiveRequestMutation, isLoading } =
    usePostApiV5FirmsRequestsIdArchive({
      mutation: {
        onSuccess: () => {
          if (onSuccessfulRecall) {
            onSuccessfulRecall();
          }
        },
        onError: (error) => {
          if (onErroredRecall) {
            onErroredRecall(error);
          }
          toast.error('Error recalling request');
          console.error('Error recalling request:', error);
        },
        onSettled: () => {
          onClose();
        },
      },
    });

  const handleRecall = () => {
    archiveRequestMutation({
      id: requestId || '',
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Archive this request?"
      iconClose
      onSubmit={handleRecall}
      submitLabel="Yes, archive"
      submitBtnProps={{ color: 'error', disabled: isLoading }}
      cancelLabel="No, cancel"
      onCancel={onClose}
      cancelBtnProps={{ color: 'primary', disabled: isLoading }}
    >
      <Typography>
        Are you sure you want to archive this request for this recipient?
      </Typography>
      <Typography>Once archived, the request cannot be resent.</Typography>
    </Dialog>
  );
};
