import { NavMenuItem } from '@liscio/common';
import {
  Icon,
  Menu,
  useMenuAnchor,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from '@liscio/ui';

import MoreMenuItem from './components/MoreMenuItem/MoreMenuItem';

export const AddNewNavMenuItem = () => {
  const { anchorEl, open, onOpen, onClose } = useMenuAnchor();

  return (
    <>
      <NavMenuItem
        data-testid="sidebar__add_new"
        icon={<Icon icon="plusSquare" />}
        label="Add New"
        onClick={onOpen}
        selected={open}
        disableRipple
        sx={(theme) => ({
          '&.Mui-selected': {
            bgcolor: theme.palette.common.tertiary[400],
            '&:hover': {
              bgcolor: theme.palette.common.tertiary[400],
            },
          },
        })}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'center',
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
        slotProps={{ paper: { sx: { width: 220 } } }}
        sx={{ ml: 1, svg: { fontSize: '18px' } }}
      >
        <MenuItem href="/account/new">
          <ListItemIcon>
            <Icon icon="accounts" />
          </ListItemIcon>
          <ListItemText>Account</ListItemText>
        </MenuItem>
        <MenuItem href="/contact/new">
          <ListItemIcon>
            <Icon icon="contacts" />
          </ListItemIcon>
          <ListItemText>Contact</ListItemText>
        </MenuItem>
        <MoreMenuItem label="Task" icon={<Icon icon="tasks" />}>
          <MenuItem href="/task/new?type=request_info">
            <ListItemText>Request Information</ListItemText>
          </MenuItem>
          <MenuItem href="/task/new?type=virtual_meeting">
            <ListItemText>Meeting</ListItemText>
          </MenuItem>
          <MenuItem href="/task/new?type=get_signature">
            <ListItemText>Get a Signature</ListItemText>
          </MenuItem>
          <MenuItem href="/task/new?type=to_do">
            <ListItemText>To Do</ListItemText>
          </MenuItem>
          <MenuItem href="/task/new?type=manage_to_go_items">
            <ListItemText>Manage To Go Items</ListItemText>
          </MenuItem>
        </MoreMenuItem>
        <MenuItem to="/requests?modal=newrequest">
          <ListItemIcon>
            <Icon icon="requests" />
          </ListItemIcon>
          <ListItemText>Requests</ListItemText>
        </MenuItem>
        <MenuItem href="/message/new">
          <ListItemIcon>
            <Icon icon="messageLines" />
          </ListItemIcon>
          <ListItemText>Message</ListItemText>
        </MenuItem>
        <MenuItem href="/note/new">
          <ListItemIcon>
            <Icon icon="fileLines" />
          </ListItemIcon>
          <ListItemText>Note</ListItemText>
        </MenuItem>
        <MenuItem href="/files/new">
          <ListItemIcon>
            <Icon icon="folder" />
          </ListItemIcon>
          <ListItemText>File</ListItemText>
        </MenuItem>
        <MenuItem href="/new_esign">
          <ListItemIcon>
            <Icon icon="edoc" />
          </ListItemIcon>
          <ListItemText>eDoc</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
