import React, { useEffect } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { RequestSchemaStatus } from '@liscio/api';
import { WorkflowRequestStatus } from '@liscio/api/graphql';
import {
  RequestSectionList,
  RequestDetailsPanel,
  RequestSubDetailsPanel,
  useRequestStore,
  useUpdateWorkflowRequest,
} from '@liscio/common';
import { Button, Divider, Stack } from '@liscio/ui';

import { MarkCompleteButton } from '../components/MarkCompleteButton/MarkCompleteButton';
import { useRequestDesktopView } from '../hooks/useRequestDesktopView';
import RequestContextMenu from 'modules/requests/components/RequestContextMenu/RequestContextMenu';
import { RequestEdit } from 'modules/requests/components/RequestEdit/RequestEdit';

const DefaultRequestViewDesktopV5 = React.lazy(() =>
  import('@liscio/common').then((module) => ({
    default: module.DefaultRequestViewDesktopV5,
  }))
);

type RequestDesktopFirmViewProps = {
  id: string;
  status: RequestSchemaStatus;
};

export function RequestDesktopFirmView({
  id,
  status,
}: RequestDesktopFirmViewProps) {
  const { visibleSections, setDisableEdit, disableEdit, request } =
    useRequestStore();
  const { mutate: updateWorkflowRequest } = useUpdateWorkflowRequest();
  const { workflowOpened: workflowOpenedFlag } = useFlags();

  // set open if assignee is viewing the request
  useEffect(() => {
    if (status === RequestSchemaStatus.pending && workflowOpenedFlag) {
      updateWorkflowRequest({
        workflowRequestId: id,
        status: WorkflowRequestStatus.Open,
      });
    }
  }, [id, status, updateWorkflowRequest, workflowOpenedFlag]);

  const {
    rightPanelOpen: editDrawerOpen,
    leftPanelOpen: detailPanelOpen,
    onOpenLeftPanel,
    onCloseLeftPanel,
    onOpenRightPanel,
    onCloseRightPanel,
    handleOnSectionClick,
  } = useRequestDesktopView({ id });

  return (
    <DefaultRequestViewDesktopV5
      slotProps={{
        RequestBody: {
          RequestLeftPanel: {
            open: detailPanelOpen,
            toggle: false,
            onOpen: onOpenLeftPanel,
            onClose: onCloseLeftPanel,
          },
          RequestRightPanel: {
            open: editDrawerOpen,
            toggle: true,
            onOpen: onOpenRightPanel,
            onClose: onCloseRightPanel,
          },
        },
      }}
      slots={{
        RequestLeftPanel: (
          <>
            <RequestSubDetailsPanel />
            <RequestSectionList
              sections={visibleSections}
              onSectionClick={handleOnSectionClick}
            />
          </>
        ),
        RequestRightPanel: (
          <Stack>
            <RequestDetailsPanel />
            <Divider />
            <RequestEdit
              open={true}
              row={{ uuid: id }}
              width={'25vw'}
              maxWidth={'400px'}
              onClose={onOpenRightPanel}
            />
          </Stack>
        ),
        RequestHeaderActions: (
          <>
            {request?.status === RequestSchemaStatus.open &&
              request.type !== 'tax_delivery' && (
                <Button
                  variant="outlined"
                  onClick={() => setDisableEdit(!disableEdit)}
                >
                  {disableEdit ? 'Change Responses' : 'Save Responses'}
                </Button>
              )}
            {request?.status === RequestSchemaStatus.open && (
              <MarkCompleteButton requestId={id} />
            )}
            <RequestContextMenu />
          </>
        ),
      }}
    />
  );
}
