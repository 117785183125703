import {
  readyToSendMutationDocument,
  createWorkflowsFromWorkflowTemplateDocument,
  workflowTemplatesDocument,
  deleteWorkflowTemplateDocument,
  workflowRequestsQueryDocument,
  updateWorkflowMutationDocument,
  workflowsQueryDocument,
} from '@liscio/api';
import {
  QueryWorkflowRequestsArgs,
  WorkflowsQueryVariables,
  WorkflowTemplatesQueryVariables,
} from '@liscio/api/graphql';
import { useGraphQLClient } from '@liscio/common';

export function useCreateWorkflowFromWorkflowTemplateMutation() {
  return useGraphQLClient().useMutationGraphQL(
    createWorkflowsFromWorkflowTemplateDocument
  );
}

export function useReadyToSendWorkflowMutation() {
  return useGraphQLClient().useMutationGraphQL(readyToSendMutationDocument);
}

export function useWorkflowTemplates(
  variables: WorkflowTemplatesQueryVariables
) {
  return useGraphQLClient().useGraphQL(workflowTemplatesDocument, variables);
}

export function useDeleteWorkflowTemplateMutation() {
  return useGraphQLClient().useMutationGraphQL(deleteWorkflowTemplateDocument);
}

export function useWorkflowRequestsQuery(
  variables: QueryWorkflowRequestsArgs,
  options?: { enabled: boolean }
) {
  return useGraphQLClient().useGraphQLWithOptions(
    workflowRequestsQueryDocument,
    { retry: false, ...options },
    variables
  );
}

export function useWorkflowsQuery(variables?: WorkflowsQueryVariables) {
  return useGraphQLClient().useGraphQL(workflowsQueryDocument, variables || {});
}

export function useUpdateWorkflow() {
  const gqlClient = useGraphQLClient();
  return gqlClient.useMutationGraphQL(updateWorkflowMutationDocument);
}
