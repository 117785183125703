import { Box } from '@liscio/ui';

import { RequestsFilterChip } from '../RequestsFilterChip/RequestsFilterChip';
import { useRequestFilterStore } from 'modules/requests/hooks/useRequestFilterStore';

export const ProgressChip = () => {
  const {
    getFilters,
    setNotStarted,
    setPercentCompleteGTE,
    setPercentCompleteLTE,
  } = useRequestFilterStore();

  const percentCompleteLte = getFilters()['percent_complete[lte]'];
  const percentCompleteGte = getFilters()['percent_complete[gte]'];
  const notStarted = getFilters().not_started;

  const handleDelete = () => {
    setNotStarted(false);
    // set these to defaults to remove them from the query params
    setPercentCompleteGTE(0);
    setPercentCompleteLTE(100);
  };

  const getLabel = () => {
    const lte = percentCompleteLte || '100';
    const gte = percentCompleteGte || '0';
    return `${gte} - ${lte}`;
  };

  return (
    <Box component="span">
      {notStarted && (
        <RequestsFilterChip
          labelKey="Progress"
          labelValue={'Not Started'}
          handleDelete={handleDelete}
          typeKey="notStarted"
        />
      )}
      {!notStarted &&
        (percentCompleteGte || percentCompleteLte) &&
        (percentCompleteGte !== 0 || percentCompleteLte !== 100) && (
          <RequestsFilterChip
            labelKey="Progress"
            labelValue={getLabel()}
            handleDelete={handleDelete}
            typeKey="percentComplete"
          />
        )}
    </Box>
  );
};
