import toast from 'react-hot-toast';

import { IndexRequestSchemaDataItem } from '@liscio/api';

import { ArchiveRequestDialog } from 'modules/requests/dialogs/ArchiveRequestDialog/ArchiveRequestDialog';
import { useRequestFilterStore } from 'modules/requests/hooks/useRequestFilterStore';

type RowMenuArchiveRequestDialogProps = {
  open: boolean;
  row: IndexRequestSchemaDataItem;
  onClose: () => void;
};

export const RowMenuArchiveRequestDialogDialog = ({
  open,
  onClose,
  row,
}: RowMenuArchiveRequestDialogProps) => {
  const { getRefetchRequests } = useRequestFilterStore();

  const onSuccessfulRecall = () => {
    const refetchRequests = getRefetchRequests();
    if (refetchRequests) {
      refetchRequests();
    }
    toast.success('Request archived successfully.');
  };

  const onErroredRecall = (error: unknown) => {
    toast.error('Error archiving request.');
    console.error('Error archiving request:', error);
  };
  return (
    <ArchiveRequestDialog
      open={open}
      requestId={row.uuid}
      onClose={onClose}
      onSuccessfulRecall={onSuccessfulRecall}
      onErroredRecall={onErroredRecall}
    />
  );
};
