import React from 'react';

import { AppBar, useMobileDrawer } from '@liscio/common';
import { ScreenWrapper, BodyWrapper } from '@liscio/ui';

import { Messages, Invoices, Tasks, FirmSpeedDial } from './components';
import { useReloadFunction } from 'custom-hooks';
import { useProfileData } from 'fetch-utils/users/user-hooks';

export interface DashboardViewProps {}

export const DashboardView: React.FC<DashboardViewProps> = () => {
  const { setIsDrawerOpen } = useMobileDrawer();
  const { hasInvoiceMenuElement, isFirmUser } = useProfileData();

  const reloadFunction = useReloadFunction([
    ['messages'],
    ['getUnpaidInvoicesAndUnauthorizedParents'],
    ['tasks'],
  ]);

  return (
    <ScreenWrapper>
      <AppBar
        onNavigationClick={() => {
          setIsDrawerOpen((currentState: boolean) => !currentState);
        }}
        title="Dashboard"
        hideSecondaryActionButton
      />
      <BodyWrapper reloadFunction={reloadFunction}>
        <Messages />
        {hasInvoiceMenuElement && !isFirmUser && <Invoices />}
        <Tasks />
        {isFirmUser && <FirmSpeedDial />}
      </BodyWrapper>
    </ScreenWrapper>
  );
};

export default DashboardView;
