import { Box, Dialog } from '@liscio/ui';

import PSPDFKitViewer from 'components/PSPDFKit/PSPDFKitViewer';
import { defaultToolbarItems } from 'components/PSPDFKit/toolbarItems';

type RequestPdfDialogProps = {
  open: boolean;
  url: string;
  title: string;
  onClose: () => void;
};

/**
 * Dialog to display a PDF viewer.
 */
export const RequestPdfDialog = ({
  open,
  url,
  title,
  onClose,
}: RequestPdfDialogProps) => {
  return (
    <Dialog
      open={open}
      fullScreen
      onClose={onClose}
      title={title}
      iconClose
      PaperProps={{
        sx: {
          width: '98vw',
          maxHeight: '98vh',
        },
      }}
    >
      <Box height="100%" width="100%" position="relative">
        <PSPDFKitViewer toolbarItems={defaultToolbarItems} url={url} />
      </Box>
    </Dialog>
  );
};
