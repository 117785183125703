import useMediaQuery from '@mui/material/useMediaQuery';

import { useTheme } from '@liscio/ui';

export const useIsDesktopView = () => {
  const theme = useTheme();
  const isLargeView = useMediaQuery(theme.breakpoints.up('md'));

  return isLargeView;
};
