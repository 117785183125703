import { lighten } from '@mui/material';

import {
  Box,
  Button,
  Icon,
  Stack,
  Tooltip,
  Typography,
  styled,
} from '@liscio/ui';

type BulkSendToolbarProps = {
  rowCount: number;
  setDrawerOpen: (value: boolean) => void;
  setBulkSendDialogOpen: (value: boolean) => void;
  validateSelectedRowData: () => boolean;
};

/**
 * Styled box for the toolbar
 */
const StyledBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  padding: '2px 12px',
  bottom: '80px',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1000,
  boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.1)',
  backgroundColor: theme.palette.common.black,
  borderRadius: '5px',
}));

/**
 * Styled action button for the toolbar
 */
const StyledActionButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.disabled,
  '&.Mui-disabled': {
    color: theme.palette.text.disabled,
  },
  '&:not(.Mui-disabled)': {
    color: lighten(theme.palette.info.light, 0.7),
  },
  zIndex: 1000,
}));

/**
 * Toolbar for bulk send mode
 */
export function BulkSendToolbar({
  rowCount,
  setBulkSendDialogOpen,
  setDrawerOpen,
  validateSelectedRowData,
}: BulkSendToolbarProps) {
  return (
    <StyledBox>
      <Stack direction="row" spacing={2}>
        <StyledActionButton
          variant="text"
          startIcon={<Icon icon="penOutline" />}
          disabled={rowCount === 0}
          size="large"
          onClick={() => setDrawerOpen(true)}
        >
          <Typography variant="body1">Edit Selected</Typography>
        </StyledActionButton>
        <Tooltip
          placement="top"
          arrow
          title={
            validateSelectedRowData()
              ? 'In order to send, all selected items must be drafts and must have an assignee and a due date.'
              : ''
          }
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: 'common.black',
                lineHeight: 1.2,
                width: '265px',
                '& .MuiTooltip-arrow': {
                  color: 'common.black',
                },
              },
            },
          }}
        >
          <Box>
            <StyledActionButton
              variant="text"
              startIcon={<Icon icon="send" />}
              disabled={rowCount === 0 || validateSelectedRowData()}
              onClick={() => setBulkSendDialogOpen(true)}
              size="large"
            >
              <Typography variant="body1">Send Selected</Typography>
            </StyledActionButton>
          </Box>
        </Tooltip>
      </Stack>
    </StyledBox>
  );
}
