import React from 'react';

import { Helmet } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';

import { REQUESTS_PAGE_TITLES, REQUESTS_PATHS } from './route-constants';
import { RequestsSearchView } from './views/RequestsSearchView';
import RequestView from './views/RequestView';

export const RequestModuleRoutes = () => (
  <>
    <Helmet title={REQUESTS_PAGE_TITLES.list} />
    <Routes>
      <Route index element={<RequestsSearchView />} />
      <Route path={REQUESTS_PATHS.list} element={<RequestsSearchView />} />
      <Route path=":view/:id/*" element={<RequestView />}>
        <Route path="sections/:sectionId" element={<RequestView />}>
          <Route path="questions/:questionId" element={<RequestView />} />
        </Route>
      </Route>
      <Route path="/:id/*" element={<RequestView />}>
        <Route path="sections/:sectionId" element={<RequestView />}>
          <Route path="questions/:questionId" element={<RequestView />} />
        </Route>
      </Route>
    </Routes>
  </>
);

export default RequestModuleRoutes;
