import React from 'react';

import { RequestSchemaStatus } from '@liscio/api';
import {
  RequestSectionList,
  RequestDetailsPanel,
  RequestSubDetailsPanel,
  useRequestStore,
} from '@liscio/common';
import { Button, Divider, Stack } from '@liscio/ui';

import { RequestDispatch } from '../components/RequestEdit/RequestDispatch';
import { useRequestDesktopView } from '../hooks/useRequestDesktopView';
import RequestContextMenu from 'modules/requests/components/RequestContextMenu/RequestContextMenu';

const DefaultRequestViewDesktopV5 = React.lazy(() =>
  import('@liscio/common').then((module) => ({
    default: module.DefaultRequestViewDesktopV5,
  }))
);

type RequestDesktopDispatchViewProps = {
  id: string;
};

export function RequestDesktopDispatchView({
  id,
}: RequestDesktopDispatchViewProps) {
  const { visibleSections, setDisableEdit, disableEdit, request } =
    useRequestStore();

  const {
    rightPanelOpen,
    leftPanelOpen,
    onOpenLeftPanel,
    onCloseLeftPanel,
    onOpenRightPanel,
    onCloseRightPanel,
    handleOnSectionClick,
  } = useRequestDesktopView({
    id,
    rightPanelOpen: true,
    leftPanelOpen: false,
  });

  return (
    <DefaultRequestViewDesktopV5
      slotProps={{
        RequestBody: {
          RequestLeftPanel: {
            open: leftPanelOpen,
            toggle: true,
            onOpen: onOpenLeftPanel,
            onClose: onCloseLeftPanel,
          },
          RequestRightPanel: {
            open: rightPanelOpen,
            toggle: false,
            onOpen: onOpenRightPanel,
            onClose: onCloseRightPanel,
          },
        },
      }}
      slots={{
        RequestLeftPanel: (
          <>
            <RequestSubDetailsPanel />
            <RequestSectionList
              sections={visibleSections}
              onSectionClick={handleOnSectionClick}
            />
          </>
        ),
        RequestRightPanel: (
          <Stack>
            <RequestDetailsPanel titleEditable />
            <Divider />
            <RequestDispatch
              open={leftPanelOpen}
              requestId={id}
              width={'25vw'}
              maxWidth={'400px'}
            />
          </Stack>
        ),
        RequestHeaderActions: (
          <>
            {request?.status === RequestSchemaStatus.open && (
              <Button
                variant="outlined"
                onClick={() => setDisableEdit(!disableEdit)}
              >
                {disableEdit ? 'Edit Request' : 'Save Responses'}
              </Button>
            )}
            <RequestContextMenu />
          </>
        ),
      }}
    />
  );
}
