import { NavMenuItem, useOpenHelpCenter } from '@liscio/common';
import { Icon, List, useViewportSize } from '@liscio/ui';

import { AddNewNavMenuItem } from './AddNewNavMenuItem';
import { BulkActionsNavMenuItem } from './BulkActionsNavMenuItem';
import { NotificationsNavMenuItem } from './NotificationsNavMenuItem';
import { useProfileData } from 'fetch-utils/users/user-hooks';
import { routes } from 'routes';

export const PrimaryNavigationMenu = () => {
  const { isMobile } = useViewportSize();
  const { hasInvoiceMenuElement, hasSMSElement } = useProfileData();
  const { openHelpCenter } = useOpenHelpCenter();

  return (
    <List>
      {!isMobile && <AddNewNavMenuItem />}
      <NavMenuItem
        data-testid="sidebar__home"
        icon={<Icon icon="home" />}
        label="Home"
        to={routes.dashboard.link()}
      />
      <NavMenuItem
        data-testid="sidebar__inbox"
        icon={<Icon icon="inbox" />}
        label="Inbox"
        to={routes.messagesInbox.link()}
      />
      <NavMenuItem
        data-testid="sidebar__tasks"
        icon={<Icon icon="tasks" />}
        label="Tasks"
        to={routes.tasksList.link()}
      />
      {!isMobile && (
        <NavMenuItem
          data-testid="sidebar__requests"
          icon={<Icon icon="requests" />}
          label="Requests"
          to={routes.requestsListProtected.link()}
        ></NavMenuItem>
      )}
      {!isMobile && (
        <NavMenuItem
          data-testid="sidebar__tax_automation"
          icon={<Icon icon="taxAutomation" />}
          label="Smart Tax"
          to={`/smart-tax`}
        ></NavMenuItem>
      )}
      {!isMobile && (
        <NavMenuItem
          data-testid="sidebar__notifications"
          icon={<Icon icon="emails" />}
          label="Emails"
          to={routes.messagesInbox.link()}
        />
      )}
      {hasSMSElement && (
        <NavMenuItem
          data-testid="sidebar__sms"
          icon={<Icon icon="sms" />}
          label="SMS"
          to={routes.smsList.link()}
        />
      )}
      <NavMenuItem
        data-testid="sidebar__accounts"
        icon={<Icon icon="accounts" />}
        label="Accounts"
        to={routes.accountsList.link()}
      />
      <NavMenuItem
        data-testid="sidebar__contacts"
        icon={<Icon icon="contacts" />}
        label="Contacts"
        to={routes.contactsList.link()}
      />
      <NavMenuItem
        data-testid="sidebar__files"
        icon={<Icon icon="files" />}
        label="Files"
        to={routes.filesList.link()}
      />
      {isMobile && (
        <NavMenuItem
          data-testid="sidebar__upload_file"
          icon={<Icon icon="uploadFile" />}
          label="Upload File"
          to={routes.filesNew.link()}
        />
      )}
      {!isMobile && (
        <>
          <BulkActionsNavMenuItem />
          <NavMenuItem
            data-testid="sidebar__edocsfiles"
            icon={<Icon icon="edoc" />}
            label="eDocs"
            to={routes.eDocsList.link()}
          />
        </>
      )}
      {hasInvoiceMenuElement && (
        <NavMenuItem
          data-testid="sidebar__billing"
          icon={<Icon icon="billing" />}
          label="Billing"
          to={routes.invoicesList.link()}
        />
      )}
      {!isMobile && <NotificationsNavMenuItem />}
      <NavMenuItem
        data-testid="sidebar__need_help"
        icon={<Icon icon="help" />}
        onClick={openHelpCenter}
        label="Need Help?"
      />
    </List>
  );
};
