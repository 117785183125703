import { Tooltip } from '@mui/material';

import { useTheme } from '@liscio/ui';

import { StyledIconButton } from './StyledComponents';
import { useRequestFilterStore } from 'modules/requests/hooks/useRequestFilterStore';

/**
 * A button that toggles the bulk send mode on and off
 */
export function BulkSendToggler() {
  const theme = useTheme();
  const { setBulkSendModeOn, bulkSendModeOn } = useRequestFilterStore();

  const handleButtonClick = () => {
    setBulkSendModeOn(!bulkSendModeOn);
  };

  return (
    <Tooltip
      title="Select multiple requests"
      placement="right"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'common.black',
          },
        },
      }}
    >
      <StyledIconButton
        icon="tasks"
        theme={theme}
        bulkSendModeOn={bulkSendModeOn}
        onClick={handleButtonClick}
        aria-label="Send Toggler"
      />
    </Tooltip>
  );
}
