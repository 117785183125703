import { create } from 'zustand';

import {
  EmployeeSchemaDataItem,
  GetApiV5FirmsRequestsParams,
  GetApiV5FirmsRequestsStatus,
  GetApiV5FirmsRequestsType,
  Uuid,
} from '@liscio/api';
import { compressJSON, decompressJSON } from '@liscio/common';
import { GridSortModel } from '@liscio/ui';

export type FilterState = {
  filters: GetApiV5FirmsRequestsParams;
  previousFilters: GetApiV5FirmsRequestsParams;
  showOnlyOverdue: boolean;
  ownersList: EmployeeSchemaDataItem[];
  ownersListMap: Record<Uuid, string>;
  currenUserEmployeeUuid: Uuid;
  bulkSendModeOn: boolean;
  refetchRequests: (() => void) | null;
};

export type FilterActions = {
  setQuery: (query: string) => void;
  setOwnerIds: (ownerIds: Uuid[]) => void;
  setType: (filterType: GetApiV5FirmsRequestsType[]) => void;
  setStatus: (filterStatus: GetApiV5FirmsRequestsStatus[]) => void;
  setNotStarted: (notStarted: boolean) => void;
  setPercentCompleteLTE: (percentCompleteLTE: number) => void;
  setPercentCompleteGTE: (percentCompleteGTE: number) => void;
  setDueDateLTE: (dueDateLTE: string) => void;
  setDueDateGTE: (dueDateGTE: string) => void;
  setUpdatedAtLTE: (updatedAtLTE: string) => void;
  setUpdatedAtGTE: (updatedAtGTE: string) => void;
  setNext: (nextCursor: string) => void;
  setSort: (sort: GridSortModel) => void;
  setShowOnlyOverdue: (showOnlyOverdue: boolean) => void;
  setFilters: (filters: GetApiV5FirmsRequestsParams) => void;
  resetFilters: () => void;
  getFilters: () => GetApiV5FirmsRequestsParams;
  getFiltersForQuery: () => Partial<GetApiV5FirmsRequestsParams>;
  getQueryString: () => string;
  loadFilterFromQueryString: (queryString: string) => void;
  clearFilters: () => void;
  setOwnersList: (ownersList: EmployeeSchemaDataItem[]) => void;
  setCurrentUserEmployeeUuid: (cpaUuid: Uuid) => void;
  setBulkSendModeOn: (bulkSendModeOn: boolean) => void;
  setRefetchRequests: (refetch: () => void) => void;
  getRefetchRequests: () => (() => void) | null;
};

export const initialFilterState = {
  filters: {
    query: '',
    type: '' as GetApiV5FirmsRequestsType,
    status: '' as GetApiV5FirmsRequestsStatus,
    owner_id: '',
    'due_date[lte]': '',
    'due_date[gte]': '',
    'percent_complete[lte]': 100,
    'percent_complete[gte]': 0,
    'updated_at[lte]': '',
    'updated_at[gte]': '',
    not_started: false,
    next: undefined,
    per_page: 25,
    sort: '',
  },
  showOnlyOverdue: false,
  ownersList: [],
  ownersListMap: {},
  currenUserEmployeeUuid: '',
  bulkSendModeOn: false,
};
/**
 * Used to manage the filters of the Request elastic search page.
 */
export const useRequestFilterStore = create<FilterState & FilterActions>(
  (set, get) => ({
    filters: {
      ...initialFilterState.filters,
    },
    previousFilters: {}, // used for managing filters when bulk send is active
    ownersList: initialFilterState.ownersList,
    // map of owner uuids to their full name {'1234': 'John Doe'}
    ownersListMap: initialFilterState.ownersListMap,
    currenUserEmployeeUuid: initialFilterState.currenUserEmployeeUuid,
    // keep this out of the filters so it doesn't get sent to the API (it's not a filter parameter)
    showOnlyOverdue: initialFilterState.showOnlyOverdue,
    // keep track of the bulk send mode
    bulkSendModeOn: initialFilterState.bulkSendModeOn,
    // keep a reference to the refetch function so we can call it from the store
    refetchRequests: null,
    // helpers for setting the various filters
    setQuery: (query) =>
      set((state) => ({
        filters: { ...state.filters, query, sort: '', next: undefined },
      })),

    setOwnerIds: (ownerIds) => {
      function trimEmptyValues(csvString: string) {
        return csvString
          .split(',')
          .map((value) => value.trim())
          .filter((value) => value !== '')
          .join(',');
      }

      const ownerIdsCSV = trimEmptyValues(ownerIds.toString());

      set((state) => ({
        filters: {
          ...state.filters,
          owner_id: ownerIdsCSV,
          sort:
            state.filters.owner_id === ownerIdsCSV ? state.filters.sort : '',
          next:
            state.filters.owner_id === ownerIdsCSV
              ? state.filters.next
              : undefined,
        },
      }));
    },

    // types are actually a CSV string when they are sent to the API
    // ?type=standard,tax_delivery,tax_organizer
    setType: (filterType) => {
      const typeToCSV = filterType.toString();

      set((state) => ({
        filters: {
          ...state.filters,
          type: typeToCSV as GetApiV5FirmsRequestsType,
          sort: state.filters.type === typeToCSV ? state.filters.sort : '',
          next:
            state.filters.type === typeToCSV ? state.filters.next : undefined,
        },
      }));
    },

    // status is actually a CSV string when they are sent to the API
    // ?type="open,pending,draft"
    setStatus: (filterStatus) => {
      const statusToCSV = filterStatus.toString();
      set((state) => ({
        filters: {
          ...state.filters,
          status: statusToCSV as GetApiV5FirmsRequestsStatus,
          sort: state.filters.status === statusToCSV ? state.filters.sort : '',
          next:
            state.filters.status === statusToCSV
              ? state.filters.next
              : undefined,
        },
      }));
    },

    setNotStarted: (notStarted) =>
      set((state) => ({
        filters: {
          ...state.filters,
          not_started: notStarted,
          sort: '',
          next: undefined,
        },
      })),

    setPercentCompleteLTE: (percentCompleteLTE) =>
      set((state) => ({
        filters: {
          ...state.filters,
          'percent_complete[lte]': percentCompleteLTE,
          sort:
            state.filters['percent_complete[lte]'] === percentCompleteLTE
              ? state.filters.sort
              : '',
          next:
            state.filters['percent_complete[lte]'] === percentCompleteLTE
              ? state.filters.next
              : undefined,
        },
      })),

    setPercentCompleteGTE: (percentCompleteGTE) =>
      set((state) => ({
        filters: {
          ...state.filters,
          'percent_complete[gte]': percentCompleteGTE,
          sort:
            state.filters['percent_complete[gte]'] === percentCompleteGTE
              ? state.filters.sort
              : '',
          next:
            state.filters['percent_complete[gte]'] === percentCompleteGTE
              ? state.filters.next
              : undefined,
        },
      })),

    setDueDateLTE: (dueDateLTE) =>
      set((state) => ({
        filters: {
          ...state.filters,
          'due_date[lte]': dueDateLTE,
          sort:
            state.filters['due_date[lte]'] === dueDateLTE
              ? state.filters.sort
              : '',
          next:
            state.filters['due_date[lte]'] === dueDateLTE
              ? state.filters.next
              : undefined,
        },
      })),

    setDueDateGTE: (dueDateGTE) =>
      set((state) => ({
        filters: {
          ...state.filters,
          'due_date[gte]': dueDateGTE,
          sort:
            state.filters['due_date[gte]'] === dueDateGTE
              ? state.filters.sort
              : '',
          next:
            state.filters['due_date[gte]'] === dueDateGTE
              ? state.filters.next
              : undefined,
        },
      })),

    setUpdatedAtLTE: (updatedAtLTE) =>
      set((state) => ({
        filters: {
          ...state.filters,
          'updated_at[lte]': updatedAtLTE,
          sort:
            state.filters['updated_at[lte]'] === updatedAtLTE
              ? state.filters.sort
              : '',
          next:
            state.filters['updated_at[lte]'] === updatedAtLTE
              ? state.filters.next
              : undefined,
        },
      })),

    setUpdatedAtGTE: (updatedAtGTE) =>
      set((state) => ({
        filters: {
          ...state.filters,
          'updated_at[gte]': updatedAtGTE,
          sort:
            state.filters['updated_at[gte]'] === updatedAtGTE
              ? state.filters.sort
              : '',
          next:
            state.filters['updated_at[gte]'] === updatedAtGTE
              ? state.filters.next
              : undefined,
        },
      })),

    setNext: (next) =>
      set((state) => ({
        filters: {
          ...state.filters,
          next: next,
        },
      })),

    setSort: (sortModel) => {
      const formatSortModel = sortModel.map((sort) => {
        if (sort.sort === 'asc') {
          return sort.field;
        }
        return `-${sort.field}`;
      });

      const getSort = formatSortModel.length > 0 ? formatSortModel[0] : '';

      set((state) => ({
        filters: { ...state.filters, sort: getSort, next: undefined },
      }));
    },
    // bulk set the filters - next/sort can be overwritten
    setFilters: (filters) =>
      set({ filters: { next: undefined, sort: '', ...filters } }),

    // reset all the filters
    resetFilters: () =>
      set((state) => ({
        // preserve the users search input
        filters: { ...initialFilterState.filters, query: state.filters.query },
        showOnlyOverdue: initialFilterState.showOnlyOverdue,
      })),

    // return the filters object
    getFilters: () => ({ ...get().filters }),

    // remove any empty values from the filters to prepare for
    // being used as URL query params
    getFiltersForQuery: () => {
      const filters = get().filters;
      const filtersForQuery: Record<string, unknown> = { ...filters };

      // if the not_started filter is true, we need to remove the percent_complete filters
      // or the API returns nothing :(
      if (filtersForQuery.not_started === true) {
        filtersForQuery['percent_complete[lte]'] = null;
        filtersForQuery['percent_complete[gte]'] = null;
      } else {
        // remove the not_started filter if it's false as it does nothing
        delete filtersForQuery.not_started;
      }
      // if percent_complete is 0 or 100, remove it
      if (filtersForQuery['percent_complete[lte]'] === 100) {
        delete filtersForQuery['percent_complete[lte]'];
      }
      if (filtersForQuery['percent_complete[gte]'] === 0) {
        delete filtersForQuery['percent_complete[gte]'];
      }

      Object.keys(filtersForQuery).forEach((key) => {
        if (
          filtersForQuery[key] === '' ||
          filtersForQuery[key] === undefined ||
          filtersForQuery[key] === null
        ) {
          delete filtersForQuery[key];
        }
      });

      return filtersForQuery;
    },

    // helpers for compressing/decompressing the filters
    getQueryString: () => {
      const filter = get();
      const compressed = compressJSON(JSON.stringify(filter));
      return `filters=${compressed}`;
    },

    loadFilterFromQueryString: (queryString) => {
      const compressed = new URLSearchParams(queryString).get('filters');
      if (compressed) {
        const filter = JSON.parse(decompressJSON(compressed));
        set({ ...filter });
      }
    },

    clearFilters: () => set({}),

    setShowOnlyOverdue: (showOnlyOverdue) => set({ showOnlyOverdue }),

    setOwnersList: (ownersList) => {
      const ownersListMap = ownersList.reduce((acc, owner) => {
        if (owner.uuid) {
          acc[owner.uuid] = `${owner?.first_name} ${owner?.last_name}`;
        }
        return acc;
      }, {} as Record<Uuid, string>);

      set({ ownersList, ownersListMap });
    },

    setCurrentUserEmployeeUuid: (employeeUuid) =>
      set({ currenUserEmployeeUuid: employeeUuid }),

    setBulkSendModeOn: (bulkSendModeOn) => {
      set({ bulkSendModeOn });

      if (bulkSendModeOn) {
        set((state) => ({
          previousFilters: { ...state.filters },
          filters: { ...initialFilterState.filters, status: 'draft' },
        }));
      } else {
        set((state) => ({
          filters: { ...state.previousFilters },
          previousFilters: {},
        }));
      }
    },

    setRefetchRequests: (refetch: () => void) =>
      set({ refetchRequests: refetch }),

    getRefetchRequests: () => get().refetchRequests,
  })
);
