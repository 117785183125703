/* eslint-disable import/no-unresolved */
import React from 'react';

import { Route, Routes, Navigate, Outlet } from 'react-router-dom';

import { useDeepLinkNavigation, useLiveUpdates } from '@liscio/common';

import { ModuleRoutes as LoginModuleRoutes } from './modules/authenticate/ModuleRoutes';
import { ModuleRoutes as DashboardModuleRoutes } from './modules/dashboard/ModuleRoutes';
import { ModuleRoutes as NotificationsModulesRoutes } from './modules/notifications/ModuleRoutes';
import {
  AuthRoute,
  CpaRoute,
  AcceptedTermsAndConditionsRoute,
  AuthenticatedRedirectRoute,
  RequireFirmEmployeeRoute,
  LazyWrapper,
} from 'components';
import { ConditionalThemeProvider } from 'components/ConditionalThemeProvider/ConditionalThemeProvider';
import { DesktopSiteRedirect } from 'components/DesktopSiteRedirect';
import { config } from 'config';
import { DEFAULT_VIEW_URL } from 'constants/settings';
import { AppLayout, AuthenticateLayout } from 'layouts';
import { ACCOUNTS_DOMAIN_URL } from 'modules/accounts/route-constants';
import { ADMIN_DOMAIN_URL } from 'modules/admin/route-constants';
import { AUTHENTICATE_DOMAIN_URL } from 'modules/authenticate/route-constants';
import { CONTACTS_DOMAIN_URL } from 'modules/contacts/route-constants';
import { DASHBOARD_DOMAIN_URL } from 'modules/dashboard/route-constants';
import { EnforcedMfaConfiguration } from 'modules/enforcedMfaConfiguration/views';
import { ERRORS_DOMAIN, ERRORS_PATHS } from 'modules/errors/route-constants';
import { InternetConnectionProblem } from 'modules/errors/views';
import { FILES_DOMAIN_URL } from 'modules/files/route-constants';
import { INVOICES_DOMAIN_URL } from 'modules/invoices/route-constants';
import { MESSAGES_DOMAIN_URL } from 'modules/messages/route-constants';
import { NOTIFICATIONS_DOMAIN_URL } from 'modules/notifications/route-constants';
import { PROFILE_DOMAIN_URL } from 'modules/profile/route-constants';
import RequestModuleRoutes from 'modules/requests/RequestModuleRoutes';
import { REQUESTS_INDEX_URL } from 'modules/requests/route-constants';
import { SMART_TAX_INDEX_URL } from 'modules/smart-tax/route-constants';
import { SMS_DOMAIN_URL } from 'modules/sms/route-constants';
import { TASKS_DOMAIN_URL } from 'modules/tasks/route-constants';
import { useCaptureCompanyName } from 'stores/cpa';

const MessagesModuleRoutes = React.lazy(
  () => import('./modules/messages/ModuleRoutes')
);
const AccountsModuleRoutes = React.lazy(
  () => import('./modules/accounts/ModuleRoutes')
);
const TasksModuleRoutes = React.lazy(
  () => import('./modules/tasks/ModuleRoutes')
);
const InvoicesModuleRoutes = React.lazy(
  () => import('./modules/invoices/ModuleRoutes')
);
const FilesModuleRoutes = React.lazy(
  () => import('./modules/files/ModuleRoutes')
);
const ProfileModulesRoutes = React.lazy(
  () => import('./modules/profile/ModuleRoutes')
);
const ContactsModuleRoutes = React.lazy(
  () => import('./modules/contacts/ModuleRoutes')
);
const SMSModuleRoutes = React.lazy(() => import('./modules/sms/ModuleRoutes'));

const AdminModulesRoutes = React.lazy(
  () => import('./modules/admin/ModuleRoutes')
);

const RequestsModuleRoutes = React.lazy(
  () => import('./modules/requests/ModuleRoutes')
);

const SmartTaxModuleRoutes = React.lazy(
  () => import('./modules/smart-tax/ModuleRoutes')
);

const AppRoutes = () => {
  // Register native app behaviors
  useDeepLinkNavigation();
  useLiveUpdates(config.dev.disableNativeLiveUpdates);

  // Capture company_name param from legacy redirect
  useCaptureCompanyName();

  return (
    <Routes>
      <Route path="/authenticate" element={<AuthenticateLayout />}>
        <Route path={AUTHENTICATE_DOMAIN_URL} element={<LoginModuleRoutes />} />
      </Route>
      <Route
        path="/enforced-mfa-configuration"
        element={<EnforcedMfaConfiguration />}
      />
      {/* Require Auth */}
      <Route
        element={<AuthRoute unauthenticatedRedirect="/authenticate/login" />}
      >
        {/* Require accepted terms & conditions */}
        <Route element={<AcceptedTermsAndConditionsRoute />}>
          {/* Require Selected Cpa */}
          <Route element={<CpaRoute />}>
            <Route element={<RequireFirmEmployeeRoute />}>
              <Route element={<AuthenticatedRedirectRoute />}>
                <Route element={<DesktopSiteRedirect />}>
                  <Route element={<AppLayout />}>
                    {/* As desktop pages are ported to firm from liscio-refactor their routes should be moved inside the Conditional Theme Provider block */}
                    <Route element={<ConditionalThemeProvider />}>
                      {/* NOTE - Admin routes are broken/are skipped during linting/builds due to breaking API change - https://github.com/Liscio-Inc/liscio-frontend/pull/1172 */}
                      <Route path="/admin">
                        <Route
                          path={ADMIN_DOMAIN_URL}
                          element={
                            <LazyWrapper>
                              <AdminModulesRoutes />
                            </LazyWrapper>
                          }
                        />
                      </Route>
                      <Route path="/notifications">
                        <Route
                          path={NOTIFICATIONS_DOMAIN_URL}
                          element={<NotificationsModulesRoutes />}
                        />
                      </Route>
                      <Route path="/requests">
                        <Route
                          index
                          path={REQUESTS_INDEX_URL}
                          element={<RequestsModuleRoutes />}
                        />
                      </Route>
                    </Route>
                    <Route path="/dashboard">
                      <Route
                        path={DASHBOARD_DOMAIN_URL}
                        element={<DashboardModuleRoutes />}
                      />
                    </Route>
                    <Route path="/messages">
                      <Route
                        path={MESSAGES_DOMAIN_URL}
                        element={
                          <LazyWrapper>
                            <MessagesModuleRoutes />
                          </LazyWrapper>
                        }
                      />
                    </Route>
                    <Route path="/accounts">
                      <Route
                        path={ACCOUNTS_DOMAIN_URL}
                        element={
                          <LazyWrapper>
                            <AccountsModuleRoutes />
                          </LazyWrapper>
                        }
                      />
                    </Route>
                    <Route path="/contacts">
                      <Route
                        path={CONTACTS_DOMAIN_URL}
                        element={
                          <LazyWrapper>
                            <ContactsModuleRoutes />
                          </LazyWrapper>
                        }
                      />
                    </Route>
                    <Route path="/files">
                      <Route
                        path={FILES_DOMAIN_URL}
                        element={
                          <LazyWrapper>
                            <FilesModuleRoutes />
                          </LazyWrapper>
                        }
                      />
                    </Route>
                    <Route path="/tasks">
                      <Route
                        path={TASKS_DOMAIN_URL}
                        element={
                          <LazyWrapper>
                            <TasksModuleRoutes />
                          </LazyWrapper>
                        }
                      />
                    </Route>
                    <Route path="/invoices">
                      <Route
                        path={INVOICES_DOMAIN_URL}
                        element={
                          <LazyWrapper>
                            <InvoicesModuleRoutes />
                          </LazyWrapper>
                        }
                      />
                    </Route>
                    <Route path="/profile">
                      <Route
                        path={PROFILE_DOMAIN_URL}
                        element={
                          <LazyWrapper>
                            <ProfileModulesRoutes />
                          </LazyWrapper>
                        }
                      />
                    </Route>
                    <Route path="/sms">
                      <Route
                        path={SMS_DOMAIN_URL}
                        element={
                          <LazyWrapper>
                            <SMSModuleRoutes />
                          </LazyWrapper>
                        }
                      />
                    </Route>
                    <Route
                      path={SMART_TAX_INDEX_URL}
                      element={
                        <LazyWrapper>
                          <SmartTaxModuleRoutes />
                        </LazyWrapper>
                      }
                    />
                    <Route
                      path={REQUESTS_INDEX_URL}
                      element={<RequestModuleRoutes />}
                    />
                    {/* this catch all route is necessary to trigger the Desktop Redirect for routes that aren't explicitely defined */}
                    <Route path="*" element={<Outlet />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      <Route
        path={`/${ERRORS_DOMAIN}${ERRORS_PATHS.internetConnection}`}
        element={<InternetConnectionProblem />}
      />
      <Route path="/" element={<Navigate to={DEFAULT_VIEW_URL} />} />
    </Routes>
  );
};

export default AppRoutes;
