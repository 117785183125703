/* eslint-disable react-hooks/exhaustive-deps */
import { MouseEvent, useEffect, useState } from 'react';

import { format, parse } from 'date-fns';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import {
  IndexRequestSchemaDataItem,
  PutApiV5FirmsRequestsIdBody,
  RequestSchemaStatus,
  useGetApiV5FirmsRequestsId,
  usePostApiV5DocumentsSignedUrls,
  usePutApiV5FirmsRequestsId,
} from '@liscio/api';
import { RecipientSelector } from '@liscio/common';
import { Stack, InputLabel, Link, TextField, Icon, styled } from '@liscio/ui';

import { StyledButton } from './StyledComponents';
import { SelectOwnerDropdown } from '../../../../components/SelectOwnerDropdown/SelectOwnerDropdown';
import { RHFDateInput } from 'components';
import { RequestPdfDialog } from 'modules/requests/dialogs/RequestPdfDialog/RequestPdfDialog';
import { useRequestFilterStore } from 'modules/requests/hooks/useRequestFilterStore';

type EditDrawerProps = {
  open: boolean;
  row: Pick<IndexRequestSchemaDataItem, 'uuid'>;
  onCancel?: (event: MouseEvent) => void;
  onClose: () => void;
  width?: string;
  maxWidth?: string;
};

type FormDataType = {
  title?: string;
  owner_id?: string;
  dueDate?: string;
  assignedAccountId?: string;
  assignedContactIds?: string[];
};

const DATE_FORMAT = 'MMM d, yyyy';

const InputLabelStyled = styled(InputLabel)(() => ({
  fontSize: 0.75,
  fontWeight: 600,
}));

/**
 * This component is used to edit a request in a drawer.
 */
export function RequestEdit({
  open,
  row,
  onCancel,
  onClose,
  width,
  maxWidth,
}: EditDrawerProps) {
  const {
    data: requestData,
    isLoading,
    refetch,
  } = useGetApiV5FirmsRequestsId(row.uuid || '', { query: { enabled: open } });
  const { mutateAsync: getSignedUrl } = usePostApiV5DocumentsSignedUrls();
  const { mutateAsync: updateRequest } = usePutApiV5FirmsRequestsId();
  const { getRefetchRequests } = useRequestFilterStore();
  const [signedUrl, setSignedUrl] = useState('');
  const [showPdf, setShowPdf] = useState(false);

  const { handleSubmit, register, control, reset, formState } =
    useForm<PutApiV5FirmsRequestsIdBody>({
      defaultValues: {
        title: '',
        owner_id: '',
        due_date: '',
        assigned_account_id: '',
        assigned_contact_ids: [],
      },
    });

  useEffect(() => {
    if (requestData && open) {
      let dueDate = '';
      try {
        dueDate = requestData.due_date
          ? new Date(requestData.due_date).toISOString().split('T')[0]
          : '';
      } catch (error) {
        console.error('Error parsing date:', error);
      }

      reset({
        title: requestData.title || '',
        owner_id: requestData.owner?.uuid || '',
        due_date: dueDate,
      });
    }
  }, [requestData, reset, open]);

  const onSubmit = (formData: FormDataType) => {
    let isoDate = '';
    try {
      isoDate = formData.dueDate
        ? format(
            parse(formData.dueDate, 'yyyy-MM-dd', new Date()),
            "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
          )
        : '';
    } catch (error) {
      console.error('Error parsing date:', error);
    }

    if (requestData?.status !== RequestSchemaStatus.draft) {
      delete formData.assignedAccountId;
      delete formData.assignedContactIds;
    }

    // Handle form submission
    updateRequest(
      {
        id: row.uuid || '',
        data: {
          title: formData.title,
          ...(formData.owner_id && { owner_id: formData.owner_id }),
          ...(isoDate !== '' && { due_date: isoDate }),
          ...(formData.assignedAccountId && {
            assigned_account_id: formData.assignedAccountId,
          }),
          ...(formData.assignedContactIds && {
            assigned_contact_ids: formData.assignedContactIds,
          }),
        },
      },
      {
        onSuccess: () => {
          const refetchRequests = getRefetchRequests();

          // Refetch the requests list
          if (refetchRequests) {
            refetchRequests();
          }

          refetch(); // Refetch the request data

          toast.success('Request updated successfully.');
          onClose();
        },
        onError: (error) => {
          toast.error('Error updating request.');
          console.error('Error updating request:', error);
          onClose();
        },
      }
    );
  };

  const handleSourceFileClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!requestData) {
      return;
    }
    if (
      requestData.source_documents &&
      requestData.source_documents.length > 0
    ) {
      getSignedUrl(
        {
          data: {
            disposition: 'inline',
            retrieval_token:
              requestData.source_documents[0].retrieval_token || '',
          },
        },
        {
          onSuccess: (data) => {
            setSignedUrl(data.signed_url || '');
          },
          onError: (error) => {
            toast.error('Error getting signed URL.');
            console.error('Error getting signed URL:', error);
          },
        }
      );
    }
    setShowPdf(true);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ height: '100%', width, maxWidth }}
      >
        <Stack height="100%">
          <Stack flexGrow={0.97} gap={2} padding="1rem">
            {requestData?.source_documents &&
              requestData.source_documents.length > 0 && (
                <Stack gap={1}>
                  <InputLabelStyled>SOURCE FILE</InputLabelStyled>
                  <Link
                    href={'#'}
                    onClick={handleSourceFileClick}
                    display="flex"
                    alignItems="start"
                  >
                    <Icon icon="filePdf" sx={{ marginRight: '5px' }} />
                    {requestData?.source_documents?.[0]?.filename}
                  </Link>
                </Stack>
              )}
            <Stack gap={1}>
              <InputLabelStyled>REQUEST TITLE</InputLabelStyled>
              <TextField variant="outlined" fullWidth {...register('title')} />
            </Stack>
            <Stack gap={1}>
              <InputLabelStyled>OWNER</InputLabelStyled>
              <SelectOwnerDropdown control={control} name="owner_id" />
            </Stack>
            <Stack gap={1}>
              <InputLabelStyled>DUE DATE</InputLabelStyled>
              <RHFDateInput
                name="dueDate"
                control={control}
                format={DATE_FORMAT}
                valueFormat="yyyy-MM-dd"
                minDate={new Date()}
              />
            </Stack>
            <Stack>
              <RecipientSelector
                name="recipients"
                control={control}
                account={requestData?.assigned_account}
                assignedContacts={requestData?.assigned_contacts}
                disabled={requestData?.status !== RequestSchemaStatus.draft}
              />
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-evenly"
            padding={2}
            sx={(theme) => ({
              borderTop: `1px solid ${theme.palette.divider}`,
            })}
          >
            {onCancel && (
              <StyledButton
                variant="outlined"
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  onCancel(e);
                }}
              >
                Cancel
              </StyledButton>
            )}
            <StyledButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={!formState.isDirty}
              fullWidth={onCancel ? false : true}
            >
              Save
            </StyledButton>
          </Stack>
        </Stack>
      </form>
      {requestData &&
        requestData.source_documents &&
        requestData.source_documents.length > 0 && (
          <RequestPdfDialog
            open={showPdf}
            onClose={() => setShowPdf(false)}
            url={signedUrl}
            title={requestData.source_documents[0].filename || ''}
          />
        )}
    </>
  );
}
