// https://codesandbox.io/p/sandbox/material-ui-submenu-sz73h7?file=%2Fsrc%2FMoreMenuItem.tsx%3A38%2C1-39%2C1
import React, {
  KeyboardEventHandler,
  useState,
  useCallback,
  useId,
  FC,
} from 'react';

import { MenuItemProps, MenuProps } from '@mui/material';

import { ListItemIcon, MenuItem, Menu, ListItemText, Icon } from '@liscio/ui';

type MoreMenuItemProps = {
  button?: true;
  label: string;
  icon?: React.ReactNode;
  MenuProps?: Omit<MenuProps, 'open' | 'onClose' | 'anchorEl' | 'onKeyDown'>;
} & Omit<MenuItemProps, 'onKeyDown' | 'onMouseEnter' | 'onMouseLeave'>;

const MoreMenuItem: FC<MoreMenuItemProps> = ({
  label,
  children,
  id,
  MenuProps,
  icon,
  ...other
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const menuItemId = useId();
  const normMenuItemId = id ?? menuItemId;

  const open = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  }, []);
  const close = useCallback(() => {
    setIsOpen(false);
    setAnchorEl(null);
  }, []);

  const handleItemKeyDown: KeyboardEventHandler<HTMLLIElement> = (ev) => {
    if (
      (ev.key !== 'ArrowRight' && ev.key !== 'Enter') ||
      ev.ctrlKey ||
      ev.shiftKey ||
      ev.altKey ||
      ev.metaKey
    )
      return;
    ev.preventDefault();
    ev.stopPropagation();
    setIsOpen(true);
  };

  const handleMenuKeyDown: KeyboardEventHandler<HTMLDivElement> = (ev) => {
    ev.stopPropagation();
    if (
      (ev.key !== 'ArrowLeft' && ev.key !== 'Escape') ||
      ev.ctrlKey ||
      ev.shiftKey ||
      ev.altKey ||
      ev.metaKey
    )
      return;
    ev.preventDefault();
    setIsOpen(false);
  };

  return (
    <MenuItem
      {...other}
      onKeyDown={handleItemKeyDown}
      sx={[
        { justifyContent: 'space-between' },
        isOpen && { backgroundColor: 'action.hover' },
      ]}
      onMouseEnter={open}
      onMouseLeave={close}
      id={normMenuItemId}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText>{label}</ListItemText>
      <Icon icon="angleRight" sx={{ fontSize: '1.2rem' }} />
      <Menu
        TransitionProps={{ onExited: () => anchorEl?.focus() }}
        disableRestoreFocus
        onKeyDown={handleMenuKeyDown}
        sx={{
          pointerEvents: 'none',
          '& .MuiList-root': {
            pointerEvents: 'auto',
          },
        }}
        MenuListProps={{
          ...MenuProps?.MenuListProps,
          'aria-labelledby': normMenuItemId,
        }}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={close}
        anchorOrigin={
          MenuProps?.anchorOrigin ?? { vertical: 'center', horizontal: 'right' }
        }
        transformOrigin={
          MenuProps?.transformOrigin ?? {
            vertical: 'center',
            horizontal: 'left',
          }
        }
      >
        {children}
      </Menu>
    </MenuItem>
  );
};

export default MoreMenuItem;
