import { CustomToast } from '@liscio/common';

export const CopyUrlErrorToast = () => {
  return (
    <CustomToast
      icon="copy"
      severity="error"
      message="Failed to copy URL to clipboard. Please try again or copy it manually."
    />
  );
};
