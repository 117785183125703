import { useState } from 'react';

import { Box, Button, Icon, List, Popover, Stack } from '@liscio/ui';

import { DueDateFiltersPanel } from './DueDateFiltersPanel/DueDateFiltersPanel';
import { LastActivityFiltersPanel } from './LastActivityFiltersPanel/LastActivityFiltersPanel';
import { OwnersFiltersPanel } from './OwnersFiltersPanel/OwnersFiltersPanel';
import { ProgressFiltersPanel } from './ProgressFiltersPanel/ProgressFiltersPanel';
import { RequestTableFiltersMenuItem } from './RequestsTableFiltersMenuItem';
import { RequestsTableFiltersMenuPanel } from './RequestsTableFiltersMenuPanel';
import { StatusFiltersPanel } from './StatusFiltersPanel/StatusFiltersPanel';
import { TypeFiltersPanel } from './TypeFiltersPanel/TypeFiltersPanel';
import { useRequestFilterStore } from 'modules/requests/hooks/useRequestFilterStore';

export const RequestsTableFilters = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [menuItemIndex, setMenuItemIndex] = useState(0);
  const { resetFilters } = useRequestFilterStore();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    resetFilters();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'requests-table-filters-popover' : undefined;

  return (
    <Box>
      <Stack direction="row" spacing={2}>
        <Button
          variant="outlined"
          color="info"
          startIcon={<Icon icon="circlePlus" color="info" />}
          sx={{ borderRadius: '50px' }}
          onClick={handleOpen}
        >
          Filter
        </Button>
        <Button
          color="info"
          sx={{ borderRadius: '50px' }}
          onClick={handleReset}
        >
          Reset
        </Button>
      </Stack>
      <Popover
        id={id}
        role="dialog"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              overflow: 'hidden',
            },
          },
        }}
      >
        <Stack
          width={560}
          height={460}
          direction="column"
          justifyContent="space-between"
        >
          <Stack direction="row" height={403}>
            <Box
              width={230}
              sx={(theme) => ({
                borderRight: `1px solid ${theme.palette.divider}`,
              })}
            >
              <List>
                <RequestTableFiltersMenuItem
                  title="Status"
                  index={0}
                  currentIndex={menuItemIndex}
                  onClick={setMenuItemIndex}
                />
                <RequestTableFiltersMenuItem
                  title="Type"
                  index={1}
                  currentIndex={menuItemIndex}
                  onClick={setMenuItemIndex}
                />
                <RequestTableFiltersMenuItem
                  title="Owner"
                  index={2}
                  currentIndex={menuItemIndex}
                  onClick={setMenuItemIndex}
                />
                <RequestTableFiltersMenuItem
                  title="Due Date"
                  index={3}
                  currentIndex={menuItemIndex}
                  onClick={setMenuItemIndex}
                />
                <RequestTableFiltersMenuItem
                  title="Progress"
                  index={4}
                  currentIndex={menuItemIndex}
                  onClick={setMenuItemIndex}
                />
                <RequestTableFiltersMenuItem
                  title="Last Activity"
                  index={5}
                  currentIndex={menuItemIndex}
                  onClick={setMenuItemIndex}
                />
              </List>
            </Box>
            <Box width={330}>
              <RequestsTableFiltersMenuPanel
                currentIndex={menuItemIndex}
                index={0}
              >
                <StatusFiltersPanel />
              </RequestsTableFiltersMenuPanel>
              <RequestsTableFiltersMenuPanel
                currentIndex={menuItemIndex}
                index={1}
              >
                <TypeFiltersPanel />
              </RequestsTableFiltersMenuPanel>
              <RequestsTableFiltersMenuPanel
                currentIndex={menuItemIndex}
                index={2}
              >
                <OwnersFiltersPanel />
              </RequestsTableFiltersMenuPanel>
              <RequestsTableFiltersMenuPanel
                currentIndex={menuItemIndex}
                index={3}
              >
                <DueDateFiltersPanel />
              </RequestsTableFiltersMenuPanel>
              <RequestsTableFiltersMenuPanel
                currentIndex={menuItemIndex}
                index={4}
              >
                <ProgressFiltersPanel />
              </RequestsTableFiltersMenuPanel>
              <RequestsTableFiltersMenuPanel
                currentIndex={menuItemIndex}
                index={5}
              >
                <LastActivityFiltersPanel />
              </RequestsTableFiltersMenuPanel>
            </Box>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            padding={1}
            sx={(theme) => ({
              borderTop: `1px solid ${theme.palette.divider}`,
            })}
          >
            <Button variant="contained" color="info" onClick={handleClose}>
              Done
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </Box>
  );
};
