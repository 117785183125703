import { GetApiV5FirmsRequestsType } from '@liscio/api';
import { Box } from '@liscio/ui';

import { RequestsFilterChip } from '../RequestsFilterChip/RequestsFilterChip';
import { useRequestFilterStore } from 'modules/requests/hooks/useRequestFilterStore';

export const TypeChip = () => {
  const { getFilters, setType } = useRequestFilterStore();

  const type = getFilters().type || ([] as GetApiV5FirmsRequestsType[]);

  const { standard, tax_delivery, tax_organizer } = GetApiV5FirmsRequestsType;

  const handleDelete: <T = GetApiV5FirmsRequestsType>(typeKey: T) => void = (
    typeKey
  ) => {
    // convert filter store csv value to array then filter out the typeKey
    const typesWithKeyRemoved = (type as GetApiV5FirmsRequestsType)
      ?.split(',')
      .filter((t) => t !== typeKey);

    setType(typesWithKeyRemoved as GetApiV5FirmsRequestsType[]);
  };

  const getLabel = (typeKey: GetApiV5FirmsRequestsType) => {
    switch (typeKey) {
      case standard:
        return 'Other';
      case tax_delivery:
        return 'Tax Return';
      case tax_organizer:
        return 'Tax Organizer';
      default:
        return 'Unknown';
    }
  };

  return (
    <Box component="span">
      {type?.includes(tax_organizer) && (
        <RequestsFilterChip
          labelKey="Type"
          labelValue={getLabel(tax_organizer)}
          handleDelete={handleDelete}
          typeKey={tax_organizer}
        />
      )}
      {type?.includes(tax_delivery) && (
        <RequestsFilterChip
          labelKey="Type"
          labelValue={getLabel(tax_delivery)}
          handleDelete={handleDelete}
          typeKey={tax_delivery}
        />
      )}
      {type?.includes(standard) && (
        <RequestsFilterChip
          labelKey="Type"
          labelValue={getLabel(standard)}
          handleDelete={handleDelete}
          typeKey={standard}
        />
      )}
    </Box>
  );
};
