// https://pspdfkit.com/api/web/
// https://github.com/PSPDFKit/awesome-pspdfkit
// make sure you have the PSPDFKit license key(VITE_PSPDFKIT_LICENSE_KEY) in your .env file

import { useEffect, useRef } from 'react';

import PSPDFKit, { Configuration, Instance, ToolbarItem } from 'pspdfkit';

import { config } from 'config';

type PDFViewerProps = {
  url: string;
  readOnly?: boolean; // This prop will default to `true`.
  toolbarItems?: ToolbarItem[]; // This is a list of items to override the default toolbar items`.
  configurationsOptions?: Configuration;
};

export default function PSPDFKitViewer({
  url,
  readOnly = true,
  toolbarItems,
  configurationsOptions,
}: PDFViewerProps) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current; // This `useRef` instance will render the PDF.

    let instance: Instance;

    const baseUrl =
      config.environment === 'development'
        ? `${window.location.origin}/public/`
        : `${window.location.origin}/`;

    (async function () {
      PSPDFKit.unload(container); // Ensure that there's only one PSPDFKit instance.

      // https://pspdfkit.com/api/web/PSPDFKit.Configuration.html
      instance = await PSPDFKit.load({
        licenseKey: config.psPdfKit.licenseKey,
        container: container as unknown as HTMLElement,
        document: url,
        baseUrl,
        ...configurationsOptions,
      });

      // https://pspdfkit.com/api/web/PSPDFKit.ViewState.html
      instance.setViewState((viewState) => viewState.set('readOnly', readOnly));

      // Set/remove/add custom toolbar items.
      // https://pspdfkit.com/api/web/PSPDFKit.Instance.html#setToolbarItems
      if (toolbarItems) {
        instance.setToolbarItems(toolbarItems);
      }
    })();
    // cleanup the PSPDFKit instance
    return () => {
      PSPDFKit?.unload(container);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurationsOptions, readOnly, toolbarItems, url]);

  // This div element will render the document to the DOM.
  return (
    <div ref={containerRef} style={{ width: '100%', height: 'inherit' }} />
  );
}
