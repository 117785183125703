import { useCallback, useEffect, useRef } from 'react';

import { useParams, useSearchParams, useNavigate } from 'react-router-dom';

import { OAuthProviderType } from '@liscio/api';
import { mapV3ProviderType, useOAuthV5Process } from '@liscio/common';
import { GlobalLoader, ErrorDialog } from '@liscio/ui';

import { config } from 'config';
import { CpaLoginV5Selector } from 'modules/authenticate/components';
import { useSetLoggedInUserSession } from 'modules/authenticate/hooks';

export const OAuthRedirectV5View = () => {
  const setLoggedUserSession = useSetLoggedInUserSession();
  const navigate = useNavigate();
  const { provider } = useParams<{ provider: OAuthProviderType }>();
  const [query] = useSearchParams();
  const code = query.get('code') || '';
  const nonce = query.get('state') || '';

  const oAuthConfig = config?.oauth[provider!];

  const isInitialized = useRef(false);

  const { handleOAuthResponse, error, signInCpas, selectCpa, isLoading } =
    useOAuthV5Process({
      provider: provider!,
      oAuthConfig,
      onProcessFinished: async (loggedInUser) => {
        await setLoggedUserSession(loggedInUser);
      },
      loginType: mapV3ProviderType(provider!),
    });

  const goBackToLogin = useCallback(
    () => navigate('/authenticate/login', { replace: true }),
    [navigate]
  );

  useEffect(() => {
    if (!isInitialized.current && provider) {
      handleOAuthResponse({ code, nonce, provider });
    }
    return () => {
      isInitialized.current = true;
    };
  }, [code, handleOAuthResponse, nonce, provider]);

  // Render OAuth error
  if (error) {
    return (
      <ErrorDialog
        title="Sign In Error"
        error={error}
        onClose={goBackToLogin}
      />
    );
  }

  // Render unexpected error
  if (!provider) {
    return <ErrorDialog title="Sign In Error" onClose={goBackToLogin} />;
  }

  // Handle Oauth code flow cpa selection
  if (signInCpas?.length && !isLoading) {
    return (
      <CpaLoginV5Selector
        onCancel={goBackToLogin}
        cpas={signInCpas}
        onCpaSelected={selectCpa}
      />
    );
  }

  return <GlobalLoader open />;
};

export default OAuthRedirectV5View;
