import { Uuid } from '@liscio/api';
import { Box } from '@liscio/ui';

import { RequestsFilterChip } from '../RequestsFilterChip/RequestsFilterChip';
import { useRequestFilterStore } from 'modules/requests/hooks/useRequestFilterStore';

export const OwnerChip = () => {
  const { getFilters, setOwnerIds, ownersListMap } = useRequestFilterStore();

  const matchCommasWithWhitespace = /\s*,\s*/;
  const ownerIds = getFilters()
    .owner_id?.split(matchCommasWithWhitespace)
    .map(String)
    .filter(Boolean);

  const handleDelete = (uuid?: Uuid) => {
    if (ownerIds) {
      setOwnerIds(ownerIds.filter((id) => id !== uuid));
    }
  };

  return (
    <Box component="span">
      {ownerIds &&
        ownerIds.map((uuid) => (
          <RequestsFilterChip
            key={uuid}
            labelKey="Owner"
            labelValue={ownersListMap[uuid]}
            handleDelete={() => handleDelete(uuid)}
            typeKey="notStarted"
          />
        ))}
    </Box>
  );
};
