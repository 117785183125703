import { useCallback, useEffect, useState } from 'react';

import { Capacitor } from '@capacitor/core';

import {
  OAuthConfig,
  OAuthProviderType,
  isLoginWithCodeMultiCpaResponse,
  isLoginWithCodeSessionResponse,
} from '@liscio/api';
import { useAuthStore, setAuthSession, useCpaStore } from '@liscio/common';

import { createNonce, verifyNonce } from './oauth-utils';
import { config } from 'config';
import {
  useLoginWithIauthTokenAndCpaId,
  useLoginWithOAuthCodeMutation,
} from 'fetch-utils/users/auth-hooks';
import { saveApplicationCpa } from 'stores/cpa/cpa-utils';
import { CpaStateItem } from 'stores/cpa/cpa.types';

/**
 * useOauth Helper hook
 */
export const useOAuth = (provider: OAuthProviderType) => {
  const oauthConfig: OAuthConfig = config.oauth[provider];

  const [error, setError] = useState<any>();
  const [multiCpa, setMultiCpa] = useState<{
    cpas: CpaStateItem[];
    token: string;
  }>();
  const { setAppCpa, setSessionCpa } = useCpaStore();
  const {
    mutateAsync: loginWithCode,
    error: loginError,
    isLoading: isLoggingIn,
  } = useLoginWithOAuthCodeMutation();
  const {
    mutateAsync: loginWithToken,
    error: multiCpaLoginError,
    isLoading: isMultiCpaLoggingIn,
  } = useLoginWithIauthTokenAndCpaId();
  const setAuth = useAuthStore(({ setAuth }) => setAuth);

  // Initiate "redirect" to OAuth provider
  const initiateOAuth = useCallback(async () => {
    const nonce = await createNonce();
    const oauthUrl = oauthConfig.url;
    const oauthParams = new URLSearchParams({
      client_id: oauthConfig.clientId,
      response_type: 'code',
      redirect_uri: oauthConfig.redirectUri,
      scope: oauthConfig.scope,
      state: nonce,
    });

    const url = `${oauthUrl}?${oauthParams.toString()}`;

    // Use the same window for web, new window for native
    if (Capacitor.isNativePlatform()) {
      window.open(url);
    } else {
      window.location.replace(url);
    }
  }, [oauthConfig]);

  // Handle OAuth redirect
  const handleOAuthResponse = useCallback(
    async ({ code, nonce }: { code: string; nonce: string }) => {
      try {
        await verifyNonce(nonce);
      } catch {
        setError({ message: 'Invalid state nonce in OAuth response.' });
        return;
      }

      const result = await loginWithCode({
        user: { login_type: provider, code },
        redirect_uri: oauthConfig.redirectUri,
      });

      // Handle multiple cpa response
      if (isLoginWithCodeMultiCpaResponse(result)) {
        setMultiCpa({
          cpas: result.cpa_data || [],
          token: result.token || '',
        });
      } else if (isLoginWithCodeSessionResponse(result)) {
        // Handle success response
        setAuth(result);
        setAuthSession(result);
      } else {
        setError({ message: 'Unable to Sign In with Provider.' });
      }
    },
    [setError, loginWithCode, setAuth, setMultiCpa, provider, oauthConfig]
  );

  const selectCpa = useCallback(
    async (cpa: CpaStateItem) => {
      const session = await loginWithToken({
        user: {
          cpa_id: cpa.id!,
          login_type: provider,
          token: multiCpa?.token || '',
        },
      });
      setAuth(session);
      setAuthSession(session);
      setAppCpa(cpa);
      await saveApplicationCpa(cpa);
      setSessionCpa(cpa);
    },
    [
      multiCpa?.token,
      loginWithToken,
      setAuth,
      setAppCpa,
      setSessionCpa,
      provider,
    ]
  );

  // Validate provider
  useEffect(() => {
    if (!oauthConfig) {
      setError({ message: `Invalid OAuth provider "${provider}"` });
    }
  }, [provider, setError, oauthConfig]);

  return {
    initiateOAuth,
    handleOAuthResponse,
    isLoggingIn: isLoggingIn || isMultiCpaLoggingIn,
    error: error || loginError || multiCpaLoginError,
    cpas: multiCpa?.cpas,
    selectCpa,
  };
};
