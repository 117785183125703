import { StyledStatusCellChip } from './StatusCell.styled';

type StatusCellProps = {
  status: string;
};

export const StatusCell = ({ status }: StatusCellProps) => {
  const upperCaseStatus = status.toUpperCase();
  return <StyledStatusCellChip label={upperCaseStatus} size="small" />;
};
