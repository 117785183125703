import { CustomToast } from '@liscio/common';

export const ExportZipSuccessToast = () => {
  return (
    <CustomToast
      icon="fileZip"
      severity="success"
      message="ZIP export successfully initiated. Depending on the size of this request and any uploaded files, it may take some time to process. Once complete it will become available for download from the Liscio Vault."
    />
  );
};
