import { MouseEvent } from 'react';

import { Alert, Link } from '@liscio/ui';

import { generateRedirectUrl } from 'components/DesktopSiteRedirect/desktop-site-redirect-utils';
import { config } from 'config';
import { useRequestExperience } from 'modules/requests/hooks/use-experiences-hook';

/**
 * This component is named the same as the component in the
 * liscio-refactor code base so they are easier to identify.
 *
 * This component will send a message to the liscio-refactor
 * experience to revert changes and use the old Request page.
 */
export function NewRequestPageAlert() {
  const { updateRequestExperience } = useRequestExperience();

  const redirect = generateRedirectUrl(
    `/requests`,
    `${window.location.search}`,
    window.location.pathname
  );

  const handleLinkClick = async (event: MouseEvent) => {
    event.preventDefault();

    try {
      const result = await updateRequestExperience('disabled');
      if (result) {
        // Navigate to the URL after updating the experience
        window.location.href =
          redirect ?? `${config.legacyRedirect.url}/requests`;
      }
    } catch (error) {
      console.error('Error updating request experience:', error);
    }
  };

  return (
    <Alert
      severity="info"
      sx={{ paddingY: '0px', fontWeight: 'normal', alignItems: 'center' }}
    >
      You're now using our new and improved Requests page. If you prefer the old
      design, you can{' '}
      <Link
        fontWeight="bold"
        onClick={handleLinkClick}
        href={redirect ?? `${config.legacyRedirect.url}/requests`}
      >
        switch back.
      </Link>
    </Alert>
  );
}
