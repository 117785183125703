import { GetApiV5FirmsRequestsStatus } from '@liscio/api';
import { Box } from '@liscio/ui';

import { RequestsFilterChip } from '../RequestsFilterChip/RequestsFilterChip';
import { useRequestFilterStore } from 'modules/requests/hooks/useRequestFilterStore';

export const StatusChip = () => {
  const { getFilters, setStatus } = useRequestFilterStore();

  const status = getFilters().status || ([] as GetApiV5FirmsRequestsStatus[]);

  const { open, pending, archived, draft } = GetApiV5FirmsRequestsStatus;

  const handleDelete: <T = GetApiV5FirmsRequestsStatus>(typeKey: T) => void = (
    typeKey
  ) => {
    // convert filter store csv value to array then filter out the typeKey
    const typesWithKeyRemoved = (status as GetApiV5FirmsRequestsStatus)
      ?.split(',')
      .filter((t) => t !== typeKey);

    setStatus(typesWithKeyRemoved as GetApiV5FirmsRequestsStatus[]);
  };

  const getLabel = (typeKey: GetApiV5FirmsRequestsStatus) => {
    switch (typeKey) {
      case open:
        return 'Open';
      case pending:
        return 'Pending';
      case archived:
        return 'Archived';
      case draft:
        return 'Draft';
      default:
        return 'Unknown';
    }
  };

  return (
    <Box component="span">
      {status?.includes(open) && (
        <RequestsFilterChip
          labelKey="Status"
          labelValue={getLabel(open)}
          handleDelete={handleDelete}
          typeKey={open}
        />
      )}
      {status?.includes(pending) && (
        <RequestsFilterChip
          labelKey="Status"
          labelValue={getLabel(pending)}
          handleDelete={handleDelete}
          typeKey={pending}
        />
      )}
      {status?.includes(archived) && (
        <RequestsFilterChip
          labelKey="Status"
          labelValue={getLabel(archived)}
          handleDelete={handleDelete}
          typeKey={archived}
        />
      )}
      {status?.includes(draft) && (
        <RequestsFilterChip
          labelKey="Status"
          labelValue={getLabel(draft)}
          handleDelete={handleDelete}
          typeKey={draft}
        />
      )}
    </Box>
  );
};
