import { CustomToast } from '@liscio/common';

export const ExportZipErrorToast = () => {
  return (
    <CustomToast
      icon="fileZip"
      severity="error"
      message="Error starting zip export."
    />
  );
};
