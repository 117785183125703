import React from 'react';

import { ClosePaymentDialogReasons } from '@liscio/api';
import { GlobalLoader, ErrorDialog } from '@liscio/ui';

export interface PlaidPaymentProcessingStateProps {
  closeFunction(closeReason: ClosePaymentDialogReasons): void;
  processPlaidPaymentError?: Error | null;
}

export const PlaidPaymentProcessingState: React.FC<
  PlaidPaymentProcessingStateProps
> = ({ closeFunction, processPlaidPaymentError }) => {
  return (
    <>
      <GlobalLoader open={!processPlaidPaymentError} />
      <ErrorDialog
        error={processPlaidPaymentError}
        onClose={() => closeFunction(ClosePaymentDialogReasons.VOID)}
      />
    </>
  );
};

export default PlaidPaymentProcessingState;
