import { IndexRequestSchemaDataItemAssignedContactsItem } from '@liscio/api';

type AssigneeCellProps = {
  assignees: IndexRequestSchemaDataItemAssignedContactsItem[];
};

export const AssigneeCell = ({ assignees }: AssigneeCellProps) => {
  const getFullName = (
    assignee: IndexRequestSchemaDataItemAssignedContactsItem
  ) => `${assignee?.first_name} ${assignee?.last_name}`;

  return <>{assignees?.map(getFullName).join(', ')}</>;
};
