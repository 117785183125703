import { format } from 'date-fns';

import { Typography, useTheme, Icon, Stack, Tooltip } from '@liscio/ui';

interface DueDateCellProps {
  date: string;
}

export const DueDateCell = ({ date }: DueDateCellProps) => {
  const theme = useTheme();

  const dueDate = new Date(date);
  const isOverdue = dueDate < new Date();

  const textColor = isOverdue
    ? theme.palette.common.error[700]
    : theme.palette.text.primary;

  const dateToRender = date ? format(dueDate, 'MMM, do, yyyy') : '';

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
      height="100%"
    >
      <Typography component="span" color={textColor}>
        {dateToRender}
      </Typography>
      {isOverdue && dateToRender && (
        <Tooltip title="Overdue" placement="top">
          <Icon
            icon="errorOutline"
            fontSize="small"
            sx={{ color: theme.palette.common.error[700] }}
          />
        </Tooltip>
      )}
    </Stack>
  );
};
