import toast from 'react-hot-toast';

import { MenuItem, ListItemIcon, Icon } from '@liscio/ui';
import { copyCurrentUrlToClipboard } from '@liscio/utils';

import { CopyUrlErrorToast } from './CopyUrlErrorToast';
import { CopyUrlSuccessToast } from './CopyUrlSuccessToast';

interface CopyLinkMenuItemProps {
  onClose: () => void;
}

export const CopyLinkMenuItem = ({ onClose }: CopyLinkMenuItemProps) => {
  const handleCopyLink = () => {
    copyCurrentUrlToClipboard(
      () => {
        toast.custom(CopyUrlSuccessToast, {
          position: 'top-right',
          duration: 2000,
          style: {
            padding: 0,
            margin: '0px 0px',
          },
        });
      },
      (error) => {
        toast.custom(CopyUrlErrorToast, {
          position: 'top-right',
          duration: 2000,
          style: {
            padding: 0,
            margin: '0px 0px',
          },
        });
        console.error('Error copying link to clipboard:', error);
      }
    );
    onClose();
  };

  return (
    <MenuItem onClick={handleCopyLink}>
      <ListItemIcon>
        <Icon icon="copy" fontSize="small" />
      </ListItemIcon>
      Copy Link
    </MenuItem>
  );
};
