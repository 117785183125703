import { Box, CircularProgress, useTheme, Typography } from '@liscio/ui';

type PercentCompleteCellProps = {
  value: number;
};

export const PercentCompleteCell = ({ value }: PercentCompleteCellProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        height: 64,
        margin: theme.spacing(2),
      }}
    >
      <CircularProgress
        variant="determinate"
        value={value}
        size={30}
        thickness={4}
        color="info"
        sx={{
          boxShadow: `inset 0px 0px 1px 3px ${theme.palette.common.neutrals[100]}`,
          borderRadius: '15px',
        }}
      />
      <Box
        sx={{
          top: -34,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: 'text.primary' }}
        >
          {value || 0}
        </Typography>
      </Box>
    </Box>
  );
};
