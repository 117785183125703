import { AuthenticatedUserCpaDataItem } from '@liscio/api';
import { AppBar, BlockedState } from '@liscio/common';
import {
  Button,
  Stack,
  Typography,
  ScreenWrapper,
  BodyWrapper,
  LightFooterWrapper,
} from '@liscio/ui';

import ProtectImg from 'modules/authenticate/assets/protect.svg?react';

export interface MfaSetupDesktopOnlyInfoProps {
  resetProcess: () => void;
  getSelectedCpa: () => AuthenticatedUserCpaDataItem | undefined;
}

export const MfaSetupDesktopOnlyInfo = ({
  resetProcess,
  getSelectedCpa,
}: MfaSetupDesktopOnlyInfoProps) => {
  const cpaName = getSelectedCpa()?.cpa_name || '';
  return (
    <ScreenWrapper>
      <AppBar title="Protect your account" DefaultStateElement={BlockedState} />
      <BodyWrapper
        justifyContent="center"
        alignItems="center"
        display="flex"
        flexDirection="column"
        gap={2}
        p={2}
        textAlign="center"
      >
        <Stack alignItems="center">
          <ProtectImg />
        </Stack>
        <Typography>
          {cpaName} requires multi-factor authentication for enhanced security.
        </Typography>
        <Typography>
          Sign in on your <strong>desktop device</strong> to set up your
          preferred authentication app.
        </Typography>
      </BodyWrapper>
      <LightFooterWrapper>
        <Button variant="contained" fullWidth onClick={resetProcess}>
          Got it!
        </Button>
      </LightFooterWrapper>
    </ScreenWrapper>
  );
};
