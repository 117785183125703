import { createConsumer, Consumer } from '@rails/actioncable';

import { config } from 'config';

class WebsocketService {
  public consumer: Consumer;

  constructor() {
    const hostname = (config.api.url || '').replace(/(^\w+:|^)\/\//, '');

    const websocketUrl = `wss://${hostname}/cable`;
    this.consumer = createConsumer(websocketUrl);
  }

  public authenticate(authToken: string) {
    try {
      if (!this.consumer?.subprotocols.includes(authToken)) {
        this.consumer.addSubProtocol(authToken);
      }
    } catch {
      // eslint-disable-next-line
      console.log('Unable to authenticate ws consumer');
    }
  }
}
export type WebsocketServiceInstance = InstanceType<typeof WebsocketService>;
export const websocket = new WebsocketService();
