import { useMemo } from 'react';

import { useGetApiV5FirmsEmployees } from '@liscio/api';

export const useEmployeeList = (enabled: boolean = false, perPage?: number) => {
  const PER_PAGE = perPage || 1000;

  const { data, isLoading, isError, fetchStatus, isFetching } =
    useGetApiV5FirmsEmployees(
      {
        sort: 'name',
        per_page: PER_PAGE,
      },
      {
        query: {
          enabled,
          staleTime: Infinity, // Prevent unnecessary refetches
          keepPreviousData: true, // Keep previous data while loading new data
        },
      }
    );

  const employeeList = useMemo(() => {
    return data?.data || [];
  }, [data?.data]);

  const totalEntries = useMemo(() => {
    return data?.meta?.total_entries || 0;
  }, [data?.meta?.total_entries]);

  const loading = (isLoading || isFetching) && fetchStatus !== 'idle';

  return {
    employeeList,
    employeeListLoading: loading,
    employeeListError: isError,
    totalEntries,
  };
};
