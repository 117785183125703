import React, { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { Box, EmptyListPlaceholder } from '@liscio/ui';

import Header from '../Header/Header';
import { Loading, TasksList } from 'components';
import { useInfinitiveTasks } from 'fetch-utils/tasks/tasks-hooks';
import { useProfileData } from 'fetch-utils/users/user-hooks';
import {
  DASHBOARD_DOMAIN,
  DASHBOARD_PATHS,
} from 'modules/dashboard/route-constants';
import { TASKS_DOMAIN, TASKS_PATHS } from 'modules/tasks/route-constants';
import { extractValues } from 'modules/tasks/views/ListView/helpers';

export interface TasksProps {}

const filters = {
  tab: 'open_tasks',
  taskTypes: '',
  account: '',
  sortOrder: 'updated_at',
  sortField: 'desc',
};

export const Tasks: React.FC<TasksProps> = () => {
  const navigate = useNavigate();
  const { isFirmUser } = useProfileData();
  const dashboardParameter = isFirmUser ? 'true' : '';

  const { data, isLoading } = useInfinitiveTasks(
    filters.tab,
    filters.taskTypes,
    filters.account,
    filters.sortOrder,
    filters.sortField,
    dashboardParameter
  );
  const { tasks, openNumberOfTasks } = extractValues(data?.pages, filters.tab);
  const shouldHighlightUnreadComments = useMemo(
    () => !['pending_review', 'archived'].includes(filters.tab),
    []
  );

  return (
    <>
      <Header
        title="Tasks"
        numberOfItems={openNumberOfTasks}
        onSeeAllClick={() => {
          navigate(`/${TASKS_DOMAIN}${TASKS_PATHS.list}`);
        }}
      />
      <Box position="relative" minHeight="100px">
        {isLoading && <Loading numberOfElements={1} />}
        {!isLoading && tasks.length > 0 && (
          <TasksList
            tasks={tasks.slice(0, 3)}
            navigateState={{
              goBackUrl: `/${DASHBOARD_DOMAIN}${DASHBOARD_PATHS.home}`,
            }}
            highlightUnreadComments={shouldHighlightUnreadComments}
          />
        )}
        {!isLoading && tasks.length === 0 && (
          <EmptyListPlaceholder label="Your task list is empty." />
        )}
      </Box>
    </>
  );
};

export default Tasks;
