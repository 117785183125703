import { useState } from 'react';

import toast from 'react-hot-toast';

import {
  IndexRequestSchemaDataItem,
  usePostApiV5FirmsRequestsIdReopen,
} from '@liscio/api';
import { Dialog, TextField } from '@liscio/ui';

import { useRequestFilterStore } from 'modules/requests/hooks/useRequestFilterStore';

type ReOpenRequestDialogProps = {
  open: boolean;
  row: IndexRequestSchemaDataItem;
  onClose: () => void;
};

/**
 * Dialog to re-open a request.
 */
export const ReOpenRequestDialog = ({
  open,
  row,
  onClose,
}: ReOpenRequestDialogProps) => {
  const [userMessage, setUserMessage] = useState('');
  const { getRefetchRequests } = useRequestFilterStore();
  const { mutateAsync: reopenRequest } = usePostApiV5FirmsRequestsIdReopen();

  const handleRecall = () => {
    const messageContent = `<div>
    ${userMessage}
    <br/>
    <br/>
    <a href="/requests/${row.uuid}">Click here to view the ${row.title} request</a>
    </div>`;

    reopenRequest(
      { id: row.uuid || '', data: { reopen_message: messageContent } },
      {
        onSuccess: () => {
          const refetchRequests = getRefetchRequests();
          if (refetchRequests) {
            refetchRequests();
          }
          toast.success('Request re-opened successfully.');
        },
        onError: (error) => {
          toast.error('Error re-opening request.');
          console.error('Error re-opening request:', error);
        },
      }
    );
    onClose();
  };

  const handleUserMessageInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUserMessage(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Please provide a reason for re-opening this request"
      iconClose
      onSubmit={handleRecall}
      submitLabel="Re-open"
      submitBtnProps={{ color: 'primary' }}
      cancelLabel="Cancel"
      onCancel={onClose}
      cancelBtnProps={{ color: 'primary' }}
      fullScreen
      PaperProps={{
        sx: {
          width: '32%',
          height: '50%',
        },
      }}
    >
      <p>
        All assignees on this request will receive the following as a message,
        so consider referencing the specific items which require their response
        or review.
      </p>
      <TextField
        multiline
        rows={4}
        fullWidth
        autoFocus
        onChange={handleUserMessageInputChange}
        InputProps={{
          sx: {
            ariaLabel: 'Re-Open Message',
            marginTop: '16px',
            backgroundColor: 'common.white',
            padding: '4px 0px 5px',
            '& .MuiInputBase-inputMultiline': { border: 'none' },
          },
        }}
      />
    </Dialog>
  );
};
