import { Capacitor } from '@capacitor/core';
import { matchRoutes } from 'react-router-dom';

import {
  exclusionPathMapRoutes,
  pathMap,
  pathMapRoutes,
} from './desktop-redirects';
import { config } from 'config';

/**
 * Get the configured Desktop site redirect uri for a given location
 */
export const getDesktopSiteRedirect = (addRoutes: Record<'path', string>[]) => {
  const redirectedFrom = new URLSearchParams(window.location.search).get(
    'redirectedFrom'
  );
  if (redirectedFrom !== null) {
    sessionStorage.setItem('redirectedFrom', redirectedFrom ?? '');
    // remove the redirectedFrom query param from the URL without reloading
    const url = new URL(window.location.href);
    url.searchParams.delete('redirectedFrom');
    window.history.replaceState({}, '', url.toString());
  }

  if (!config.legacyRedirect.url && !redirectedFrom) {
    return undefined;
  }

  const { pathname } = window.location;

  let generatedExclusionPaths: Record<'path', string>[] =
    exclusionPathMapRoutes;

  // Allows additionals routes from LaunchDarkly, etc calculated at render time
  if (addRoutes?.length) {
    generatedExclusionPaths = [...exclusionPathMapRoutes, ...addRoutes];
  }

  const [excludeMatch] = matchRoutes(generatedExclusionPaths, pathname) || [];

  if (!excludeMatch) {
    const [match] = matchRoutes(pathMapRoutes, pathname) || [];
    const routePath = match ? match.route.path : '';
    const pathConfig = pathMap[routePath];
    const newRedirectPath = pathConfig?.redirect;

    return generateRedirectUrl(newRedirectPath, undefined, pathname);
  }

  return undefined;
};

/**
 * Determine whether the current device should be redirected to the Desktop site
 */
export const getIsDesktopRedirectDevice = (userAgent: string) =>
  // Redirect on presence of iphone/ipad/android in useragent
  !['iphone', 'ipad', 'android'].some((device) =>
    userAgent.toLowerCase().includes(device)
  ) && !Capacitor.isNativePlatform();

/**
 * Generate a redirect URL for the Desktop site
 * @param newRedirectPath - The path in the app we are redirecting to
 * @param search - The search query string
 * @param originalPath - The path for this app
 * @returns The redirect URL
 * **/
export function generateRedirectUrl(
  newRedirectPath?: string,
  search?: string,
  originalPath?: string
) {
  if (newRedirectPath) {
    const params = new URLSearchParams(newRedirectPath);
    // Resolve redirect path params
    params.forEach((paramValue) => {
      newRedirectPath = newRedirectPath?.replaceAll(
        `:${paramValue}`,
        encodeURIComponent(paramValue)
      );
    });
  }

  // if we have a new redirect path, use it, otherwise use the current path
  // paths sometimes diverge between the two sites
  const host =
    config.environment !== 'production' &&
    sessionStorage.getItem('redirectedFrom')
      ? sessionStorage.getItem('redirectedFrom')
      : config.legacyRedirect.url;

  const path = newRedirectPath || originalPath;

  const windowSearch = new URLSearchParams(window.location.search);
  const searchParams = new URLSearchParams(search ?? '');
  const constructedSearch = new URLSearchParams({
    ...Object.fromEntries(windowSearch),
    ...Object.fromEntries(searchParams),
  });
  if (config.environment !== 'production') {
    constructedSearch.append(
      `redirectedFrom`,
      `${window.location.protocol}\\\\${window.location.host}`
    );
  }

  return new URL(`${path}?${constructedSearch}`, `${host}`).toString();
}
