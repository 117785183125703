import toast from 'react-hot-toast';

import { usePostApiV5FirmsRequestsIdClose } from '@liscio/api';
import { Dialog, Typography } from '@liscio/ui';

export type MarkCompleteRequestDialogProps = {
  open: boolean;
  requestId: string;
  onClose: () => void;
  onSuccessfulComplete?: () => void;
  onErroredComplete?: (error: unknown) => void;
};

/**
 * This component is used to complete a request.
 */
export const MarkCompleteRequestDialog = ({
  open,
  requestId,
  onClose,
  onSuccessfulComplete,
  onErroredComplete,
}: MarkCompleteRequestDialogProps) => {
  const { mutateAsync: closeRequestMutation, isLoading } =
    usePostApiV5FirmsRequestsIdClose({
      mutation: {
        onSuccess: () => {
          if (onSuccessfulComplete) {
            onSuccessfulComplete();
          }
        },
        onError: (error) => {
          if (onErroredComplete) {
            onErroredComplete(error);
          }
          toast.error('Error closing request');
          console.error('Error closing request:', error);
        },
        onSettled: () => {
          onClose();
        },
      },
    });

  const handleRecall = () => {
    closeRequestMutation({
      id: requestId || '',
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Close this request?"
      iconClose
      onSubmit={handleRecall}
      submitLabel="Mark Complete"
      submitBtnProps={{ color: 'primary', disabled: isLoading }}
      cancelLabel="Cancel"
      onCancel={onClose}
      cancelBtnProps={{ color: 'primary', disabled: isLoading }}
      aria-label="Mark as a complete dialog"
    >
      <Typography>Are you sure you want to submit this Request?</Typography>
      <Typography>
        Your client will not be able to make further edits after submission.
      </Typography>
    </Dialog>
  );
};
