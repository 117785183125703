import React from 'react';

import {
  Button,
  Typography,
  Icon,
  ScreenWrapper,
  BodyWrapper,
  FooterWrapper,
} from '@liscio/ui';

export interface InternetConnectionProblemProps {}

export const InternetConnectionProblem: React.FC<
  InternetConnectionProblemProps
> = () => {
  const reload = () => {
    window.location.replace('/dashboard/home');
  };

  return (
    <ScreenWrapper>
      <BodyWrapper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '30px',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          padding: '16px',
        }}
      >
        <Icon icon="wifiSlash" sx={{ width: '100px', height: '100px' }} />
        <Typography variant="h2" sx={{ fontSize: '32px' }}>
          Your internet connection is unstable.
        </Typography>
        <Typography>Check your connection and click Reload.</Typography>
      </BodyWrapper>
      <FooterWrapper>
        <Button variant="contained" onClick={reload} fullWidth>
          Reload
        </Button>
      </FooterWrapper>
    </ScreenWrapper>
  );
};

export default InternetConnectionProblem;
