import toast from 'react-hot-toast';

import {
  IndexRequestSchemaDataItem,
  useDeleteApiV5FirmsRequestsId,
} from '@liscio/api';
import { Dialog } from '@liscio/ui';

import { useRequestFilterStore } from 'modules/requests/hooks/useRequestFilterStore';

type DeleteRequestDialogProps = {
  open: boolean;
  row?: IndexRequestSchemaDataItem;
  requestId?: string;
  onClose: () => void;
  onSuccess?: () => void;
};

export const DeleteRequestDialog = ({
  open,
  row,
  requestId,
  onClose,
  onSuccess,
}: DeleteRequestDialogProps) => {
  const { getRefetchRequests } = useRequestFilterStore();
  const { mutateAsync: deleteRequestMutation } =
    useDeleteApiV5FirmsRequestsId();

  const handleDelete = () => {
    deleteRequestMutation(
      {
        id: row?.uuid || requestId || '',
      },
      {
        onSuccess: () => {
          const refetchRequests = getRefetchRequests();
          if (refetchRequests) {
            refetchRequests();
          }
          toast.success('Draft deleted successfully.');
          onSuccess?.();
        },
        onError: (error) => {
          toast.error('Error deleting Draft.');
          console.error('Error deleting request:', error);
        },
      }
    );
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Delete this request?"
      iconClose
      onSubmit={handleDelete}
      submitLabel="Yes, delete"
      submitBtnProps={{ color: 'error' }}
      cancelLabel="No, cancel"
      onCancel={onClose}
      cancelBtnProps={{ color: 'primary' }}
    >
      <p>
        Are you sure you want to <b>delete</b> this request?
      </p>
    </Dialog>
  );
};
