import { useEffect, useState } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  useGetApiV5FirmsRequestsId,
  useGetApiV5FirmsRequestsRequestIdSections,
} from '@liscio/api';
import {
  useTrackRequestQueryEvent,
  AppBar,
  AttachIconState,
  RequestStoreProvider,
} from '@liscio/common';
import { ErrorDialog, VerboseLoading } from '@liscio/ui';

import { RequestDesktopDispatchView } from './RequestDesktopDispatchView';
import { RequestDesktopFirmView } from './RequestDesktopRouterView';
import { parseWorkflowRequestError } from '../helpers';
import { generateRedirectUrl } from 'components/DesktopSiteRedirect/desktop-site-redirect-utils';
import { useIsDesktopView } from 'custom-hooks/useIsDesktopView';
import {
  REQUESTS_INDEX,
  REQUESTS_PATHS,
} from 'modules/requests/route-constants';

const PROGRESS_CAPTIONS = [
  'Building sections...',
  'Adding questions...',
  'Crunching numbers...',
  'Retrieving values...',
  'Wrapping up...',
];

export const RequestView = () => {
  const { id, view } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isDesktopView = useIsDesktopView();
  const [loadingStateVisible, setLoadingStateVisible] = useState(true);
  const { requestRefactorFirmShow, requestRefactorDispatchShow } = useFlags();

  const {
    data: workflowData,
    error: workflowError,
    isLoading: isWorkflowLoading,
    remove,
  } = useGetApiV5FirmsRequestsId(id || '', {
    query: { cacheTime: Infinity, staleTime: Infinity },
  });

  const { isLoading: isSectionsLoading, remove: removeSections } =
    useGetApiV5FirmsRequestsRequestIdSections(
      id || '',
      {},
      {
        query: { cacheTime: Infinity, staleTime: Infinity },
      }
    );

  /**
   * Clear the cache when leaving the page
   */
  useEffect(() => {
    return () => {
      remove();
      removeSections();
    };
  }, [remove, removeSections]);

  // Track request_opened event
  const { trackRequestQueryEvent } = useTrackRequestQueryEvent(id);
  useEffect(() => {
    if (workflowData) {
      // NOTE: We'll have limited access to properties here in the refactor
      trackRequestQueryEvent('request_opened');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowData]);

  const isDispatchView = view && view.toLowerCase() === 'dispatch';

  if (!requestRefactorFirmShow) {
    window.location.href = generateRedirectUrl(`/requests/${id}`);

    return null;
  }

  if (isDispatchView && !requestRefactorDispatchShow) {
    window.location.href = generateRedirectUrl(`/requests/dispatch/${id}`);
    return null;
  }

  if (workflowError) {
    const { title, navigateTo, buttonText, message } =
      parseWorkflowRequestError([
        { code: workflowError.error_code, message: workflowError.message },
      ]);

    const handleErrorNavigationClick = () => {
      if (navigateTo) {
        navigate(navigateTo);
      } else {
        return null;
      }
    };
    return (
      <ErrorDialog
        title={title || 'Error'}
        error={workflowError}
        errorContent={message}
        closeLabel={buttonText || 'Close'}
        onClose={handleErrorNavigationClick}
      />
    );
  }

  return (
    <>
      {!isDesktopView && loadingStateVisible && (
        <AppBar
          onNavigationClick={() =>
            navigate(`/${REQUESTS_INDEX}${REQUESTS_PATHS.list}`)
          }
          DefaultStateElement={AttachIconState}
          hideSecondaryActionButton
          title="Requests"
          position="relative"
        />
      )}
      <VerboseLoading
        delay={3000}
        finalDelay={2000}
        progressTitle="We are preparing your request"
        isLoading={isWorkflowLoading || isSectionsLoading}
        progressCaptions={PROGRESS_CAPTIONS}
        successTitle="Your request is ready"
        successCaption="All set and ready to go"
        onShouldVanish={() => setLoadingStateVisible(false)}
      />
      {!loadingStateVisible && workflowData && id && (
        <RequestStoreProvider id={id} goBackUrl={location.state?.goBackUrl}>
          {isDispatchView ? (
            <RequestDesktopDispatchView id={id} />
          ) : (
            <RequestDesktopFirmView id={id} status={workflowData.status} />
          )}
        </RequestStoreProvider>
      )}
    </>
  );
};

export default RequestView;
