import { useState } from 'react';

import toast from 'react-hot-toast';

import {
  IndexRequestSchemaDataItem,
  postApiV5FirmsWorkflowRequestsBulkDispatch,
  usePostApiV5FirmsWorkflowRequestsBulkDispatch,
} from '@liscio/api';
import { Dialog } from '@liscio/ui';

import { BulkSendSuccessDialog } from './BulkSendSuccessDialog';

type BulkSendDialogProps = {
  open: boolean;
  selectedRows: (IndexRequestSchemaDataItem | undefined)[];
  onClose: () => void;
};

/**
 * This component is used to bulk send drafts.
 */
export function BulkSendDialog({
  open,
  selectedRows,
  onClose,
}: BulkSendDialogProps) {
  const [bulkSendSuccessDialogOpen, setBulkSendSuccessDialogOpen] =
    useState(false);

  const { mutateAsync: bulkDispatch, isLoading } =
    usePostApiV5FirmsWorkflowRequestsBulkDispatch({
      mutation: {
        mutationFn: (values) =>
          postApiV5FirmsWorkflowRequestsBulkDispatch(values.data).then(
            (response) => {
              const hasErrors = Number(response?.data?.errors?.length) > 0;

              if (hasErrors) {
                throw new Error('Some drafts were not sent');
              }

              return response;
            }
          ),
      },
    });

  const rowCount = selectedRows.length;

  const handleSubmit = () => {
    bulkDispatch(
      {
        data: {
          request: {
            ids: selectedRows
              .map((row) => row?.uuid)
              .filter((id): id is string => id !== undefined),
          },
        },
      },
      {
        onSuccess: () => {
          setBulkSendSuccessDialogOpen(true);
          onClose();
        },
        onError: (error) => {
          toast.error('Error sending draft requests.');
          console.error('Error sending draft requests:', error);
          onClose();
        },
      }
    );
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        title="Send selected"
        iconClose
        onSubmit={handleSubmit}
        submitLabel={`Send ${rowCount} selected`}
        submitBtnProps={{ color: 'info', disabled: isLoading }}
        cancelLabel="Cancel"
        onCancel={onClose}
        cancelBtnProps={{ color: 'info' }}
      >
        <p>
          Clicking "Send" will send{' '}
          <b>
            {rowCount} {rowCount === 1 ? 'request' : 'requests'}
          </b>{' '}
          to their respective assignees.
        </p>
      </Dialog>
      <BulkSendSuccessDialog
        open={bulkSendSuccessDialogOpen}
        rowCount={rowCount}
        onClose={() => setBulkSendSuccessDialogOpen(false)}
      />
    </>
  );
}
