export interface DesktopRedirectConfig {
  // If redirect present, redirect to this string
  redirect?: string;
}

/**
 * Path match map configuration
 */
export const exclusionPaths: string[] = [
  '/admin/billing',
  '/admin/users',
  '/admin/users/update',
  'notifications/list',
  'authenticate/*',
  '/requests/*',
  '/',
  '/admin/templates/organizer',
  '/enforced-mfa-configuration',
  // https://liscio.atlassian.net/browse/DEV-5681
  // '/requests', // This can be enabled once the new requests page is ready for firms
  // '/requests/:id',  // This can be enabled once the new single request page is ready for firms
];

/**
 * Conditional exclusion paths
 * A string path map to keep all routes in one place vs declaring in DesktopoSiteRedirect.tsx, etc.
 */
export const conditionalExclusionPaths = {
  /*
   * temporary route for ld devFirmRequestsIndexPage flag.
   * Allows for development under a temporary routes to not disprupt current /requests usage
   */
  elasticSearchRequests: '/elastic-search-requests',
  smartTax: '/smart-tax*',
};

export const pathMap: { [path: string]: DesktopRedirectConfig } = {
  'requests/new': { redirect: '/requests/new' }, // This can be removed once the 'create new request' page is ready for firms
  'requests/import': { redirect: '/requests/import' }, // This can be removed once the 'import requests' page is ready for firms
  '/authenticate/ml/:id': { redirect: '/magicLink/:id' },
  '/': { redirect: '/' },
  '/messages/list': { redirect: '/all_messages' },
  '/messages/details/:id': { redirect: '/inbox/:id' },

  '/edocs/list': { redirect: '/esign_list' },

  '/files/new': { redirect: '/fileupload/new' },
  '/files/list': { redirect: '/files' },
  '/messages/new': { redirect: '/message/new' },
  '/accounts/list': { redirect: '/accounts' },

  '/contacts/list': { redirect: '/contacts' },

  '/tasks/list': { redirect: '/all_tasks' },
  '/tasks/details/:id': { redirect: '/task/detail/:id' },

  '/invoices/list': { redirect: '/billing/invoices' },
  '/invoices/details/:id': { redirect: '/billing/invoices/:id' },
  '/dashboard/home': { redirect: '/dashboard' },
  '/admin/templates/message': {
    redirect: '/templates/tab=message_template',
  },
  '/admin/templates/tasks': { redirect: '/templates?tab=task_template' },
  '/admin/templates/edocs': { redirect: '/templates?tab=edoc_template' },
  '/admin/preferences': { redirect: '/preferences' },
  '/admin/integrations': { redirect: '/integrations' },
  '/admin/sms': { redirect: '/sms-admin' },
};

export const pathMapRoutes = Object.keys(pathMap).map((path) => ({
  path,
}));

export const exclusionPathMapRoutes = exclusionPaths.map((path) => ({ path }));

/**
 * Defer rendering of these paths to prevent OTP consumption prior to redirect
 */
export const deferredPaths = ['/magicLink/:id', '/ml/:id'];
