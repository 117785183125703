/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

import { useCpaStore } from '@liscio/common';
import { Button, GlobalLoader, Stack, ErrorDialog } from '@liscio/ui';
import { defaultErrorParser } from '@liscio/utils';

import { validationSchema } from './validationSchema';
import { useYupValidationResolver } from 'custom-hooks';
import { useForgotPassword } from 'fetch-utils/users/auth-hooks';
import { AuthPasswordInput } from 'modules/authenticate/components/FormFields';

export interface ResetPasswordFormData {
  password: string;
  password_confirmation: string;
}

export const parseError = (error?: unknown) => {
  if (typeof (error as any)?.error === 'string') {
    return (error as any).error;
  }
  if (typeof (error as any)?.errors?.[0]?.message === 'string') {
    return (error as any)?.errors?.[0]?.message;
  }

  return defaultErrorParser(error);
};

export const ResetPasswordForm = () => {
  // Back to login
  const navigate = useNavigate();
  const backToLogin = useCallback(
    () => navigate('/authenticate/login', { replace: true }),
    [navigate]
  );

  // Form
  const validateResolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormData>({
    resolver: validateResolver,
  });

  const {
    changePassword: {
      mutateAsync: changePasswordV3,
      isLoading: isLoadingV3,
      error: errorV3,
    },
  } = useForgotPassword();
  const { token } = useParams<{ token: string }>();
  const queryCompany = useCpaStore(({ queryCompany }) => queryCompany);
  const onSubmit: SubmitHandler<ResetPasswordFormData> = useCallback(
    async (data) => {
      try {
        await changePasswordV3({
          ...data,
          token: token || '',
          company_name: queryCompany || '',
        });

        backToLogin();
        toast.success('Password changed successfully', { duration: 3000 });
      } catch {}
    },
    [backToLogin, changePasswordV3, queryCompany, token]
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack mt={2} gap={2}>
          <AuthPasswordInput
            {...register('password')}
            placeholder="New Password"
            error={Boolean(errors.password)}
            helperText={errors?.password?.message}
          />
          <AuthPasswordInput
            {...register('password_confirmation')}
            placeholder="Confirm Password"
            error={Boolean(errors.password_confirmation)}
            helperText={errors?.password_confirmation?.message}
          />
          <Stack mt={2} gap={2}>
            <Button variant="contained" type="submit" size="large">
              Submit
            </Button>
            <Button
              variant="contained"
              type="button"
              size="large"
              color="secondary"
              onClick={backToLogin}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>
      <GlobalLoader open={isLoadingV3} />
      <ErrorDialog error={errorV3} parseError={parseError} />
    </>
  );
};
