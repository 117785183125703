import { useState } from 'react';

import { MenuItem, ListItemIcon, Icon } from '@liscio/ui';

import { RequestPdfDialog } from 'modules/requests/dialogs/RequestPdfDialog/RequestPdfDialog';

interface PrintPdfMenuItemProps {
  previewUrl: string;
  title: string;
  onClose: () => void;
}

export const PrintPdfMenuItem = ({
  previewUrl,
  title,
  onClose,
}: PrintPdfMenuItemProps) => {
  const [showPdf, setShowPdf] = useState(false);
  return (
    <>
      <MenuItem onClick={() => setShowPdf(true)}>
        <ListItemIcon>
          <Icon icon="print" fontSize="small" />
        </ListItemIcon>
        Print PDF
      </MenuItem>
      <RequestPdfDialog
        open={showPdf}
        onClose={() => {
          setShowPdf(false);
          onClose();
        }}
        title={title}
        url={previewUrl}
      />
    </>
  );
};
