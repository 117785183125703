import { Chip, styled } from '@liscio/ui';

export const StyledStatusCellChip = styled(Chip)(({ theme }) => ({
  background: theme.palette.common.neutrals[100],
  color: theme.palette.common.neutrals[700],
  fontWeight: 600,
  fontSize: '0.75rem',
  padding: '3px 6px 3px 6px',
  lineHeight: '1rem',
}));
