import React from 'react';

import { List, ListItem } from '@mui/material';
import { styled, Box, ScreenWrapper, BodyWrapper } from '@liscio/ui';

import { SearchTextField, DetailsState, AppBar, Overlay } from '@liscio/common';

import { ListItemButton } from '../ListItemButton/ListItemButton';
import { Loading } from '../Loading/Loading';

export interface AutocompleteOverlaySelectProps<T> {
  open?: boolean;
  isLoading?: boolean;
  title: string;
  closeFunction(): void;
  renderItem(item: T): React.ReactNode;
  keyExtractor(item: T): string | number;
  data: Array<T>;
  searchString?: string;
  onSearchChange?: (searchString: string) => void;
  onSelect?: (item: T) => void;
  searchDebounce?: number;
}

const SearchWrapper = styled(Box)(({ theme }) => ({
  padding: '8px',
  borderBottom: `solid 1px ${theme.palette.common.blueGray[200]}`,
}));

export const AutocompleteOverlaySelect = <T extends unknown>({
  open = false,
  isLoading = false,
  title,
  closeFunction,
  data,
  keyExtractor,
  renderItem,
  onSelect = () => {},
  onSearchChange,
  searchString = '',
  searchDebounce = 500,
}: AutocompleteOverlaySelectProps<T>) => {
  return (
    <Overlay open={open}>
      <ScreenWrapper>
        <AppBar
          onNavigationClick={closeFunction}
          DefaultStateElement={DetailsState}
          title={title}
        />
        {onSearchChange && (
          <SearchWrapper>
            <SearchTextField
              onChange={onSearchChange}
              onClearClick={() => onSearchChange('')}
              value={searchString}
              debounce={searchDebounce}
            />
          </SearchWrapper>
        )}
        <BodyWrapper>
          {isLoading && <Loading />}
          {!isLoading && (
            <List disablePadding>
              {data.map((item) => (
                <ListItem key={keyExtractor(item)} disablePadding>
                  <ListItemButton onClick={() => onSelect(item)}>
                    {renderItem(item)}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        </BodyWrapper>
      </ScreenWrapper>
    </Overlay>
  );
};

export default AutocompleteOverlaySelect;
