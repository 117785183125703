import { useState } from 'react';

import { IndexRequestSchemaDataItem } from '@liscio/api';
import { Box, Icon } from '@liscio/ui';

import { RowMenu } from './RowMenu';

type RowMenuCellProps = {
  row: IndexRequestSchemaDataItem;
};

export const RowMenuCell = ({ row }: RowMenuCellProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClick = (event: React.MouseEvent<Element>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<Element>) => {
    event?.stopPropagation?.();
    setAnchorEl(null);
  };

  // If the row is archived or recalled, don't show the menu
  if (row && row.status && ['archived', 'recalled'].includes(row.status))
    return null;

  return (
    <Box pt={0.75} width={30}>
      <Icon icon="ellipsisVertical" fontSize="small" onClick={handleClick} />
      <RowMenu row={row} anchorEl={anchorEl} handleClose={handleClose} />
    </Box>
  );
};
